/* eslint-disable jsx-a11y/anchor-is-valid */
import { IHomeComponent } from "./types"

import check from '../../assets/images/check-mark.png'
import cancel from '../../assets/images/cancel.png'
import { Typography } from "antd"

const { Title } = Typography;
const CheckoutCompleted : React.FC<IHomeComponent> = ({isMobile, isCancelled, ...rest }) => {
  return (
    <div {...rest} className="home-page-wrapper">
      <div className="home-page" style={{
        paddingTop: 100,
        height: '87vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}>
          {isCancelled ? <img src={cancel} width={100} height={100} alt="Cancelled" /> : <img src={check} width={100} height={100} alt="Paid" />}
          {isCancelled ? <Title>Declined!</Title> : <Title>Thank you!</Title> }
        </div>
      </div>
    </div>
  )
}

export default CheckoutCompleted
