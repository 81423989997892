import { enquireScreen } from 'enquire-js'
import { useEffect, useState } from "react"
import { FooterDataSource, HeaderNavDataSource, HowToDataSource, HowToHeaderDataSource, HowToLiveDataSource, HowToScreenDataSource, HowToSharingDataSource, MMBannerDataSource } from "../components/Home/data.source"
import Loader from "../components/Home/Loader"
import Footer from "../components/Home/Footer"
import PreFooter from "../components/Home/PreFooter"
import Header from '../components/Home/Header'
// import HowTo from '../components/Home/HowTo'
import Banner from '../components/Home/Banner'
import BgMovie from '../assets/videos/bg-movie.mp4'
import About from '../components/Home/About'

let isThisMobile : boolean
enquireScreen((b : boolean) => {
  isThisMobile = b
})

const MakeMoney = () => {
  const [isMobile, setIsMobile] = useState(isThisMobile)
  const [show, isShown] = useState(false)
  const [headerClassName, setHeaderClassName] = useState('')

  useEffect(() => {
    const handleScroll = (headerClassName: string) => {
      if (headerClassName !== 'menu-with-bg' && window.pageYOffset >= 150) {
          setHeaderClassName('menu-with-bg');
      } else if (headerClassName === 'menu-with-bg' && window.pageYOffset < 150) {
          setHeaderClassName('');
      }
    }

    window.onscroll = () => handleScroll(headerClassName);

    enquireScreen((b : boolean) => {
      setIsMobile(!!b)
    })
    
    if (window.location.port) {
      setTimeout(() => {
        isShown(true)
      }, 100)
    } else {
      isShown(true)
    }
  }, [headerClassName, isMobile, show])

  const children = [
    <Header
      dataSource={HeaderNavDataSource(isMobile, headerClassName)}
      isMobile={isMobile}
      key="Header"
    />,
    <Banner
      dataSource={MMBannerDataSource(true)}
      isMobile={isMobile}
      video={BgMovie}
      key="Banner"
    />,
    // <HowTo
    //   isMobile={isMobile}
    //   key="HowTo"
    // />,
    <About
      dataSource={HowToHeaderDataSource}
      isMobile={isMobile}
      id="HowToHeader"
      key="HowToHeader"
    />,
    <About
      dataSource={HowToDataSource}
      isMobile={isMobile}
      id="HowTo"
      key="HowTo"
    />,
    <About
      dataSource={HowToScreenDataSource}
      isMobile={isMobile}
      id="HowToScreen"
      key="HowToScreen"
    />,
    <About
      dataSource={HowToSharingDataSource}
      isMobile={isMobile}
      id="HowToSharing"
      key="HowToSharing"
    />,
    <About
      dataSource={HowToLiveDataSource}
      isMobile={isMobile}
      id="HowToLive"
      key="HowToLive"
    />,
    <PreFooter
      isMobile={isMobile}
      key="Pre-Footer"
    />,
    <Footer
      dataSource={FooterDataSource}
      isMobile={isMobile}
      key="Footer"
    />,
  ]

  return (
    <div
      className="templates-wrapper"
    >
      {!show ? <Loader /> : children}
    </div>
  )
}

export default MakeMoney
