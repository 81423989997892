import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { Store } from 'redux'
import { History } from 'history'
import { ApplicationState } from './stores'
import Home from './screens/Home'
import PrivacyPolicy from './screens/PrivacyPolicy'
import AboutVision from './screens/AboutVision'
import AboutMission from './screens/AboutMission'
import Terms from './screens/Terms'
import CommunityStandard from './screens/CommunityStandard'
import MakeMoney from './screens/MakeMoney'
import Order from './screens/Order'
import ReturnURL from './screens/ReturnURL'
import CancelURL from './screens/CancelURL'
import Download from './screens/Download'

interface IAppProps {
  store: Store<ApplicationState>
  history: History
}

const App: React.FC<IAppProps> = ({ store, history }) => {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <BrowserRouter basename={process.env.REACT_APP_PUBLIC_PATH}>
          <Switch>
            <Route exact path='/' component={Home}/>
            <Route path='/vision-mission' component={AboutVision}/>
            <Route path='/mission' component={AboutMission}/>
            <Route path='/privacy-policy' component={PrivacyPolicy}/>
            <Route path='/terms-of-service' component={Terms}/>
            <Route path='/community-standards' component={CommunityStandard}/>
            <Route path='/how-to-monetize-your-content' component={MakeMoney}/>
            <Route path='/order' component={Order}/>
            <Route path='/returnUrl' component={ReturnURL}/>
            <Route path='/cancelUrl' component={CancelURL}/>
            <Route path='/download' component={Download}/>
            <Redirect from="*" to="/" />
          </Switch>
        </BrowserRouter>
      </ConnectedRouter>
    </Provider>
  )
}

export default App
