import { enquireScreen } from 'enquire-js'
import { useEffect, useState } from "react"
import { FooterDataSource, HeaderNavDataSource } from "../components/Home/data.source"
import Loader from "../components/Home/Loader"
import Footer from "../components/Home/Footer"
import PreFooter from "../components/Home/PreFooter"
import Privacy from "../components/Home/Privacy"
import Header from '../components/Home/Header'
import PrivacyEn from '../components/Home/PrivacyEn'
import PrivacyZh from '../components/Home/PrivacyZh'
import PrivacyMs from '../components/Home/PrivacyMs'

let isThisMobile : boolean
enquireScreen((b : boolean) => {
  isThisMobile = b
})

const PrivacyPolicy = () => {
  const [isMobile, setIsMobile] = useState(isThisMobile)
  const [show, isShown] = useState(false)
  const [headerClassName, setHeaderClassName] = useState('no-banner')

  const searchParams = new URLSearchParams(document.location.search)
  const lang = searchParams.get('lang')

  useEffect(() => {
    const handleScroll = (headerClassName: string) => {
      if (headerClassName !== 'menu-with-bg' && window.pageYOffset >= 150) {
          setHeaderClassName('no-banner menu-with-bg');
      } else if (headerClassName === 'menu-with-bg' && window.pageYOffset < 150) {
          setHeaderClassName('no-banner');
      }
    }

    window.onscroll = () => handleScroll(headerClassName);

    enquireScreen((b : boolean) => {
      setIsMobile(!!b)
    })
    
    if (window.location.port) {
      setTimeout(() => {
        isShown(true)
      }, 100)
    } else {
      isShown(true)
    }
  }, [headerClassName, isMobile, show])

  let children;

  switch (lang) {
    case 'en':
      children = [
        <PrivacyEn
          isMobile={isMobile}
          key="Privacy"
        />
      ]
      break;
    case 'zh':
      children = [
        <PrivacyZh
          isMobile={isMobile}
          key="Privacy"
        />
      ]
      break;
    case 'ms':
      children = [
        <PrivacyMs
          isMobile={isMobile}
          key="Privacy"
        />
      ]
      break;
    default:
      children = [
        <Header
          dataSource={HeaderNavDataSource(isMobile, headerClassName)}
          isMobile={isMobile}
          key="Header"
        />,
        <Privacy
          isMobile={isMobile}
          key="Privacy"
        />,
        <PreFooter
          isMobile={isMobile}
          key="Pre-Footer"
        />,
        <Footer
          dataSource={FooterDataSource}
          isMobile={isMobile}
          key="Footer"
        />,
      ]
      break;
  }

  return (
    <div
      className="templates-wrapper"
    >
      {!show ? <Loader /> : children}
    </div>
  )
}

export default PrivacyPolicy
