import { connectRouter, RouterState } from "connected-react-router"
import { combineReducers } from "redux"
import { all, fork } from "redux-saga/effects"
import { summaryReducer } from "./summaries/reducers"
import summarySaga from "./summaries/sagas"
import { SummaryState } from "./summaries/types"
import { History } from 'history'

export interface ApplicationState {
  summaries: SummaryState
  router: RouterState
}

export const createRootReducer = (history: History) =>
  combineReducers({
    summaries: summaryReducer,
    router: connectRouter(history)
  })
  
export function* rootSaga() {
  yield all([fork(summarySaga)])
}
