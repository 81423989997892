import { createElement } from 'react'
import TweenOne from 'rc-tween-one'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import { IHomeComponent } from "./types"
import { isImg, isImportedImg } from './utils'

const Footer: React.FC<IHomeComponent> = ({dataSource, isMobile, ...rest }) => {
  return (
    <>
      <div {...rest} {...dataSource.wrapper}>
        <OverPack {...dataSource.OverPack}>
          <TweenOne
            animation={{ x: '+=30', opacity: 0, type: 'from' }}
            key="copyright"
            {...dataSource.copyright}
          >
            {dataSource.copyright.children.map((item: any, i: number) =>
              createElement(
                item.name.indexOf('title') === 0 ? 'h2' : 'div',
                { key: i.toString(), ...item },
                typeof item.children === 'string' && (item.children.match(isImg) || item.children.match(isImportedImg))
                  ? createElement('img', {
                      src: item.children,
                      alt: 'img',
                    })
                  : item.children
              )
            )}
          </TweenOne>
        </OverPack>
      </div>
    </>
  )
}

export default Footer