import { IHomeComponent } from "./types"

const PrivacyEn : React.FC<IHomeComponent> = ({isMobile, ...rest }) => {
  return (
    <div {...rest} className="home-page-wrapper">
      <div className="home-page" style={{
        paddingTop: 100,
      }}>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '12pt'}}>
          <span style={{fontFamily: '"Montserrat", Sans-serif'}}>Effective: 1 October 2023</span>
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '18pt'}}>
          <strong><span style={{fontFamily: '"Montserrat", Sans-serif'}}>Privacy Policies</span></strong>
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '12pt'}}>
          <span style={{fontFamily: '"Montserrat", Sans-serif'}}>When you use MeLife services, you’ll share some information with us. So we want to be upfront about the information we collect, how we use it, whom we share it with and the controls we give you to access, update and delete your information.</span>
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '12pt'}}>
          <span style={{fontFamily: '"Montserrat", Sans-serif'}}>That’s why we’ve written this Privacy Policy. And it's why we've tried to write it in a way that's easy to understand for all our users. Of course, if you still have questions about anything in our Privacy Policy, just contact us at </span><a href="/cdn-cgi/l/email-protection#83e2f3f3efeaede8f6f3f3c3e4eee2eaefade0ecee" style={{textDecoration: 'none'}}><span className="Hyperlink" style={{fontFamily: '"Montserrat", Sans-serif'}}><span className="__cf_email__" data-cfemail="315041415d585f5a44414171565c50585d1f525e5c">hi@melife.id</span></span></a><span style={{fontFamily: '"Montserrat", Sans-serif'}}>.</span>
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '18pt'}}>
          <strong><span style={{fontFamily: '"Montserrat", Sans-serif'}}>Summary</span></strong>
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '12pt'}}>
          <u><span style={{fontFamily: '"Montserrat", Sans-serif'}}>What information do we collect about you?</span></u>
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '12pt'}}>
          <span style={{fontFamily: '"Montserrat", Sans-serif'}}>We collect and process information you give us when you create an account and upload content to the Platform. This includes technical and behavioural information about your use of the Platform.</span><span style={{fontFamily: '"Montserrat", Sans-serif'}}>&nbsp; </span><span style={{fontFamily: '"Montserrat", Sans-serif'}}>We also collect information about you if you download the app and interact with the Platform without creating an account.</span>
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '12pt'}}>
          <u><span style={{fontFamily: '"Montserrat", Sans-serif'}}>How will we use the information about you?</span></u>
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '12pt'}}>
          <span style={{fontFamily: '"Montserrat", Sans-serif'}}>We use your information to provide the Platform to you and to improve and administer it.</span><span style={{fontFamily: '"Montserrat", Sans-serif'}}>&nbsp; </span><span style={{fontFamily: '"Montserrat", Sans-serif'}}>We use your information to, among other things, show you suggested feed, improve and develop the Platform and ensure your safety. Where appropriate, we will also use your personal information to serve you targeted advertising and promote the Platform.</span>
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '12pt'}}>
          <u><span style={{fontFamily: '"Montserrat", Sans-serif'}}>Who do we share your information with?</span></u>
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '12pt'}}>
          <span style={{fontFamily: '"Montserrat", Sans-serif'}}>We share your data with third party service providers who help us to deliver the Platform, such as cloud storage providers. We also share your information with business partners, other companies in the same group as MeLife, content moderation services, measurement providers, advertisers, and analytics providers. Where and when required by law, we will share your information with law enforcement agencies or regulators, and with third parties pursuant to a legally binding court order.</span>
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '12pt'}}>
          <u><span style={{fontFamily: '"Montserrat", Sans-serif'}}>How long do we keep hold of your information?</span></u>
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '12pt'}}>
          <span style={{fontFamily: '"Montserrat", Sans-serif'}}>We retain your information for as long as it is necessary to provide you with the service. Where we do not need your information in order to provide the service to you, we retain it only as long as we have a legitimate business purpose in keeping such data or where we are subject to a legal obligation to retain the data. We will also retain your data if we believe it is or will be necessary for the establishment, exercise or defence of legal claims. </span>
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '12pt'}}>
          <u><span style={{fontFamily: '"Montserrat", Sans-serif'}}>How will we notify you of any changes to this Privacy Policy?</span></u>
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '12pt'}}>
          <span style={{fontFamily: '"Montserrat", Sans-serif'}}>We will generally notify all users of any material changes to this policy through a notice on our Platform. However, you should look at this policy regularly to check for any changes. We will also update the “Effective” date at the top of this policy, which reflects the effective date of such policy. By accessing or using the Platform, you acknowledge that you have read this policy and that you understand your rights in relation to your personal data and how we will collect, use and process it.</span>
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '18pt'}}>
          <strong><span style={{fontFamily: '"Montserrat", Sans-serif'}}>When will MeLife collect Personal data?</span></strong><strong><span style={{width: '1.47pt', fontFamily: '"Montserrat", Sans-serif', display: 'inline-block'}}>&nbsp;</span></strong><strong><span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}>&nbsp;</span></strong><br /><span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}>We will/may collect personal data about you:</span><span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}>&nbsp;</span><span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}> </span>
        </p>
        <ul style={{margin: '0pt', paddingLeft: '0pt'}}>
          <li style={{marginTop: '6pt', marginLeft: '27.6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', paddingLeft: '8.4pt', fontFamily: 'serif', fontSize: '10pt'}}>
            <span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}>when you register and/or use our Services or Platform, or open an account with us;</span><span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}>&nbsp;</span><span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}> </span>
          </li>
          <li style={{marginTop: '6pt', marginLeft: '27.6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', paddingLeft: '8.4pt', fontFamily: 'serif', fontSize: '10pt'}}>
            <span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}>when you submit any form, including, but not limited to, application forms or other forms relating to any of our products and services, whether online or by way of a physical form;</span><span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}>&nbsp;</span><span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}> </span>
          </li>
          <li style={{marginTop: '6pt', marginLeft: '27.6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', paddingLeft: '8.4pt', fontFamily: 'serif', fontSize: '10pt'}}>
            <span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}>when you enter into any agreement or provide other documentation or information in respect of your interactions with us, or when you use our products and services;</span><span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}>&nbsp;</span><span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}> </span>
          </li>
          <li style={{marginTop: '6pt', marginLeft: '27.6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', paddingLeft: '8.4pt', fontFamily: 'serif', fontSize: '10pt'}}>
            <span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}>when you interact with us, such as via telephone calls (which may be recorded), letters, fax, face-to-face meetings, social media platforms and emails, including when you interact with our customer service agents;</span><span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}>&nbsp;</span><span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}> </span>
          </li>
          <li style={{marginTop: '6pt', marginLeft: '27.6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', paddingLeft: '8.4pt', fontFamily: 'serif', fontSize: '10pt'}}>
            <span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}>when you use our electronic services, or interact with us via our application or use Services on our Platform. This includes, without limitation, through cookies which we may deploy when you interact with our application or website;</span><span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}>&nbsp;</span><span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}> </span>
          </li>
          <li style={{marginTop: '6pt', marginLeft: '27.6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', paddingLeft: '8.4pt', fontFamily: 'serif', fontSize: '10pt'}}>
            <span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}>when you grant permissions on your device to share information with our application or Platform; </span>
          </li>
          <li style={{marginTop: '6pt', marginLeft: '27.6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', paddingLeft: '8.4pt', fontFamily: 'serif', fontSize: '10pt'}}>
            <span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}>when you carry out transactions through our Services;</span><span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}>&nbsp;</span><span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}> </span>
          </li>
          <li style={{marginTop: '6pt', marginLeft: '27.6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', paddingLeft: '8.4pt', fontFamily: 'serif', fontSize: '10pt'}}>
            <span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}>when you provide us with feedback or complaints;</span><span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}>&nbsp;</span><span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}> </span>
          </li>
          <li style={{marginTop: '6pt', marginLeft: '27.6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', paddingLeft: '8.4pt', fontFamily: 'serif', fontSize: '10pt'}}>
            <span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}>when you register for a contest; or</span><span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}>&nbsp;</span><span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}> </span>
          </li>
          <li style={{marginTop: '6pt', marginLeft: '27.6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', paddingLeft: '8.4pt', fontFamily: 'serif', fontSize: '10pt'}}>
            <span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}>when you submit your personal data to us for any reason</span><span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}>&nbsp;</span><span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}> </span>
          </li>
        </ul>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '12pt'}}>
          <span style={{fontFamily: '"Montserrat", Sans-serif'}}>The above does not purport to be exhaustive and sets out some common instances of when personal data about you may be collected.</span><span style={{fontFamily: '"Montserrat", Sans-serif'}}>&nbsp;</span><span style={{fontFamily: '"Montserrat", Sans-serif'}}> </span>
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '18pt'}}>
          <strong><span style={{fontFamily: '"Montserrat", Sans-serif'}}>What personal data will MeLife collect?</span></strong><strong><span style={{fontFamily: '"Montserrat", Sans-serif'}}>&nbsp;</span></strong><strong><span style={{fontFamily: '"Montserrat", Sans-serif'}}> </span></strong>
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '12pt'}}>
          <span style={{fontFamily: '"Montserrat", Sans-serif'}}>The personal data that MeLife may collect includes but is not limited to:</span><span style={{fontFamily: '"Montserrat", Sans-serif'}}>&nbsp;</span><span style={{fontFamily: '"Montserrat", Sans-serif'}}> </span>
        </p>
        <ul style={{margin: '0pt', paddingLeft: '0pt'}}>
          <li style={{marginTop: '6pt', marginLeft: '27.6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', paddingLeft: '8.4pt', fontFamily: 'serif', fontSize: '10pt'}}>
            <span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}>name;</span><span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}>&nbsp;</span><span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}> </span>
          </li>
          <li style={{marginTop: '6pt', marginLeft: '27.6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', paddingLeft: '8.4pt', fontFamily: 'serif', fontSize: '10pt'}}>
            <span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}>email address;</span><span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}>&nbsp;</span><span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}> </span>
          </li>
          <li style={{marginTop: '6pt', marginLeft: '27.6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', paddingLeft: '8.4pt', fontFamily: 'serif', fontSize: '10pt'}}>
            <span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}>date of birth;</span><span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}>&nbsp;</span><span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}> </span>
          </li>
          <li style={{marginTop: '6pt', marginLeft: '27.6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', paddingLeft: '8.4pt', fontFamily: 'serif', fontSize: '10pt'}}>
            <span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}>billing and/or delivery address;</span><span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}>&nbsp;</span><span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}> </span>
          </li>
          <li style={{marginTop: '6pt', marginLeft: '27.6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', paddingLeft: '8.4pt', fontFamily: 'serif', fontSize: '10pt'}}>
            <span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}>bank account and payment information;</span><span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}>&nbsp;</span><span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}> </span>
          </li>
          <li style={{marginTop: '6pt', marginLeft: '27.6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', paddingLeft: '8.4pt', fontFamily: 'serif', fontSize: '10pt'}}>
            <span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}>telephone number;</span><span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}>&nbsp;</span><span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}> </span>
          </li>
          <li style={{marginTop: '6pt', marginLeft: '27.6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', paddingLeft: '8.4pt', fontFamily: 'serif', fontSize: '10pt'}}>
            <span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}>gender;</span><span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}>&nbsp;</span><span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}> </span>
          </li>
          <li style={{marginTop: '6pt', marginLeft: '27.6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', paddingLeft: '8.4pt', fontFamily: 'serif', fontSize: '10pt'}}>
            <span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}>location;</span>
          </li>
          <li style={{marginTop: '6pt', marginLeft: '27.6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', paddingLeft: '8.4pt', fontFamily: 'serif', fontSize: '10pt'}}>
            <span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}>information sent by or associated with the device(s) used to access our Services or Platform;</span><span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}>&nbsp;</span><span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}> </span>
          </li>
          <li style={{marginTop: '6pt', marginLeft: '27.6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', paddingLeft: '8.4pt', fontFamily: 'serif', fontSize: '10pt'}}>
            <span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}>information about your network and the people and accounts you interact with; </span>
          </li>
          <li style={{marginTop: '6pt', marginLeft: '27.6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', paddingLeft: '8.4pt', fontFamily: 'serif', fontSize: '10pt'}}>
            <span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}>photographs or audio or video recordings; </span>
          </li>
          <li style={{marginTop: '6pt', marginLeft: '27.6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', paddingLeft: '8.4pt', fontFamily: 'serif', fontSize: '10pt'}}>
            <span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}>government issued identification or other information required for our due diligence, know your customer, identity verification, or fraud prevention purposes;</span><span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}>&nbsp;</span><span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}> </span>
          </li>
          <li style={{marginTop: '6pt', marginLeft: '27.6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', paddingLeft: '8.4pt', fontFamily: 'serif', fontSize: '10pt'}}>
            <span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}>marketing and communications data, such as your preferences in receiving marketing from us and third parties, your communication preferences and history of communications with us, our service providers, and other third parties;</span><span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}>&nbsp;</span><span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}> </span>
          </li>
          <li style={{marginTop: '6pt', marginLeft: '27.6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', paddingLeft: '8.4pt', fontFamily: 'serif', fontSize: '10pt'}}>
            <span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}>usage and transaction data, including details about your searches, orders, the advertising and content you interact with on the Platform, and other products and services related to you; </span>
          </li>
          <li style={{marginTop: '6pt', marginLeft: '27.6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', paddingLeft: '8.4pt', fontFamily: 'serif', fontSize: '10pt'}}>
            <span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}>location data; </span>
          </li>
          <li style={{marginTop: '6pt', marginLeft: '27.6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', paddingLeft: '8.4pt', fontFamily: 'serif', fontSize: '10pt'}}>
            <span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}>any other information about the User when the User signs up to use our Services or Platform, and when the User uses the Services or Platform, as well as information related to how the User uses our Services or Platform; and</span><span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}>&nbsp;</span><span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}> </span>
          </li>
          <li style={{marginTop: '6pt', marginLeft: '27.6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', paddingLeft: '8.4pt', fontFamily: 'serif', fontSize: '10pt'}}>
            <span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}>aggregate data on content the User engages with.</span><span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}>&nbsp;</span><span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}> </span>
          </li>
        </ul>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '12pt'}}>
          <span style={{fontFamily: '"Montserrat", Sans-serif'}}>You agree not to submit any information to us which is inaccurate or misleading, and you agree to inform us of any inaccuracies or changes to such information. We reserve the right at our sole discretion to require further documentation to verify the information provided by you.</span><span style={{fontFamily: '"Montserrat", Sans-serif'}}>&nbsp;</span><span style={{fontFamily: '"Montserrat", Sans-serif'}}> </span>
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '12pt'}}>
          <span style={{fontFamily: '"Montserrat", Sans-serif'}}>If you do not want us to collect the aforementioned information/personal data, you can simply revoke your permission by changing the settings in the app or on your device if your device offers those options. Of course, if you do that, certain services may lose full functionality. For example, opting out of the collection of location information will cause its location-based features to be disabled.</span><span style={{fontFamily: '"Montserrat", Sans-serif'}}>&nbsp;</span><span style={{fontFamily: '"Montserrat", Sans-serif'}}> </span>
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '12pt'}}>
          <strong><span style={{fontFamily: '"Montserrat", Sans-serif'}}>Information from Third Parties.</span></strong><span style={{fontFamily: '"Montserrat", Sans-serif'}}> You may choose to share certain data with us from third parties or through your use of the Platform, we may collect such third-party data automatically. We use the information collected from you, and your interaction with the Platform and other third-party sites and Apps, to infer your likely interests to provide you with more relevant advertising. This information tells us about websites you've visited, apps you've downloaded and purchases you have made so that we can predict what else might interest you in the future and assess how effective the advertising on our Platform is. We collect this information by the use of Cookies and similar technologies on our Platform and from similar information received from third parties who advertise on our Platform and whose sites/Apps you visit.</span>
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '12pt'}}>
          <strong><span style={{fontFamily: '"Montserrat", Sans-serif'}}>Technical Information we collect about you.</span></strong><span style={{fontFamily: '"Montserrat", Sans-serif'}}> We collect certain information about the device you use to access the Platform, such as your IP address, user agent, mobile carrier, time zone settings, identifiers for advertising purposes, model of your device, the device system, network type, device IDs, your screen resolution and operating system, app and file names and types, keystroke patterns or rhythms, battery state, audio settings and connected audio devices. Where you log-in from multiple devices, we will be able to use your profile information to identify your activity across devices. We may also associate you with information collected from devices other than those you use to log-in to the Platform. </span>
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '12pt'}}>
          <strong><span style={{fontFamily: '"Montserrat", Sans-serif'}}>Location.</span></strong><span style={{fontFamily: '"Montserrat", Sans-serif'}}> We collect information about your approximate location, including location information based on your SIM card and/or IP address. With your permission, we may also collect precise location data (such as GPS).</span>
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '12pt'}}>
          <strong><span style={{fontFamily: '"Montserrat", Sans-serif'}}>Find other users and invite your friends.</span></strong><span style={{fontFamily: '"Montserrat", Sans-serif'}}> You can choose whether to find other users of the Platform using our 'Find Friends' function. This functionality allows you to see which of your friends from your telephone's contact list or peoples nearby is using the Platform and offers you the option to follow them. You can also use this function to invite your contacts to join you on the Platform. We will use the contact information you have for that person, from within your telephone's contact list and give you the option to send them either an SMS, email or third-party message (such as Whatsapp, Facebook (including Facebook Messenger) or Twitter) inviting them to view your MeLife profile.</span><span style={{fontFamily: '"Montserrat", Sans-serif'}}>&nbsp; </span>
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '18pt'}}>
          <strong><span style={{fontFamily: '"Montserrat", Sans-serif'}}>COOKIES</span></strong><strong><span style={{fontFamily: '"Montserrat", Sans-serif'}}>&nbsp;</span></strong><strong><span style={{fontFamily: '"Montserrat", Sans-serif'}}> </span></strong>
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '12pt'}}>
          <span style={{fontFamily: '"Montserrat", Sans-serif'}}>We or our authorized service providers and advertising partners may from time to time use "cookies" or other features to allow us or third parties to collect or share information in connection with your use of our Services or Platform. These features help us improve our Platform and the Services we offer, help us offer new services and features, and/or enable us and our advertising partners to serve more relevant content to you, including through remarketing. “Cookies” are identifiers that are stored on your computer or mobile device that record data about the computer or mobile device, how and when the Services or Platform are used or visited, by how many people and other activity within our Platform. We may link cookie information to personal data. Cookies also link to information regarding what items you have selected for purchase and web pages you have viewed. This information is used to keep track of your shopping cart, to deliver content specific to your interests, to enable our third-party advertising partners to serve advertisements on sites across the internet, and to conduct data analysis and to monitor usage of the Services.</span><span style={{fontFamily: '"Montserrat", Sans-serif'}}>&nbsp;</span><span style={{fontFamily: '"Montserrat", Sans-serif'}}> </span>
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '12pt'}}>
          <span style={{fontFamily: '"Montserrat", Sans-serif'}}>You may refuse the use of cookies by selecting the appropriate settings on your browser or device. However, please note that if you do this you may not be able to use the full functionality of our Platform or the Services.</span><span style={{fontFamily: '"Montserrat", Sans-serif'}}>&nbsp;</span><span style={{fontFamily: '"Montserrat", Sans-serif'}}> </span>
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '18pt'}}>
          <strong><span style={{fontFamily: '"Montserrat", Sans-serif'}}>How we use your personal data</span></strong>
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '12pt'}}>
          <span style={{fontFamily: '"Montserrat", Sans-serif'}}>We will use the information we collect about you in the following ways:</span>
        </p>
        <ul style={{margin: '0pt', paddingLeft: '0pt'}}>
          <li className="ListParagraph" style={{marginTop: '6pt', marginLeft: '28.52pt', marginBottom: '0pt', textAlign: 'justify', lineHeight: 'normal', paddingLeft: '7.48pt', fontFamily: 'serif', fontSize: '12pt'}}>
            <span style={{fontFamily: '"Montserrat", Sans-serif'}}>notify you about changes to our service;</span>
          </li>
          <li className="ListParagraph" style={{marginLeft: '28.52pt', marginBottom: '0pt', textAlign: 'justify', lineHeight: 'normal', paddingLeft: '7.48pt', fontFamily: 'serif', fontSize: '12pt'}}>
            <span style={{fontFamily: '"Montserrat", Sans-serif'}}>provide you with user support;</span>
          </li>
          <li className="ListParagraph" style={{marginLeft: '28.52pt', marginBottom: '0pt', textAlign: 'justify', lineHeight: 'normal', paddingLeft: '7.48pt', fontFamily: 'serif', fontSize: '12pt'}}>
            <span style={{fontFamily: '"Montserrat", Sans-serif'}}>personalise the content you receive and provide you with tailored content that will be of interest to you;</span>
          </li>
          <li className="ListParagraph" style={{marginLeft: '28.52pt', marginBottom: '0pt', textAlign: 'justify', lineHeight: 'normal', paddingLeft: '7.48pt', fontFamily: 'serif', fontSize: '12pt'}}>
            <span style={{fontFamily: '"Montserrat", Sans-serif'}}>enable you to share User Content and interact with other users;</span>
          </li>
          <li className="ListParagraph" style={{marginLeft: '28.52pt', marginBottom: '0pt', textAlign: 'justify', lineHeight: 'normal', paddingLeft: '7.48pt', fontFamily: 'serif', fontSize: '12pt'}}>
            <span style={{fontFamily: '"Montserrat", Sans-serif'}}>enable our messenger service to function if you choose to use this function;</span>
          </li>
          <li className="ListParagraph" style={{marginLeft: '28.52pt', marginBottom: '0pt', textAlign: 'justify', lineHeight: 'normal', paddingLeft: '7.48pt', fontFamily: 'serif', fontSize: '12pt'}}>
            <span style={{fontFamily: '"Montserrat", Sans-serif'}}>enable you to participate in the virtual items program; </span>
          </li>
          <li className="ListParagraph" style={{marginLeft: '28.52pt', marginBottom: '0pt', textAlign: 'justify', lineHeight: 'normal', paddingLeft: '7.48pt', fontFamily: 'serif', fontSize: '12pt'}}>
            <span style={{fontFamily: '"Montserrat", Sans-serif'}}>communicate with you; </span>
          </li>
          <li className="ListParagraph" style={{marginLeft: '28.52pt', marginBottom: '0pt', textAlign: 'justify', lineHeight: 'normal', paddingLeft: '7.48pt', fontFamily: 'serif', fontSize: '12pt'}}>
            <span style={{fontFamily: '"Montserrat", Sans-serif'}}>to help us detect and combat abuse, harmful activity, fraud, spam, and illegal activity on the Platform;</span>
          </li>
          <li className="ListParagraph" style={{marginLeft: '28.52pt', marginBottom: '0pt', textAlign: 'justify', lineHeight: 'normal', paddingLeft: '7.48pt', fontFamily: 'serif', fontSize: '12pt'}}>
            <span style={{fontFamily: '"Montserrat", Sans-serif'}}>ensure your safety and security including reviewing user content, messages and associated metadata for breach of our Community Guidelines and other inappropriate content;</span>
          </li>
          <li className="ListParagraph" style={{marginLeft: '28.52pt', marginBottom: '0pt', textAlign: 'justify', lineHeight: 'normal', paddingLeft: '7.48pt', fontFamily: 'serif', fontSize: '12pt'}}>
            <span style={{fontFamily: '"Montserrat", Sans-serif'}}>ensure content is presented in the most effective manner for you and your device;</span>
          </li>
          <li className="ListParagraph" style={{marginLeft: '28.52pt', marginBottom: '0pt', textAlign: 'justify', lineHeight: 'normal', paddingLeft: '7.48pt', fontFamily: 'serif', fontSize: '12pt'}}>
            <span style={{fontFamily: '"Montserrat", Sans-serif'}}>improve, promote and develop the Platform and promote popular topics, hashtags and campaigns on the Platform;</span>
          </li>
          <li className="ListParagraph" style={{marginLeft: '28.52pt', marginBottom: '0pt', textAlign: 'justify', lineHeight: 'normal', paddingLeft: '7.48pt', fontFamily: 'serif', fontSize: '12pt'}}>
            <span style={{fontFamily: '"Montserrat", Sans-serif'}}>carry out data analysis and test the Platform to ensure its stability and security;</span>
          </li>
          <li className="ListParagraph" style={{marginLeft: '28.52pt', marginBottom: '0pt', textAlign: 'justify', lineHeight: 'normal', paddingLeft: '7.48pt', fontFamily: 'serif', fontSize: '12pt'}}>
            <span style={{fontFamily: '"Montserrat", Sans-serif'}}>allow you to participate in interactive features of the Platform;</span>
          </li>
          <li className="ListParagraph" style={{marginLeft: '28.52pt', marginBottom: '0pt', textAlign: 'justify', lineHeight: 'normal', paddingLeft: '7.48pt', fontFamily: 'serif', fontSize: '12pt'}}>
            <span style={{fontFamily: '"Montserrat", Sans-serif'}}>to enable you to socialise on the Platform, for example, by allowing other users to identify you via the "Find other friends" function or through their phone contacts; </span>
          </li>
          <li className="ListParagraph" style={{marginLeft: '28.52pt', marginBottom: '0pt', textAlign: 'justify', lineHeight: 'normal', paddingLeft: '7.48pt', fontFamily: 'serif', fontSize: '12pt'}}>
            <span style={{fontFamily: '"Montserrat", Sans-serif'}}>to ensure that you are old enough to use our Platform (as required by law).</span>
          </li>
          <li className="ListParagraph" style={{marginLeft: '28.52pt', marginBottom: '0pt', textAlign: 'justify', lineHeight: 'normal', paddingLeft: '7.48pt', fontFamily: 'serif', fontSize: '12pt'}}>
            <span style={{fontFamily: '"Montserrat", Sans-serif'}}>provide you with personalised advertising;</span>
          </li>
          <li className="ListParagraph" style={{marginLeft: '28.52pt', marginBottom: '0pt', textAlign: 'justify', lineHeight: 'normal', paddingLeft: '7.48pt', fontFamily: 'serif', fontSize: '12pt'}}>
            <span style={{fontFamily: '"Montserrat", Sans-serif'}}>provide you with location-based services (where those services are available in your jurisdiction);</span>
          </li>
          <li className="ListParagraph" style={{marginLeft: '28.52pt', marginBottom: '0pt', textAlign: 'justify', lineHeight: 'normal', paddingLeft: '7.48pt', fontFamily: 'serif', fontSize: '12pt'}}>
            <span style={{fontFamily: '"Montserrat", Sans-serif'}}>To inform our algorithms</span>
          </li>
          <li className="ListParagraph" style={{marginLeft: '28.52pt', marginBottom: '0pt', textAlign: 'justify', lineHeight: 'normal', paddingLeft: '7.48pt', fontFamily: 'serif', fontSize: '12pt'}}>
            <span style={{fontFamily: '"Montserrat", Sans-serif'}}>enforce our terms, conditions and policies; </span>
          </li>
          <li className="ListParagraph" style={{marginLeft: '28.52pt', marginBottom: '0pt', textAlign: 'justify', lineHeight: 'normal', paddingLeft: '7.48pt', fontFamily: 'serif', fontSize: '12pt'}}>
            <span style={{fontFamily: '"Montserrat", Sans-serif'}}>administer the Platform including troubleshooting; and</span>
          </li>
          <li className="ListParagraph" style={{marginLeft: '28.52pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', paddingLeft: '7.48pt', fontFamily: 'serif', fontSize: '12pt'}}>
            <span style={{fontFamily: '"Montserrat", Sans-serif'}}>to facilitate sales, promotion, and purchase of goods and services and to provide user support</span>
          </li>
        </ul>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '18pt'}}>
          <strong><span style={{fontFamily: '"Montserrat", Sans-serif'}}>How we share your personal data</span></strong>
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '12pt'}}>
          <span style={{fontFamily: '"Montserrat", Sans-serif'}}>We share your data with the following selected third parties:</span>
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '12pt'}}>
          <u><span style={{fontFamily: '"Montserrat", Sans-serif'}}>Payment Providers</span></u>
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '12pt'}}>
          <span style={{fontFamily: '"Montserrat", Sans-serif'}}>If you choose to purchase our services or conduct other payment related transactions, we will share data with the relevant payment provider to facilitate this transaction. For virtual gift transactions, we share a transaction ID to enable us to identify you and credit your account with the correct value in virtual gift once you have made the payment.</span>
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '12pt'}}>
          <u><span style={{fontFamily: '"Montserrat", Sans-serif'}}>Service Providers </span></u>
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '12pt'}}>
          <span style={{fontFamily: '"Montserrat", Sans-serif'}}>We provide information and content to service providers who support our business, such as cloud service providers and providers of content moderation services to ensure that the Platform is a safe and enjoyable place and service providers that assist us in marketing the Platform. </span>
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '12pt'}}>
          <u><span style={{fontFamily: '"Montserrat", Sans-serif'}}>Analytics providers </span></u>
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '12pt'}}>
          <span style={{fontFamily: '"Montserrat", Sans-serif'}}>We use analytics providers to help us in the optimisation and improvement of the Platform.</span><span style={{fontFamily: '"Montserrat", Sans-serif'}}>&nbsp; </span><span style={{fontFamily: '"Montserrat", Sans-serif'}}>Our third-party analytics providers also help us serve targeted adverts.</span>
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '12pt'}}>
          <u><span style={{fontFamily: '"Montserrat", Sans-serif'}}>Advertisers and Advertising Networks</span></u>
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '12pt'}}>
          <span style={{fontFamily: '"Montserrat", Sans-serif'}}>We share information with advertisers and third-party measurement companies to show how many and which users of the Platform have viewed or clicked on an advertisement. We share your device ID with measurement companies so that we can link your activity on the Platform with your activity on other websites; we then use this information to show you adverts which may be of interest to you. </span>
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '12pt'}}>
          <u><span style={{fontFamily: '"Montserrat", Sans-serif'}}>Our Corporate Group</span></u>
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '12pt'}}>
          <span style={{fontFamily: '"Montserrat", Sans-serif'}}>We may also share your information with other members, subsidiaries, or affiliates of our corporate group, to provide the Platform including improving and optimising the Platform, preventing illegal use and supporting users.</span>
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '12pt'}}>
          <u><span style={{fontFamily: '"Montserrat", Sans-serif'}}>Law Enforcement</span></u>
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '12pt'}}>
          <span style={{fontFamily: '"Montserrat", Sans-serif'}}>We will share your information with law enforcement agencies, public authorities or other organisations if legally required to do so, or if such use is reasonably necessary to:</span>
        </p>
        <ul style={{margin: '0pt', paddingLeft: '0pt'}}>
          <li className="ListParagraph" style={{marginTop: '6pt', marginLeft: '28.52pt', marginBottom: '0pt', textAlign: 'justify', lineHeight: 'normal', paddingLeft: '7.48pt', fontFamily: 'serif', fontSize: '12pt'}}>
            <span style={{fontFamily: '"Montserrat", Sans-serif'}}>comply with legal obligation, process or request;</span>
          </li>
          <li className="ListParagraph" style={{marginLeft: '28.52pt', marginBottom: '0pt', textAlign: 'justify', lineHeight: 'normal', paddingLeft: '7.48pt', fontFamily: 'serif', fontSize: '12pt'}}>
            <span style={{fontFamily: '"Montserrat", Sans-serif'}}>enforce our Terms of Service and other agreements, policies, and standards, including investigation of any potential violation thereof;</span>
          </li>
          <li className="ListParagraph" style={{marginLeft: '28.52pt', marginBottom: '0pt', textAlign: 'justify', lineHeight: 'normal', paddingLeft: '7.48pt', fontFamily: 'serif', fontSize: '12pt'}}>
            <span style={{fontFamily: '"Montserrat", Sans-serif'}}>detect, prevent or otherwise address security, fraud or technical issues; or</span>
          </li>
          <li className="ListParagraph" style={{marginLeft: '28.52pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', paddingLeft: '7.48pt', fontFamily: 'serif', fontSize: '12pt'}}>
            <span style={{fontFamily: '"Montserrat", Sans-serif'}}>protect the rights, property or safety of us, our users, a third party or the public as required or permitted by law (including exchanging information with other companies and organisations for the purposes of fraud protection and credit risk reduction).</span>
          </li>
        </ul>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '12pt'}}>
          <u><span style={{fontFamily: '"Montserrat", Sans-serif'}}>Public Profiles</span></u>
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '12pt'}}>
          <span style={{fontFamily: '"Montserrat", Sans-serif'}}>Please note that if your profile is public, your content will be visible to anyone on the Platform and may also be accessed or shared by your friends and followers as well as third parties such as search engines, content aggregators and news sites. You can change who can see a video each time you upload a video. Alternatively, you can change your profile to default private by changing your settings to 'Private Account' in “Manage my account” settings. </span>
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '12pt'}}>
          <u><span style={{fontFamily: '"Montserrat", Sans-serif'}}>Sale or Merger</span></u>
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '12pt'}}>
          <span style={{fontFamily: '"Montserrat", Sans-serif'}}>We will also disclose your information to third parties:</span>
        </p>
        <ul style={{margin: '0pt', paddingLeft: '0pt'}}>
          <li className="ListParagraph" style={{marginTop: '6pt', marginLeft: '28.52pt', marginBottom: '0pt', textAlign: 'justify', lineHeight: 'normal', paddingLeft: '7.48pt', fontFamily: 'serif', fontSize: '12pt'}}>
            <span style={{fontFamily: '"Montserrat", Sans-serif'}}>in the event that we sell or buy any business or assets (whether a result of liquidation, bankruptcy or otherwise), in which case we will disclose your data to the prospective seller or buyer of such business or assets; or</span>
          </li>
          <li className="ListParagraph" style={{marginLeft: '28.52pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', paddingLeft: '7.48pt', fontFamily: 'serif', fontSize: '12pt'}}>
            <span style={{fontFamily: '"Montserrat", Sans-serif'}}>if we sell, buy, merge, are acquired by, or partner with other companies or businesses, or sell some or all of our assets. In such transactions, user information may be among the transferred assets.</span>
          </li>
        </ul>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '18pt'}}>
          <strong><span style={{fontFamily: '"Montserrat", Sans-serif'}}>Where we store your personal data </span></strong>
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '12pt'}}>
          <span style={{fontFamily: '"Montserrat", Sans-serif'}}>The personal data we collect from you may be stored on a server located in Singapore or the United States, outside of the country where you live. We maintain major servers around the world to bring you our services globally and continuously.</span>
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '18pt'}}>
          <strong><span style={{fontFamily: '"Montserrat", Sans-serif'}}>Your Rights and Choices</span></strong>
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '12pt'}}>
          <span style={{fontFamily: '"Montserrat", Sans-serif'}}>You can access and edit most of your profile information by signing into MeLife. You can delete the User Content you uploaded. We also provide a number of tools in Settings that allow you to control, among others, who can view your videos, send you messages, or post comments to your videos. Should you choose to do so, you may delete your entire account in Settings. You may also be afforded certain rights under applicable laws, which may include the right to access, delete, update, or rectify your data, to be informed of the processing of your data, to file complaints with authorities, and potentially other rights. If you have any questions on how to use the above tools, want to know about what rights you may have or want to exercise them, or have any requests, inquiries, or complaints, please contact us at: </span><a href="/cdn-cgi/l/email-protection#9afbeaeaf6f3f4f1efeaeadafdf7fbf3f6b4f9f5f7" style={{textDecoration: 'none'}}><span className="Hyperlink" style={{fontFamily: '"Montserrat", Sans-serif'}}><span className="__cf_email__" data-cfemail="eb8a9b9b878285809e9b9bab8c868a8287c5888486">hi@melife.id</span></span></a><span style={{fontFamily: '"Montserrat", Sans-serif'}}>.</span>
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '18pt'}}>
          <strong><span style={{fontFamily: '"Montserrat", Sans-serif'}}>The security of your personal data</span></strong>
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '12pt'}}>
          <span style={{fontFamily: '"Montserrat", Sans-serif'}}>We take steps to ensure that your information is treated securely and in accordance with this policy. Unfortunately, the transmission of information via the internet is not completely secure. Although we will protect your personal data, for example, by encryption, we cannot guarantee the security of your information transmitted via the Platform; any transmission is at your own risk.</span>
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '12pt'}}>
          <span style={{fontFamily: '"Montserrat", Sans-serif'}}>We have appropriate technical and organizational measures to ensure a level of security appropriate to the risk of varying likelihood and severity for the rights and freedoms of you and other users. We maintain these technical and organizational measures and will amend them from time to time to improve the overall security of our systems.</span>
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '12pt'}}>
          <span style={{fontFamily: '"Montserrat", Sans-serif'}}>We will, from time to time, include links to and from the websites of our partner networks, advertisers and affiliates. If you follow a link to any of these websites, please note that these websites have their own privacy policies and that we do not accept any responsibility or liability for these policies. Please check these policies before you submit any information to these websites.</span>
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '18pt'}}>
          <strong><span style={{fontFamily: '"Montserrat", Sans-serif'}}>How long we keep your personal data</span></strong>
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '12pt'}}>
          <span style={{fontFamily: '"Montserrat", Sans-serif'}}>We retain your information for as long as it is necessary, for example, to comply with our contractual relationship or to provide you with our service. Where we do not need your information in order to provide the service to you, we retain it only for so long as we have a legitimate business purpose in keeping such data, as may be allowed by applicable laws. However, there are occasions where we are likely to keep this data for longer in accordance with our legal obligations or where it is necessary for the establishment, exercise or defence of legal claims.</span>
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '12pt'}}>
          <span style={{fontFamily: '"Montserrat", Sans-serif'}}>After you have terminated your use of our Platform, we store your information in an aggregated and anonymised format.</span>
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '18pt'}}>
          <strong><span style={{fontFamily: '"Montserrat", Sans-serif'}}>Information relating to children</span></strong>
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '12pt'}}>
          <span style={{fontFamily: '"Montserrat", Sans-serif'}}>MeLife is not directed at children under the age of 13. In certain cases, this age may be higher due to local regulatory requirements, please see your local privacy policy for more information. If you believe that we have personal data about or collected from a child under the relevant age, contact us at: </span><a href="/cdn-cgi/l/email-protection#debfaeaeb2b7b0b5abaeae9e" style={{textDecoration: 'none'}}><span className="Hyperlink" style={{fontFamily: '"Montserrat", Sans-serif'}}>info@</span></a><span className="Hyperlink" style={{fontFamily: '"Montserrat", Sans-serif'}}>melife.id</span><span style={{fontFamily: '"Montserrat", Sans-serif'}}>.</span>
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '18pt'}}>
          <strong><span style={{fontFamily: '"Montserrat", Sans-serif'}}>Complaints</span></strong>
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '12pt'}}>
          <span style={{fontFamily: '"Montserrat", Sans-serif'}}>In the event that you wish to make a complaint about how we process your personal data, contact us immediately at </span><a href="/cdn-cgi/l/email-protection#573627273b3e393c22272717303a363e3b7934383a" style={{textDecoration: 'none'}}><span className="Hyperlink" style={{fontFamily: '"Montserrat", Sans-serif'}}><span className="__cf_email__" data-cfemail="6d0c1d1d01040306181d1d2d0a000c0401430e0200">hi@melife.id</span></span></a><span style={{fontFamily: '"Montserrat", Sans-serif'}}>. We will endeavour to deal with your request as soon as possible. This is without prejudice to your right to launch a claim with the relevant data protection authority.</span>
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '18pt'}}>
          <strong><span style={{fontFamily: '"Montserrat", Sans-serif'}}>Changes</span></strong>
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '12pt'}}>
          <span style={{fontFamily: '"Montserrat", Sans-serif'}}>We may update this Privacy Policy from time to time. When we update the Privacy Policy, we will notify you by updating the “Effective” date at the top of this policy and posting the new Privacy Policy and providing any other notice required by applicable law. Your continued access to or use of the Platform after the date of the updated policy constitutes your acceptance of the updated policy. If you do not agree to the updated policy, you must stop accessing or using the Platform.</span>
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '18pt'}}>
          <strong><span style={{fontFamily: '"Montserrat", Sans-serif'}}>Contact</span></strong>
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '12pt'}}>
          <span style={{fontFamily: '"Montserrat", Sans-serif'}}>If you have questions, comments, or requests regarding this policy, contact us at: </span><a href="/cdn-cgi/l/email-protection#8decfdfde1e4e3e6f8fdfdcdeae0ece4e1a3eee2e0" style={{textDecoration: 'none'}}><span className="Hyperlink" style={{fontFamily: '"Montserrat", Sans-serif'}}><span className="__cf_email__" data-cfemail="f39283839f9a9d98868383b3949e929a9fdd909c9e">hi@melife.id</span></span></a><span style={{fontFamily: '"Montserrat", Sans-serif'}}>.</span>
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '12pt'}}>
          <span style={{fontFamily: '"Montserrat", Sans-serif'}}>&nbsp;</span>
        </p>
      </div>
    </div>
  )
}

export default PrivacyEn
