import { IHomeComponent } from "./types"

const PrivacyMs : React.FC<IHomeComponent> = ({isMobile, ...rest }) => {
  return (
    <div {...rest} className="home-page-wrapper">
      <div className="home-page" style={{
        paddingTop: 100,
      }}>
        <p
          style={{
            marginTop: "6pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            fontSize: "12pt"
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"' }}>
            Berkuat kuasa: 1 Julai 2023
          </span>
        </p>
        <p
          style={{
            marginTop: "6pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            fontSize: "18pt"
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Times New Roman"' }}>Dasar Privasi</span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "6pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            fontSize: "12pt"
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"' }}>
            Apabila anda menggunakan perkhidmatan MeLife, anda akan berkongsi
            beberapa maklumat dengan kami. Oleh itu, kami ingin anda mengetahui
            terlebih dahulu mengenai maklumat yang kami kumpulkan, bagaimana kami
            menggunakannya, dengan siapa kami membagikannya dan kawalan yang kami
            berikan kepada anda untuk mengakses, mengemaskini dan menghapus maklumat
            anda.
          </span>
        </p>
        <p
          style={{
            marginTop: "6pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            fontSize: "12pt"
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"' }}>
            Itulah sebabnya kami menulis Dasar Privasi ini. Itulah sebabnya kami cuba
            menulisnya dengan cara yang mudah difahami oleh semua pengguna kami. Sudah
            tentu, jika anda masih mempunyai pertanyaan mengenai apa-apa dalam Dasar
            Privasi kami, hubungi kami di&nbsp;
          </span>
          <a href="mailto:info@linkda.app" style={{ textDecoration: "none" }}>
            <u>
              <span style={{ fontFamily: '"Times New Roman"', color: "#0563c1" }}>
                hi@melife.id
              </span>
            </u>
          </a>
          <span style={{ fontFamily: '"Times New Roman"' }}>.</span>
        </p>
        <p
          style={{
            marginTop: "6pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            fontSize: "18pt"
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Times New Roman"' }}>Ringkasan</span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "6pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            fontSize: "12pt"
          }}
        >
          <u>
            <span style={{ fontFamily: '"Times New Roman"' }}>
              Maklumat apa yang kami kumpulkan mengenai anda?
            </span>
          </u>
        </p>
        <p
          style={{
            marginTop: "6pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            fontSize: "12pt"
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"' }}>
            Kami mengumpulkan dan memproses maklumat yang anda berikan semasa anda
            membuat akaun dan memuat naik kandungan ke Platform. Ini termasuk maklumat
            teknikal dan tingkah laku mengenai penggunaan Platform anda.
          </span>
          <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp;&nbsp;</span>
          <span style={{ fontFamily: '"Times New Roman"' }}>
            Kami juga mengumpulkan maklumat mengenai anda jika anda memuat turun
            aplikasi dan berinteraksi dengan Platform tanpa membuat akaun.
          </span>
        </p>
        <p
          style={{
            marginTop: "6pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            fontSize: "12pt"
          }}
        >
          <u>
            <span style={{ fontFamily: '"Times New Roman"' }}>
              Bagaimana kami akan menggunakan maklumat mengenai anda?
            </span>
          </u>
        </p>
        <p
          style={{
            marginTop: "6pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            fontSize: "12pt"
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"' }}>
            Kami menggunakan maklumat anda untuk menyediakan Platform kepada anda dan
            untuk memperbaiki dan mentadbirnya.
          </span>
          <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp;&nbsp;</span>
          <span style={{ fontFamily: '"Times New Roman"' }}>
            Kami menggunakan maklumat anda untuk, antara lain, mencadangkan isi
            kandungan untuk anda, memperbaiki dan mengembangkan Platform dan
            memastikan keselamatan anda.
          </span>
          <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp;&nbsp;</span>
          <span style={{ fontFamily: '"Times New Roman"' }}>
            Sekiranya sesuai, kami juga akan menggunakan maklumat peribadi anda untuk
            melayani iklan yang disasarkan dan mempromosikan Platform.
          </span>
        </p>
        <p
          style={{
            marginTop: "6pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            fontSize: "12pt"
          }}
        >
          <u>
            <span style={{ fontFamily: '"Times New Roman"' }}>
              Dengan siapa kami berkongsi maklumat anda?
            </span>
          </u>
        </p>
        <p
          style={{
            marginTop: "6pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            fontSize: "12pt"
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"' }}>
            Kami berkongsi data anda dengan penyedia perkhidmatan pihak ketiga yang
            membantu kami membekalkan perkhidmatan kami, seperti penyedia penyimpanan
            awan. Kami juga berkongsi maklumat anda dengan rakan niaga, syarikat lain
            dalam kumpulan yang sama dengan MeLife, perkhidmatan penyederhanaan
            kandungan, penyedia pengukuran, pengiklan, dan penyedia analitik. Di mana
            dan bila diperlukan oleh undang-undang, kami akan berkongsi maklumat anda
            dengan agensi atau pengawal selia penegak hukum, dan dengan pihak ketiga
            berdasarkan perintah pengadilan yang mengikat secara hukum.
          </span>
        </p>
        <p
          style={{
            marginTop: "6pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            fontSize: "12pt"
          }}
        >
          <u>
            <span style={{ fontFamily: '"Times New Roman"' }}>
              Berapa lama kami menyimpan maklumat anda?
            </span>
          </u>
        </p>
        <p
          style={{
            marginTop: "6pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            fontSize: "12pt"
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"' }}>
            Kami menyimpan maklumat anda selama yang diperlukan untuk memberi anda
            perkhidmatan. Di mana kami tidak memerlukan maklumat anda untuk memberikan
            perkhidmatan kepada anda, kami menyimpannya hanya selagi kami mempunyai
            tujuan perniagaan yang sah dalam menyimpan data tersebut atau di mana kami
            dikenakan kewajiban hukum untuk menyimpan data tersebut. Kami juga akan
            menyimpan data anda jika kami percaya bahawa atau perlu untuk penubuhan,
            pelaksanaan atau pembelaan tuntutan undang-undang.&nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: "6pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            fontSize: "12pt"
          }}
        >
          <u>
            <span style={{ fontFamily: '"Times New Roman"' }}>
              Bagaimana kami akan memberitahu anda tentang perubahan pada Dasar
              Privasi ini?
            </span>
          </u>
        </p>
        <p
          style={{
            marginTop: "6pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            fontSize: "12pt"
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"' }}>
            Kami secara amnya akan memberitahu semua pengguna mengenai perubahan
            material pada dasar ini melalui pemberitahuan di Platform kami. Walau
            bagaimanapun, anda harus melihat polisi ini secara berkala untuk memeriksa
            sebarang perubahan. Kami juga akan mengemas kini tarikh “berkuat kuasa” di
            bahagian atas dasar ini, yang mencerminkan tarikh kuat kuasa polisi
            tersebut. Dengan mengakses atau menggunakan Platform, anda mengakui bahawa
            anda telah membaca dasar ini dan bahawa anda memahami hak anda berkaitan
            dengan data peribadi anda dan bagaimana kami akan mengumpulkan, gunakan
            dan proseskannya.
          </span>
        </p>
        <p
          style={{
            marginTop: "6pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            fontSize: "18pt"
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp;</span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "6pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            fontSize: "18pt"
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Times New Roman"' }}>
              Bilakah MeLife akan mengumpulkan data Peribadi?
            </span>
          </strong>
          <strong>
            <span
              style={{
                width: "14.34pt",
                fontFamily: '"Times New Roman"',
                display: "inline-block"
              }}
            >
              &nbsp;
            </span>
          </strong>
          <strong>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              &nbsp;
            </span>
          </strong>
          <br />
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Kami akan / dapat mengumpulkan data peribadi mengenai anda:
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            &nbsp;&nbsp;
          </span>
        </p>
        <ul style={{ margin: "0pt", paddingLeft: "0pt" }}>
          <li
            style={{
              marginTop: "6pt",
              marginLeft: "28.52pt",
              textAlign: "justify",
              lineHeight: "normal",
              paddingLeft: "7.48pt",
              fontFamily: "serif",
              fontSize: "12pt"
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"' }}>
              semasa anda mendaftar dan / atau menggunakan Perkhidmatan atau Platform
              kami, atau membuka akaun dengan kami;
            </span>
            <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp;&nbsp;</span>
          </li>
          <li
            style={{
              marginLeft: "28.52pt",
              textAlign: "justify",
              lineHeight: "normal",
              paddingLeft: "7.48pt",
              fontFamily: "serif",
              fontSize: "12pt"
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"' }}>
              apabila anda menghantar sebarang borang, termasuk, tetapi tidak terhad
              kepada, borang permohonan atau borang lain yang berkaitan dengan
              mana-mana produk dan perkhidmatan kami, sama ada dalam talian atau
              melalui cara fizikal;
            </span>
            <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp;&nbsp;</span>
          </li>
          <li
            style={{
              marginLeft: "28.52pt",
              textAlign: "justify",
              lineHeight: "normal",
              paddingLeft: "7.48pt",
              fontFamily: "serif",
              fontSize: "12pt"
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"' }}>
              apabila anda membuat perjanjian atau memberikan dokumentasi atau
              maklumat lain berkenaan dengan interaksi anda dengan kami, atau semasa
              anda menggunakan produk dan perkhidmatan kami;
            </span>
            <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp;&nbsp;</span>
          </li>
          <li
            style={{
              marginLeft: "28.52pt",
              textAlign: "justify",
              lineHeight: "normal",
              paddingLeft: "7.48pt",
              fontFamily: "serif",
              fontSize: "12pt"
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"' }}>
              semasa anda berinteraksi dengan kami, seperti melalui panggilan telefon
              ( yang mungkin direkodkan ), surat, faks, pertemuan tatap muka, platform
              media sosial dan e-mel, termasuk semasa anda berinteraksi dengan ejen
              perkhidmatan pelanggan kami;
            </span>
            <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp;&nbsp;</span>
          </li>
          <li
            style={{
              marginLeft: "28.52pt",
              textAlign: "justify",
              lineHeight: "normal",
              paddingLeft: "7.48pt",
              fontFamily: "serif",
              fontSize: "12pt"
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"' }}>
              semasa anda menggunakan perkhidmatan elektronik kami, atau berinteraksi
              dengan kami melalui aplikasi kami atau menggunakan Perkhidmatan di
              Platform kami. Ini termasuk, tanpa batasan, melalui kuki yang mungkin
              kami gunakan semasa anda berinteraksi dengan aplikasi atau laman web
              kami;
            </span>
            <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp;&nbsp;</span>
          </li>
          <li
            style={{
              marginLeft: "28.52pt",
              textAlign: "justify",
              lineHeight: "normal",
              paddingLeft: "7.48pt",
              fontFamily: "serif",
              fontSize: "12pt"
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"' }}>
              apabila anda memberikan kebenaran pada peranti anda untuk berkongsi
              maklumat dengan aplikasi atau Platform kami;&nbsp;
            </span>
          </li>
          <li
            style={{
              marginLeft: "28.52pt",
              textAlign: "justify",
              lineHeight: "normal",
              paddingLeft: "7.48pt",
              fontFamily: "serif",
              fontSize: "12pt"
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"' }}>
              semasa anda melakukan transaksi melalui Perkhidmatan kami;
            </span>
            <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp;&nbsp;</span>
          </li>
          <li
            style={{
              marginLeft: "28.52pt",
              textAlign: "justify",
              lineHeight: "normal",
              paddingLeft: "7.48pt",
              fontFamily: "serif",
              fontSize: "12pt"
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"' }}>
              apabila anda memberi kami maklum balas atau aduan;
            </span>
            <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp;&nbsp;</span>
          </li>
          <li
            style={{
              marginLeft: "28.52pt",
              textAlign: "justify",
              lineHeight: "normal",
              paddingLeft: "7.48pt",
              fontFamily: "serif",
              fontSize: "12pt"
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"' }}>
              semasa anda mendaftar untuk peraduan; atau
            </span>
            <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp;&nbsp;</span>
          </li>
          <li
            style={{
              marginLeft: "28.52pt",
              marginBottom: "6pt",
              textAlign: "justify",
              lineHeight: "normal",
              paddingLeft: "7.48pt",
              fontFamily: "serif",
              fontSize: "12pt"
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"' }}>
              semasa anda menyerahkan data peribadi anda kepada kami dengan alasan apa
              pun
            </span>
            <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp;&nbsp;</span>
          </li>
        </ul>
        <p
          style={{
            marginTop: "6pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            fontSize: "12pt"
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"' }}>
            Perkara di atas tidak bermaksud lengkap dan menunjukkan beberapa contoh
            umum mengenai data peribadi mengenai anda mungkin dikumpulkan.
          </span>
          <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp;&nbsp;</span>
        </p>
        <p
          style={{
            marginTop: "6pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            fontSize: "18pt"
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Times New Roman"' }}>
              Data peribadi apa yang akan dikumpulkan oleh MeLife?
            </span>
          </strong>
          <strong>
            <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp;&nbsp;</span>
          </strong>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Data peribadi yang mungkin dikumpulkan oleh MeLife merangkumi tetapi
            tidak terhad kepada:
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            &nbsp;&nbsp;
          </span>
        </p>
        <ul style={{ margin: "0pt", paddingLeft: "0pt" }}>
          <li
            style={{
              marginTop: "6pt",
              marginLeft: "28.52pt",
              textAlign: "justify",
              lineHeight: "normal",
              paddingLeft: "7.48pt",
              fontFamily: "serif",
              fontSize: "12pt"
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"' }}>nama;</span>
            <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp;&nbsp;</span>
          </li>
          <li
            style={{
              marginLeft: "28.52pt",
              textAlign: "justify",
              lineHeight: "normal",
              paddingLeft: "7.48pt",
              fontFamily: "serif",
              fontSize: "12pt"
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"' }}>alamat e-mel;</span>
            <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp;&nbsp;</span>
          </li>
          <li
            style={{
              marginLeft: "28.52pt",
              textAlign: "justify",
              lineHeight: "normal",
              paddingLeft: "7.48pt",
              fontFamily: "serif",
              fontSize: "12pt"
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"' }}>tarikh lahir;</span>
            <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp;&nbsp;</span>
          </li>
          <li
            style={{
              marginLeft: "28.52pt",
              textAlign: "justify",
              lineHeight: "normal",
              paddingLeft: "7.48pt",
              fontFamily: "serif",
              fontSize: "12pt"
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"' }}>
              alamat penagihan dan / atau penghantaran;
            </span>
            <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp;&nbsp;</span>
          </li>
          <li
            style={{
              marginLeft: "28.52pt",
              textAlign: "justify",
              lineHeight: "normal",
              paddingLeft: "7.48pt",
              fontFamily: "serif",
              fontSize: "12pt"
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"' }}>
              maklumat akaun bank dan pembayaran;
            </span>
            <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp;&nbsp;</span>
          </li>
          <li
            style={{
              marginLeft: "28.52pt",
              textAlign: "justify",
              lineHeight: "normal",
              paddingLeft: "7.48pt",
              fontFamily: "serif",
              fontSize: "12pt"
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"' }}>nombor telefon;</span>
            <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp;&nbsp;</span>
          </li>
          <li
            style={{
              marginLeft: "28.52pt",
              textAlign: "justify",
              lineHeight: "normal",
              paddingLeft: "7.48pt",
              fontFamily: "serif",
              fontSize: "12pt"
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"' }}>jantina;</span>
            <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp;&nbsp;</span>
          </li>
          <li
            style={{
              marginLeft: "28.52pt",
              textAlign: "justify",
              lineHeight: "normal",
              paddingLeft: "7.48pt",
              fontFamily: "serif",
              fontSize: "12pt"
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"' }}>lokasi;</span>
          </li>
          <li
            style={{
              marginLeft: "28.52pt",
              textAlign: "justify",
              lineHeight: "normal",
              paddingLeft: "7.48pt",
              fontFamily: "serif",
              fontSize: "12pt"
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"' }}>
              maklumat yang dihantar oleh atau dikaitkan dengan peranti yang digunakan
              untuk mengakses Perkhidmatan atau Platform kami;
            </span>
            <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp;&nbsp;</span>
          </li>
          <li
            style={{
              marginLeft: "28.52pt",
              textAlign: "justify",
              lineHeight: "normal",
              paddingLeft: "7.48pt",
              fontFamily: "serif",
              fontSize: "12pt"
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"' }}>
              maklumat mengenai rangkaian anda dan orang serta akaun yang anda
              berinteraksi;&nbsp;
            </span>
          </li>
          <li
            style={{
              marginLeft: "28.52pt",
              textAlign: "justify",
              lineHeight: "normal",
              paddingLeft: "7.48pt",
              fontFamily: "serif",
              fontSize: "12pt"
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"' }}>
              rakaman gambar atau audio atau video;&nbsp;
            </span>
          </li>
          <li
            style={{
              marginLeft: "28.52pt",
              textAlign: "justify",
              lineHeight: "normal",
              paddingLeft: "7.48pt",
              fontFamily: "serif",
              fontSize: "12pt"
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"' }}>
              pengenalan yang dikeluarkan oleh kerajaan atau maklumat lain yang
              diperlukan untuk usaha wajar kami, ketahui pelanggan anda, pengesahan
              identiti, atau tujuan pencegahan penipuan;
            </span>
            <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp;&nbsp;</span>
          </li>
          <li
            style={{
              marginLeft: "28.52pt",
              textAlign: "justify",
              lineHeight: "normal",
              paddingLeft: "7.48pt",
              fontFamily: "serif",
              fontSize: "12pt"
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"' }}>
              data pemasaran dan komunikasi, seperti pilihan anda dalam menerima
              pemasaran dari kami dan pihak ketiga, pilihan komunikasi anda dan
              sejarah komunikasi dengan kami, penyedia perkhidmatan kami, dan pihak
              ketiga yang lain;
            </span>
            <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp;&nbsp;</span>
          </li>
          <li
            style={{
              marginLeft: "28.52pt",
              textAlign: "justify",
              lineHeight: "normal",
              paddingLeft: "7.48pt",
              fontFamily: "serif",
              fontSize: "12pt"
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"' }}>
              data penggunaan dan transaksi, termasuk perincian mengenai carian,
              pesanan, iklan dan kandungan anda yang anda berinteraksi di Platform,
              dan produk dan perkhidmatan lain yang berkaitan dengan anda;&nbsp;
            </span>
          </li>
          <li
            style={{
              marginLeft: "28.52pt",
              textAlign: "justify",
              lineHeight: "normal",
              paddingLeft: "7.48pt",
              fontFamily: "serif",
              fontSize: "12pt"
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"' }}>
              data lokasi;&nbsp;
            </span>
          </li>
          <li
            style={{
              marginLeft: "28.52pt",
              textAlign: "justify",
              lineHeight: "normal",
              paddingLeft: "7.48pt",
              fontFamily: "serif",
              fontSize: "12pt"
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"' }}>
              sebarang maklumat lain mengenai Pengguna ketika Pengguna mendaftar untuk
              menggunakan Perkhidmatan atau Platform kami, dan ketika Pengguna
              menggunakan Perkhidmatan atau Platform, serta maklumat yang berkaitan
              dengan bagaimana Pengguna menggunakan Perkhidmatan atau Platform kami;
              dan
            </span>
            <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp;&nbsp;</span>
          </li>
          <li
            style={{
              marginLeft: "28.52pt",
              marginBottom: "6pt",
              textAlign: "justify",
              lineHeight: "normal",
              paddingLeft: "7.48pt",
              fontFamily: "serif",
              fontSize: "12pt"
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"' }}>
              agregat data mengenai kandungan yang digunakan oleh Pengguna.
            </span>
            <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp;&nbsp;</span>
          </li>
        </ul>
        <p
          style={{
            marginTop: "6pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            fontSize: "12pt"
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"' }}>
            Anda bersetuju untuk tidak menyerahkan maklumat kepada kami yang tidak
            tepat atau mengelirukan, dan anda bersetuju untuk memberitahu kami
            mengenai ketidaktepatan atau perubahan maklumat tersebut. Kami berhak
            untuk memerlukan dokumentasi lebih lanjut untuk mengesahkan maklumat yang
            diberikan oleh anda.
          </span>
          <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp;&nbsp;</span>
        </p>
        <p
          style={{
            marginTop: "6pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            fontSize: "12pt"
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"' }}>
            Sekiranya anda tidak mahu kami mengumpulkan maklumat / data peribadi yang
            disebutkan di atas, anda hanya boleh membatalkan kebenaran anda dengan
            mengubah tetapan di aplikasi atau pada peranti anda jika peranti anda
            menawarkan pilihan tersebut. Sudah tentu, jika anda melakukannya,
            perkhidmatan tertentu mungkin kehilangan fungsi sepenuhnya. Sebagai
            contoh, memilih untuk tidak mengambil maklumat lokasi akan menyebabkan
            ciri berdasarkan lokasinya dilumpuhkan.
          </span>
          <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp;&nbsp;</span>
        </p>
        <p
          style={{
            marginTop: "6pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            fontSize: "12pt"
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Times New Roman"' }}>
              Maklumat dari Pihak Ketiga.
            </span>
          </strong>
          <span style={{ fontFamily: '"Times New Roman"' }}>
            &nbsp;Anda boleh memilih untuk berkongsi data tertentu dengan kami dari
            pihak ketiga atau melalui penggunaan Platform anda, kami mungkin
            mengumpulkan data pihak ketiga tersebut secara automatik. Kami menggunakan
            maklumat yang dikumpulkan dari anda, dan interaksi anda dengan Platform
            dan laman web dan Aplikasi pihak ketiga yang lain, untuk menyimpulkan
            kemungkinan minat anda untuk memberi anda iklan yang lebih berkaitan.
            Maklumat ini memberitahu kami mengenai laman web yang anda lawati,
            aplikasi yang telah anda muat turun dan pembelian yang anda buat sehingga
            kami dapat meramalkan apa lagi yang mungkin menarik minat anda di masa
            depan dan menilai seberapa berkesan iklan di Platform kami. Kami
            mengumpulkan maklumat ini dengan menggunakan Cookies dan teknologi serupa
            di Platform kami dan dari maklumat serupa yang diterima dari pihak ketiga
            yang beriklan di Platform kami dan laman web / Aplikasi yang anda lawati.
          </span>
        </p>
        <p
          style={{
            marginTop: "6pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            fontSize: "12pt"
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Times New Roman"' }}>
              Maklumat Teknikal yang kami kumpulkan mengenai anda.
            </span>
          </strong>
          <span style={{ fontFamily: '"Times New Roman"' }}>
            &nbsp;Kami mengumpulkan maklumat tertentu mengenai peranti yang anda
            gunakan untuk mengakses Platform, seperti alamat IP anda, ejen pengguna,
            pembawa mudah alih, tetapan zon waktu, pengenal untuk tujuan pengiklanan,
            model peranti anda, sistem peranti, jenis rangkaian, ID peranti, resolusi
            skrin dan sistem operasi anda, nama dan jenis aplikasi dan fail, corak
            atau irama penekanan kekunci, keadaan bateri, tetapan audio dan peranti
            audio yang disambungkan. Di mana anda log masuk dari pelbagai peranti,
            kami akan dapat menggunakan maklumat profil anda untuk mengenal pasti
            aktiviti anda di seluruh peranti. Kami juga boleh mengaitkan anda dengan
            maklumat yang dikumpulkan dari peranti selain yang anda gunakan untuk log
            masuk ke Platform.&nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: "6pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            fontSize: "12pt"
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Times New Roman"' }}>Lokasi.</span>
          </strong>
          <span style={{ fontFamily: '"Times New Roman"' }}>
            &nbsp;Kami mengumpulkan maklumat mengenai lokasi anggaran anda, termasuk
            maklumat lokasi berdasarkan kad SIM dan / atau alamat IP anda. Dengan izin
            anda, kami juga dapat mengumpulkan data lokasi yang tepat (seperti GPS).
          </span>
        </p>
        <p
          style={{
            marginTop: "6pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            fontSize: "12pt"
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Times New Roman"' }}>
              Cari pengguna lain dan jemput rakan anda.
            </span>
          </strong>
          <span style={{ fontFamily: '"Times New Roman"' }}>
            &nbsp;Anda boleh memilih sama ada untuk mencari pengguna Platform lain
            menggunakan fungsi 'Cari Rakan' kami. Fungsi ini membolehkan anda melihat
            rakan anda dari senarai kenalan telefon anda atau orang yang berdekatan
            menggunakan Platform dan menawarkan anda pilihan untuk mengikutinya. Anda
            juga boleh menggunakan fungsi ini untuk mengundang kenalan anda untuk
            menyertai anda di Platform. Kami akan menggunakan maklumat hubungan yang
            anda miliki untuk orang itu, dari dalam senarai kenalan telefon anda dan
            memberi anda pilihan untuk menghantar SMS kepada mereka, e-mel atau mesej
            pihak ketiga (seperti Whatsapp, Facebook (termasuk Facebook Messenger)
            atau Twitter) menjemput mereka untuk melihat profil MeLife anda.
          </span>
          <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp;&nbsp;</span>
        </p>
        <p
          style={{
            marginTop: "6pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            fontSize: "18pt"
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Times New Roman"' }}>KUKI</span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "6pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            fontSize: "12pt"
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"' }}>
            Kami atau penyedia perkhidmatan dan rakan pengiklanan kami yang sah boleh
            dari semasa ke semasa menggunakan "kuki" atau ciri lain untuk membolehkan
            kami atau pihak ketiga mengumpulkan atau berkongsi maklumat berkaitan
            dengan penggunaan Perkhidmatan atau Platform kami oleh anda. Ciri-ciri ini
            membantu kami meningkatkan Platform dan Perkhidmatan yang kami tawarkan,
            membantu kami menawarkan perkhidmatan dan ciri baru, dan / atau
            membolehkan kami dan rakan pengiklanan kami melayani kandungan yang lebih
            berkaitan kepada anda, termasuk melalui pemasaran semula. “Kuki” adalah
            pengecam yang disimpan di komputer atau peranti mudah alih anda yang
            merakam data mengenai komputer atau peranti mudah alih, bagaimana dan bila
            Perkhidmatan atau Platform digunakan atau dikunjungi, oleh berapa banyak
            orang dan aktiviti lain dalam Platform kami. Kami mungkin menghubungkan
            maklumat kuki ke data peribadi. Kuki juga menghubungkan ke maklumat
            mengenai item apa yang telah anda pilih untuk pembelian dan halaman web
            yang telah anda lihat. Maklumat ini digunakan untuk melacak keranjang
            belanja anda, untuk menyampaikan kandungan khusus untuk minat anda, untuk
            membolehkan rakan pengiklanan pihak ketiga kami menayangkan iklan di laman
            web di seluruh internet, dan untuk melakukan analisis data dan untuk
            memantau penggunaan Perkhidmatan.
          </span>
          <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp;&nbsp;</span>
        </p>
        <p
          style={{
            marginTop: "6pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            fontSize: "18pt"
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Anda boleh menolak penggunaan kuki dengan memilih tetapan yang sesuai pada
            penyemak imbas peranti anda. Walau bagaimanapun, harap maklum bahawa jika
            anda melakukan ini, anda mungkin tidak dapat menggunakan fungsi penuh
            Platform atau Perkhidmatan kami.
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            &nbsp;&nbsp;
          </span>
          <strong>
            <span style={{ fontFamily: '"Times New Roman"' }}>
              Bagaimana kami menggunakan data peribadi anda
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "6pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            fontSize: "12pt"
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"' }}>
            Kami akan menggunakan maklumat yang kami kumpulkan mengenai anda dengan
            cara berikut:
          </span>
        </p>
        <ul style={{ margin: "0pt", paddingLeft: "0pt" }}>
          <li
            style={{
              marginTop: "6pt",
              marginLeft: "28.52pt",
              textAlign: "justify",
              lineHeight: "normal",
              paddingLeft: "7.48pt",
              fontFamily: "serif",
              fontSize: "12pt"
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"' }}>
              memberitahu anda mengenai perubahan perkhidmatan kami;
            </span>
          </li>
          <li
            style={{
              marginLeft: "28.52pt",
              textAlign: "justify",
              lineHeight: "normal",
              paddingLeft: "7.48pt",
              fontFamily: "serif",
              fontSize: "12pt"
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"' }}>
              memberi anda sokongan pengguna;
            </span>
          </li>
          <li
            style={{
              marginLeft: "28.52pt",
              textAlign: "justify",
              lineHeight: "normal",
              paddingLeft: "7.48pt",
              fontFamily: "serif",
              fontSize: "12pt"
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"' }}>
              memperibadikan kandungan yang anda terima dan memberi anda kandungan
              yang disesuaikan yang akan menarik minat anda;
            </span>
          </li>
          <li
            style={{
              marginLeft: "28.52pt",
              textAlign: "justify",
              lineHeight: "normal",
              paddingLeft: "7.48pt",
              fontFamily: "serif",
              fontSize: "12pt"
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"' }}>
              membolehkan anda berkongsi Kandungan Pengguna dan berinteraksi dengan
              pengguna lain;
            </span>
          </li>
          <li
            style={{
              marginLeft: "28.52pt",
              textAlign: "justify",
              lineHeight: "normal",
              paddingLeft: "7.48pt",
              fontFamily: "serif",
              fontSize: "12pt"
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"' }}>
              membolehkan perkhidmatan utusan kami berfungsi jika anda memilih untuk
              menggunakan fungsi ini;
            </span>
          </li>
          <li
            style={{
              marginLeft: "28.52pt",
              textAlign: "justify",
              lineHeight: "normal",
              paddingLeft: "7.48pt",
              fontFamily: "serif",
              fontSize: "12pt"
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"' }}>
              membolehkan anda mengambil bahagian dalam program item maya;&nbsp;
            </span>
          </li>
          <li
            style={{
              marginLeft: "28.52pt",
              textAlign: "justify",
              lineHeight: "normal",
              paddingLeft: "7.48pt",
              fontFamily: "serif",
              fontSize: "12pt"
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"' }}>
              berkomunikasi dengan anda;&nbsp;
            </span>
          </li>
          <li
            style={{
              marginLeft: "28.52pt",
              textAlign: "justify",
              lineHeight: "normal",
              paddingLeft: "7.48pt",
              fontFamily: "serif",
              fontSize: "12pt"
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"' }}>
              untuk membantu kita mengesan dan memerangi penyalahgunaan, aktiviti
              berbahaya, penipuan, spam, dan aktiviti haram di Platform;
            </span>
          </li>
          <li
            style={{
              marginLeft: "28.52pt",
              textAlign: "justify",
              lineHeight: "normal",
              paddingLeft: "7.48pt",
              fontFamily: "serif",
              fontSize: "12pt"
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"' }}>
              memastikan keselamatan dan keselamatan anda termasuk meninjau kandungan
              pengguna, mesej dan metadata yang berkaitan kerana melanggar Garis
              Panduan Komuniti kami dan kandungan lain yang tidak sesuai;
            </span>
          </li>
          <li
            style={{
              marginLeft: "28.52pt",
              textAlign: "justify",
              lineHeight: "normal",
              paddingLeft: "7.48pt",
              fontFamily: "serif",
              fontSize: "12pt"
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"' }}>
              memastikan kandungan disajikan dengan cara yang paling berkesan untuk
              anda dan peranti anda;
            </span>
          </li>
          <li
            style={{
              marginLeft: "28.52pt",
              textAlign: "justify",
              lineHeight: "normal",
              paddingLeft: "7.48pt",
              fontFamily: "serif",
              fontSize: "12pt"
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"' }}>
              memperbaiki, mempromosikan dan mengembangkan Platform dan mempromosikan
              topik, hashtag dan kempen popular di Platform;
            </span>
          </li>
          <li
            style={{
              marginLeft: "28.52pt",
              textAlign: "justify",
              lineHeight: "normal",
              paddingLeft: "7.48pt",
              fontFamily: "serif",
              fontSize: "12pt"
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"' }}>
              menjalankan analisis data dan menguji Platform untuk memastikan
              kestabilan dan keselamatannya;
            </span>
          </li>
          <li
            style={{
              marginLeft: "28.52pt",
              textAlign: "justify",
              lineHeight: "normal",
              paddingLeft: "7.48pt",
              fontFamily: "serif",
              fontSize: "12pt"
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"' }}>
              membolehkan anda mengambil bahagian dalam ciri interaktif Platform;
            </span>
          </li>
          <li
            style={{
              marginLeft: "28.52pt",
              textAlign: "justify",
              lineHeight: "normal",
              paddingLeft: "7.48pt",
              fontFamily: "serif",
              fontSize: "12pt"
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"' }}>
              untuk membolehkan anda bersosial di Platform, misalnya, dengan
              membenarkan pengguna lain mengenal pasti anda melalui fungsi "Cari rakan
              lain" atau melalui kenalan telefon mereka;&nbsp;
            </span>
          </li>
          <li
            style={{
              marginLeft: "28.52pt",
              textAlign: "justify",
              lineHeight: "normal",
              paddingLeft: "7.48pt",
              fontFamily: "serif",
              fontSize: "12pt"
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"' }}>
              untuk memastikan bahawa anda cukup tua untuk menggunakan Platform kami
              (seperti yang dikehendaki oleh undang-undang).
            </span>
          </li>
          <li
            style={{
              marginLeft: "28.52pt",
              textAlign: "justify",
              lineHeight: "normal",
              paddingLeft: "7.48pt",
              fontFamily: "serif",
              fontSize: "12pt"
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"' }}>
              memberi anda iklan yang diperibadikan;
            </span>
          </li>
          <li
            style={{
              marginLeft: "28.52pt",
              textAlign: "justify",
              lineHeight: "normal",
              paddingLeft: "7.48pt",
              fontFamily: "serif",
              fontSize: "12pt"
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"' }}>
              memberi anda perkhidmatan berdasarkan lokasi (di mana perkhidmatan
              tersebut tersedia di bidang kuasa anda);
            </span>
          </li>
          <li
            style={{
              marginLeft: "28.52pt",
              textAlign: "justify",
              lineHeight: "normal",
              paddingLeft: "7.48pt",
              fontFamily: "serif",
              fontSize: "12pt"
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"' }}>
              Untuk memberitahu algoritma kami
            </span>
          </li>
          <li
            style={{
              marginLeft: "28.52pt",
              textAlign: "justify",
              lineHeight: "normal",
              paddingLeft: "7.48pt",
              fontFamily: "serif",
              fontSize: "12pt"
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"' }}>
              menguatkuasakan syarat dan dasar kami;&nbsp;
            </span>
          </li>
          <li
            style={{
              marginLeft: "28.52pt",
              textAlign: "justify",
              lineHeight: "normal",
              paddingLeft: "7.48pt",
              fontFamily: "serif",
              fontSize: "12pt"
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"' }}>
              mentadbir Platform termasuk menyelesaikan masalah; dan
            </span>
          </li>
          <li
            style={{
              marginLeft: "28.52pt",
              marginBottom: "6pt",
              textAlign: "justify",
              lineHeight: "normal",
              paddingLeft: "7.48pt",
              fontFamily: "serif",
              fontSize: "12pt"
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"' }}>
              untuk memudahkan penjualan, promosi, dan pembelian barang dan
              perkhidmatan dan untuk memberikan sokongan pengguna
            </span>
          </li>
        </ul>
        <p
          style={{
            marginTop: "6pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            fontSize: "18pt"
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Times New Roman"' }}>
              Bagaimana kami berkongsi data peribadi anda
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "6pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            fontSize: "12pt"
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"' }}>
            Kami berkongsi data anda dengan pihak ketiga terpilih berikut:
          </span>
        </p>
        <p
          style={{
            marginTop: "6pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            fontSize: "12pt"
          }}
        >
          <u>
            <span style={{ fontFamily: '"Times New Roman"' }}>
              Penyedia Pembayaran
            </span>
          </u>
        </p>
        <p
          style={{
            marginTop: "6pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            fontSize: "12pt"
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"' }}>
            Sekiranya anda memilih untuk membeli perkhidmatan kami atau melakukan
            transaksi berkaitan pembayaran lain, kami akan berkongsi data dengan
            penyedia pembayaran yang berkaitan untuk memudahkan transaksi ini. Untuk
            transaksi hadiah maya, kami berkongsi ID transaksi untuk membolehkan kami
            mengenal pasti anda dan mengreditkan akaun anda dengan nilai yang betul
            dalam hadiah maya setelah anda membuat pembayaran.
          </span>
        </p>
        <p
          style={{
            marginTop: "6pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            fontSize: "12pt"
          }}
        >
          <u>
            <span style={{ fontFamily: '"Times New Roman"' }}>
              Penyedia Perkhidmatan&nbsp;
            </span>
          </u>
        </p>
        <p
          style={{
            marginTop: "6pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            fontSize: "12pt"
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"' }}>
            Kami memberikan maklumat dan kandungan kepada penyedia perkhidmatan yang
            menyokong perniagaan kami, seperti penyedia perkhidmatan awan dan penyedia
            perkhidmatan penyederhanaan kandungan untuk memastikan bahawa Platform
            adalah tempat dan penyedia perkhidmatan yang selamat dan menyeronokkan
            yang membantu kami dalam memasarkan Platform.&nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: "6pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            fontSize: "12pt"
          }}
        >
          <u>
            <span style={{ fontFamily: '"Times New Roman"' }}>
              Penyedia analisis&nbsp;
            </span>
          </u>
        </p>
        <p
          style={{
            marginTop: "6pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            fontSize: "12pt"
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"' }}>
            Kami menggunakan penyedia analitik untuk membantu kami dalam pengoptimuman
            dan peningkatan Platform.
          </span>
          <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp;&nbsp;</span>
          <span style={{ fontFamily: '"Times New Roman"' }}>
            Penyedia analitik pihak ketiga kami juga membantu kami melayani iklan yang
            disasarkan.
          </span>
        </p>
        <p
          style={{
            marginTop: "6pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            fontSize: "12pt"
          }}
        >
          <u>
            <span style={{ fontFamily: '"Times New Roman"' }}>
              Pengiklan dan Rangkaian Iklan
            </span>
          </u>
        </p>
        <p
          style={{
            marginTop: "6pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            fontSize: "12pt"
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"' }}>
            Kami berkongsi maklumat dengan pengiklan dan syarikat pengukuran pihak
            ketiga untuk menunjukkan berapa banyak dan pengguna Platform mana yang
            telah melihat atau mengklik iklan. Kami berkongsi ID peranti anda dengan
            syarikat pengukuran supaya kami dapat menghubungkan aktiviti anda di
            Platform dengan aktiviti anda di laman web lain; kami kemudian menggunakan
            maklumat ini untuk menunjukkan iklan yang mungkin menarik bagi anda.&nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: "6pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            fontSize: "12pt"
          }}
        >
          <u>
            <span style={{ fontFamily: '"Times New Roman"' }}>
              Kumpulan Korporat kami
            </span>
          </u>
        </p>
        <p
          style={{
            marginTop: "6pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            fontSize: "12pt"
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"' }}>
            Kami juga boleh berkongsi maklumat anda dengan ahli, anak syarikat, atau
            sekutu kumpulan korporat kami yang lain, untuk menyediakan Platform
            termasuk meningkatkan dan mengoptimumkan Platform, mencegah penggunaan
            haram dan menyokong pengguna.
          </span>
        </p>
        <p
          style={{
            marginTop: "6pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            fontSize: "12pt"
          }}
        >
          <u>
            <span style={{ fontFamily: '"Times New Roman"' }}>
              Penguatkuasaan Undang-Undang
            </span>
          </u>
        </p>
        <p
          style={{
            marginTop: "6pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            fontSize: "12pt"
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"' }}>
            Kami akan berkongsi maklumat anda dengan agensi penguatkuasaan
            undang-undang, pihak berkuasa awam atau organisasi lain jika diperlukan
            secara sah untuk melakukannya, atau jika penggunaan tersebut wajar untuk:
          </span>
        </p>
        <ul style={{ margin: "0pt", paddingLeft: "0pt" }}>
          <li
            style={{
              marginTop: "6pt",
              marginLeft: "27.97pt",
              textAlign: "justify",
              lineHeight: "normal",
              paddingLeft: "7.48pt",
              fontFamily: "serif",
              fontSize: "12pt"
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"' }}>
              mematuhi kewajiban, proses atau permintaan undang-undang;
            </span>
          </li>
          <li
            style={{
              marginLeft: "27.97pt",
              textAlign: "justify",
              lineHeight: "normal",
              paddingLeft: "7.48pt",
              fontFamily: "serif",
              fontSize: "12pt"
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"' }}>
              menguatkuasakan Syarat Perkhidmatan kami dan perjanjian, polisi, dan
              piawaian lain, termasuk penyiasatan terhadap kemungkinan pelanggarannya;
            </span>
          </li>
          <li
            style={{
              marginLeft: "27.97pt",
              textAlign: "justify",
              lineHeight: "normal",
              paddingLeft: "7.48pt",
              fontFamily: "serif",
              fontSize: "12pt"
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"' }}>
              mengesan, mencegah atau menangani masalah keselamatan, penipuan atau
              teknikal; atau
            </span>
          </li>
          <li
            style={{
              marginLeft: "27.97pt",
              marginBottom: "6pt",
              textAlign: "justify",
              lineHeight: "normal",
              paddingLeft: "7.48pt",
              fontFamily: "serif",
              fontSize: "12pt"
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"' }}>
              melindungi hak, harta benda atau keselamatan kita, pengguna kita, pihak
              ketiga atau orang awam seperti yang dikehendaki atau dibenarkan oleh
              undang-undang (termasuk bertukar maklumat dengan syarikat dan organisasi
              lain untuk tujuan perlindungan penipuan dan pengurangan risiko kredit).
            </span>
          </li>
        </ul>
        <p
          style={{
            marginTop: "6pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            fontSize: "12pt"
          }}
        >
          <u>
            <span style={{ fontFamily: '"Times New Roman"' }}>Profil Awam</span>
          </u>
        </p>
        <p
          style={{
            marginTop: "6pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            fontSize: "12pt"
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"' }}>
            Harap maklum bahawa jika profil anda terbuka, kandungan anda akan dapat
            dilihat oleh sesiapa sahaja di Platform dan juga boleh diakses atau
            dikongsi oleh rakan dan pengikut anda serta pihak ketiga seperti mesin
            pencari, agregator kandungan dan laman berita. Anda boleh menukar siapa
            yang dapat melihat video setiap kali anda memuat naik video. Sebagai
            alternatif, anda boleh menukar profil anda menjadi lalai peribadi dengan
            menukar tetapan anda menjadi 'Akaun Swasta' dalam tetapan “ Uruskan akaun
            saya ”.&nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: "6pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            fontSize: "12pt"
          }}
        >
          <u>
            <span style={{ fontFamily: '"Times New Roman"' }}>
              Jualan atau Penggabungan
            </span>
          </u>
        </p>
        <p
          style={{
            marginTop: "6pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            fontSize: "12pt"
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"' }}>
            Kami juga akan mendedahkan maklumat anda kepada pihak ketiga:
          </span>
        </p>
        <ul style={{ margin: "0pt", paddingLeft: "0pt" }}>
          <li
            style={{
              marginTop: "6pt",
              marginLeft: "27.97pt",
              textAlign: "justify",
              lineHeight: "normal",
              paddingLeft: "7.48pt",
              fontFamily: "serif",
              fontSize: "12pt"
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"' }}>
              sekiranya kita menjual atau membeli perniagaan atau aset (sama ada
              akibat pembubaran, kebankrapan atau sebaliknya), dalam hal ini kami akan
              mendedahkan data anda kepada bakal penjual atau pembeli perniagaan atau
              aset tersebut; atau
            </span>
          </li>
          <li
            style={{
              marginLeft: "27.97pt",
              marginBottom: "6pt",
              textAlign: "justify",
              lineHeight: "normal",
              paddingLeft: "7.48pt",
              fontFamily: "serif",
              fontSize: "12pt"
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"' }}>
              jika kita menjual, membeli, menggabungkan, diperoleh oleh, atau
              bekerjasama dengan syarikat atau perniagaan lain, atau menjual
              sebahagian atau semua aset kita. Dalam urus niaga tersebut, maklumat
              pengguna mungkin antara aset yang dipindahkan.
            </span>
          </li>
        </ul>
        <p
          style={{
            marginTop: "6pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            fontSize: "18pt"
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Times New Roman"' }}>
              Di mana kami menyimpan data peribadi anda&nbsp;
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "6pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            fontSize: "12pt"
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"' }}>
            Data peribadi yang kami kumpulkan dari anda mungkin disimpan di pelayan
            yang terletak di Singapura atau Mana-mana Negara, di luar negara tempat
            anda tinggal. Kami mengekalkan pelayan utama di seluruh dunia untuk
            memberikan anda perkhidmatan kami secara global dan berterusan.
          </span>
        </p>
        <p
          style={{
            marginTop: "6pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            fontSize: "18pt"
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Times New Roman"' }}>
              Hak dan Pilihan Anda
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "6pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            fontSize: "12pt"
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"' }}>
            Anda boleh mengakses dan mengedit sebahagian besar maklumat profil anda
            dengan log masuk ke MeLife. Anda boleh menghapus Kandungan Pengguna yang
            anda muat naik. Kami juga menyediakan sejumlah alat dalam Tetapan yang
            memungkinkan Anda untuk mengendalikan, antara lain, yang dapat melihat
            video anda, mengirim pesan kepada anda, atau mengirim komen ke video anda.
            Sekiranya anda memilih untuk melakukannya, anda boleh menghapus
            keseluruhan akaun anda dalam Tetapan. Anda juga mungkin diberi hak
            tertentu berdasarkan undang-undang yang terpakai, yang mungkin termasuk
            hak untuk mengakses, menghapus, mengemas kini, atau memperbaiki data anda,
            untuk diberitahu mengenai pemrosesan data anda, untuk mengemukakan aduan
            kepada pihak berkuasa, dan berpotensi hak lain. Sekiranya anda mempunyai
            pertanyaan mengenai cara menggunakan alat di atas, ingin mengetahui
            tentang hak apa yang mungkin anda miliki atau ingin menggunakannya, atau
            mempunyai permintaan, pertanyaan, atau aduan, sila hubungi kami di:&nbsp;
          </span>
          <a href="mailto:info@linkda.app" style={{ textDecoration: "none" }}>
            <u>
              <span style={{ fontFamily: '"Times New Roman"', color: "#0563c1" }}>
                hi@melife.id
              </span>
            </u>
          </a>
          <span style={{ fontFamily: '"Times New Roman"' }}>.</span>
        </p>
        <p
          style={{
            marginTop: "6pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            fontSize: "18pt"
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Times New Roman"' }}>
              Keselamatan data peribadi anda
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "6pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            fontSize: "12pt"
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"' }}>
            Kami mengambil langkah untuk memastikan bahawa maklumat anda dilayan
            dengan selamat dan sesuai dengan polisi ini. Malangnya, penghantaran
            maklumat melalui internet tidak sepenuhnya selamat. Walaupun kami akan
            melindungi data peribadi anda, misalnya, dengan penyulitan, kami tidak
            dapat menjamin keselamatan maklumat anda yang dihantar melalui Platform;
            sebarang penghantaran adalah atas risiko anda sendiri.
          </span>
        </p>
        <p
          style={{
            marginTop: "6pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            fontSize: "12pt"
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"' }}>
            Kami mempunyai langkah-langkah teknikal dan organisasi yang sesuai untuk
            memastikan tahap keselamatan yang sesuai dengan risiko kemungkinan dan
            keparahan yang berbeza-beza untuk hak dan kebebasan anda dan pengguna
            lain. Kami mengekalkan langkah-langkah teknikal dan organisasi ini dan
            akan mengubahnya dari semasa ke semasa untuk meningkatkan keselamatan
            keseluruhan sistem kami.
          </span>
        </p>
        <p
          style={{
            marginTop: "6pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            fontSize: "12pt"
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"' }}>
            Kami akan, dari semasa ke semasa, memasukkan pautan ke dan dari laman web
            rangkaian rakan kongsi, pengiklan dan sekutu kami. Sekiranya anda
            mengikuti pautan ke mana-mana laman web ini, harap maklum bahawa laman web
            ini mempunyai dasar privasi mereka sendiri dan bahawa kami tidak menerima
            tanggungjawab atau tanggungjawab untuk polisi ini. Sila periksa polisi ini
            sebelum anda menghantar maklumat ke laman web ini.
          </span>
        </p>
        <p
          style={{
            marginTop: "6pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            fontSize: "18pt"
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Times New Roman"' }}>
              Berapa lama kami menyimpan data peribadi anda
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "6pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            fontSize: "12pt"
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"' }}>
            Kami menyimpan maklumat anda selama yang diperlukan, misalnya, untuk
            mematuhi hubungan kontrak kami atau untuk memberi anda perkhidmatan kami.
            Sekiranya kami tidak memerlukan maklumat anda untuk memberikan
            perkhidmatan kepada anda, kami menyimpannya hanya selama kami mempunyai
            tujuan perniagaan yang sah dalam menyimpan data tersebut, sebagaimana yang
            diizinkan oleh undang-undang yang terpakai. Namun, ada saat-saat di mana
            kita cenderung menyimpan data ini lebih lama sesuai dengan kewajiban hukum
            kita atau di mana perlu untuk penetapan, pelaksanaan atau pembelaan
            tuntutan hukum.
          </span>
        </p>
        <p
          style={{
            marginTop: "6pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            fontSize: "12pt"
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"' }}>
            Setelah anda menghentikan penggunaan Platform kami, kami menyimpan
            maklumat anda dalam format gabungan dan tanpa nama.
          </span>
        </p>
        <p
          style={{
            marginTop: "6pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            fontSize: "18pt"
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Times New Roman"' }}>
              Maklumat yang berkaitan dengan kanak-kanak
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "6pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            fontSize: "12pt"
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"' }}>
            MeLife tidak ditujukan kepada kanak-kanak di bawah umur 13 tahun. Dalam
            kes tertentu, usia ini mungkin lebih tinggi kerana keperluan peraturan
            tempatan, sila lihat dasar privasi tempatan anda untuk maklumat lebih
            lanjut. Sekiranya anda percaya bahawa kami mempunyai data peribadi
            mengenai atau dikumpulkan dari kanak-kanak di bawah usia yang berkenaan,
            hubungi kami di:&nbsp;
          </span>
          <a href="mailto:info@linkda.app" style={{ textDecoration: "none" }}>
            <u>
              <span style={{ fontFamily: '"Times New Roman"', color: "#0563c1" }}>
                hi@melife.id
              </span>
            </u>
          </a>
          <span style={{ fontFamily: '"Times New Roman"' }}>.</span>
        </p>
        <p
          style={{
            marginTop: "6pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            fontSize: "18pt"
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Times New Roman"' }}>Aduan</span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "6pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            fontSize: "12pt"
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"' }}>
            Sekiranya anda ingin membuat aduan mengenai bagaimana kami memproses data
            peribadi anda, segera hubungi kami di&nbsp;
          </span>
          <a href="mailto:info@linkda.app" style={{ textDecoration: "none" }}>
            <u>
              <span style={{ fontFamily: '"Times New Roman"', color: "#0563c1" }}>
                hi@melife.id
              </span>
            </u>
          </a>
          <span style={{ fontFamily: '"Times New Roman"' }}>
            . Kami akan berusaha menangani permintaan anda secepat mungkin. Ini tanpa
            menjejaskan hak anda untuk melancarkan tuntutan dengan pihak berkuasa
            perlindungan data yang berkaitan.
          </span>
        </p>
        <p
          style={{
            marginTop: "6pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            fontSize: "18pt"
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Times New Roman"' }}>Perubahan</span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "6pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            fontSize: "12pt"
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"' }}>
            Kami mungkin mengemas kini Dasar Privasi ini dari semasa ke semasa.
            Apabila kita mengemas kini Dasar Privasi, kami akan memberitahu anda
            dengan mengemas kini tarikh “Berkuat Kuasa” di bahagian atas dasar ini dan
            menyiarkan Dasar Privasi baru dan memberikan notis lain yang diperlukan
            oleh undang-undang yang terpakai. Akses berterusan anda ke atau penggunaan
            Platform setelah tarikh polisi yang dikemas kini merupakan penerimaan anda
            terhadap dasar yang dikemas kini. Sekiranya anda tidak bersetuju dengan
            dasar yang dikemas kini, anda mesti berhenti mengakses atau menggunakan
            Platform.
          </span>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "8pt",
            lineHeight: "108%",
            fontSize: "18pt"
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Times New Roman"' }}>Terjemahan&nbsp;</span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "8pt",
            lineHeight: "108%",
            fontSize: "12pt"
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"' }}>
            Dasar ini ditulis dalam bahasa Inggeris (UK). Setakat mana versi
            terjemahan dari Dasar ini bertentangan dengan versi bahasa Inggeris, versi
            bahasa Inggeris akan berkesan.
          </span>
        </p>
        <p
          style={{
            marginTop: "6pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            fontSize: "18pt"
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Times New Roman"' }}>Hubungi kami</span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "6pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            fontSize: "12pt"
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"' }}>
            Sekiranya anda mempunyai pertanyaan, komen, atau permintaan mengenai
            polisi ini, hubungi kami di:&nbsp;
          </span>
          <a href="mailto:info@linkda.app" style={{ textDecoration: "none" }}>
            <u>
              <span style={{ fontFamily: '"Times New Roman"', color: "#0563c1" }}>
                hi@melife.id
              </span>
            </u>
          </a>
          <span style={{ fontFamily: '"Times New Roman"' }}>.</span>
        </p>
      </div>
    </div>
  )
}

export default PrivacyMs
