import { useEffect } from "react"
import * as device from 'react-device-detect';
import Loader from "../components/Home/Loader"

const Download = () => {
  useEffect(() => {
    console.log(device.osName);

    switch (device.osName) {
      case device.OsTypes.IOS:
      case device.OsTypes.MAC_OS:
        window.location.replace('https://apps.apple.com/us/app/melife');
        break;
      case device.OsTypes.Android:
        window.location.replace('https://play.google.com/store/apps/details?id=id.melife')
        break;    
      default:
        window.location.replace('https://apps.apple.com/us/app/melife');
        break;
    }
  }, [])

  return (
    <div
      className="templates-wrapper"
    >
      {<Loader />}
    </div>
  )
}

export default Download
