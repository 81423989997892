import { IHomeComponent } from "./types"

const PrivacyZh : React.FC<IHomeComponent> = ({isMobile, ...rest }) => {
  return (
    <div {...rest} className="home-page-wrapper">
      <div className="home-page" style={{
        paddingTop: 100,
      }}>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>生效日期</span>:2023
          <span style={{ fontFamily: "等线" }}>年</span>7
          <span style={{ fontFamily: "等线" }}>月</span>1
          <span style={{ fontFamily: "等线" }}>日</span>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <strong>
            <span style={{ fontFamily: "等线" }}>隐私权政策</span>
          </strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>使用</span>MeLife
          <span style={{ fontFamily: "等线" }}>服务时</span>,
          <span style={{ fontFamily: "等线" }}>您将与我们共享一些信息。因此</span>,
          <span style={{ fontFamily: "等线" }}>我们希望先了解我们收集的信息</span>,
          <span style={{ fontFamily: "等线" }}>我们如何使用它</span>,
          <span style={{ fontFamily: "等线" }}>与谁共享</span>,
          <span style={{ fontFamily: "等线" }}>以及我们为您提供进</span>
          <span style={{ fontFamily: "SimSun" }}>接</span>,
          <span style={{ fontFamily: "等线" }}>更新和删除信息的控制。</span>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>
            这就是为什么我们编写了本隐私权政策
          </span>
          ,
          <span style={{ fontFamily: "等线" }}>
            我们尝试以易于理解的方式编写它。当然
          </span>
          ,
          <span style={{ fontFamily: "等线" }}>
            如果您仍然对我们的隐私权政策有任何疑问
          </span>
          ,<span style={{ fontFamily: "等线" }}>请通过</span>hi@melife.id
          <span style={{ fontFamily: "等线" }}>与我们联系。</span>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <strong>
            <span style={{ fontFamily: "等线" }}>摘要</span>
          </strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <u>
            <span style={{ fontFamily: "等线" }}>我们会收集有关您的哪些信息</span>
          </u>
          <u>?</u>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>当您创建帐户并将内容上传到平台时</span>
          ,
          <span style={{ fontFamily: "等线" }}>
            我们会收集和处理您提供给我们的信息。这包括有关您对平台使用的技术和行为信息。
            如果您下载该应用程序并与平台进行交互而不创建帐户
          </span>
          ,<span style={{ fontFamily: "等线" }}>我们也会收集有关您的信息。</span>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <u>
            <span style={{ fontFamily: "等线" }}>我们将如何使用有关您的信息</span>
          </u>
          <u>?</u>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>我们使用您的信息为您提供平台</span>,
          <span style={{ fontFamily: "等线" }}>
            并进行改进和管理。 我们使用您的信息来向您显示建议的供稿
          </span>
          ,
          <span style={{ fontFamily: "等线" }}>
            改进和发展平台并确保您的安全。 在适当的情况下
          </span>
          ,
          <span style={{ fontFamily: "等线" }}>
            我们还将使用您的个人信息为您提供有针对性的广告并推广平台。
          </span>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <u>
            <span style={{ fontFamily: "等线" }}>我们与谁共享您的信息</span>
          </u>
          <u>?</u>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>
            我们与帮助我们交付平台的第三方服务提供商
          </span>
          (<span style={{ fontFamily: "等线" }}>例如云存储提供商</span>)
          <span style={{ fontFamily: "等线" }}>
            共享您的数据。我们还与业务合作伙伴
          </span>
          ,<span style={{ fontFamily: "等线" }}>与</span>MeLife
          <span style={{ fontFamily: "等线" }}>同组的其他公司</span>,
          <span style={{ fontFamily: "等线" }}>内容审核服务</span>,
          <span style={{ fontFamily: "等线" }}>测量提供商</span>,
          <span style={{ fontFamily: "等线" }}>
            广告商和分析提供商共享您的信息。在法律要求的情况和时侯
          </span>
          ,
          <span style={{ fontFamily: "等线" }}>
            我们将根据具有法律约束力的法院命令与执法机构或监管机构以及第三方共享您的信息。
          </span>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <u>
            <span style={{ fontFamily: "等线" }}>我们保留您的信息多长时间</span>
          </u>
          <u>?</u>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>只要有必要为您提供服务</span>,
          <span style={{ fontFamily: "等线" }}>
            我们就会保留您的信息。在不需要您的信息即可为您提供服务的地方
          </span>
          ,{" "}
          <span style={{ fontFamily: "等线" }}>
            只要我们有合法的商业目的来保存此类数据
          </span>
          ,<span style={{ fontFamily: "等线" }}>或者我们有法律义务保留数据</span>,
          <span style={{ fontFamily: "等线" }}>我们才保留它。如果我们认为建立</span>,
          <span style={{ fontFamily: "等线" }}>行使或抗辩法律主张是或有必要</span>,
          <span style={{ fontFamily: "等线" }}>我们还将保留您的数据。</span>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <u>
            <span style={{ fontFamily: "等线" }}>
              我们如何将本隐私权政策的任何更改通知您
            </span>
          </u>
          <u>?</u>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>通常</span>,
          <span style={{ fontFamily: "等线" }}>
            我们会通过平台上的通知将本政策的任何重大更改通知所有用户。但是
          </span>
          ,
          <span style={{ fontFamily: "等线" }}>
            您应该定期查看此策略以检查是否有任何更改。我们还将在本政策的顶部更新“生效”日期
          </span>
          ,
          <span style={{ fontFamily: "等线" }}>
            该日期反映了此类政策的生效日期。通过进
          </span>
          <span style={{ fontFamily: "SimSun" }}>接</span>
          <span style={{ fontFamily: "等线" }}>或使用平台</span>,
          <span style={{ fontFamily: "等线" }}>您承认您已经阅读了本政策</span>,
          <span style={{ fontFamily: "等线" }}>
            并且了解与个人数据有关的权利以及我们将如何收集
          </span>
          , <span style={{ fontFamily: "等线" }}>使用和处理它。</span>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <strong>MeLife</strong>
          <strong>
            <span style={{ fontFamily: "等线" }}>何时会收集个人数据</span>
          </strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>我们将</span>/
          <span style={{ fontFamily: "等线" }}>可能会收集有关您的个人数据</span>
          :&nbsp;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>•当您注册和</span>/
          <span style={{ fontFamily: "等线" }}>或使用我们的服务或平台</span>,
          <span style={{ fontFamily: "等线" }}>或在我们这里开设帐户时</span>;&nbsp;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>•当您提交任何表格时</span>,
          <span style={{ fontFamily: "等线" }}>
            包括但不限于与我们的任何产品和服务有关的申请表格或其他表格
          </span>
          ,<span style={{ fontFamily: "等线" }}>无论是在线还是通过物质形式</span>
          ;&nbsp;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>
            •当您就与我们的互动达成任何协议或提供其他文件或信息时
          </span>
          ,<span style={{ fontFamily: "等线" }}>或者当您使用我们的产品和服务时</span>
          ;&nbsp;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>•当您与我们互动时</span>,
          <span style={{ fontFamily: "等线" }}>例如通过电话</span>(
          <span style={{ fontFamily: "等线" }}>可能记录为</span>),
          <span style={{ fontFamily: "等线" }}>信件</span>,
          <span style={{ fontFamily: "等线" }}>传真</span>,
          <span style={{ fontFamily: "等线" }}>面对面会议</span>,
          <span style={{ fontFamily: "等线" }}>社交媒体平台和电子邮件</span>,{" "}
          <span style={{ fontFamily: "等线" }}>
            包括您与我们的客户服务代理进行交互时
          </span>
          ;&nbsp;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>
            •当您使用我们的电子服务或通过我们的应用程序与我们互动或在我们的平台上使用服务时。这包括但不限于通过
          </span>
          cookie,
          <span style={{ fontFamily: "等线" }}>
            当您与我们的应用程序或网站进行交互时
          </span>
          ,<span style={{ fontFamily: "等线" }}>我们可能会部署这些</span>cookie;&nbsp;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>
            •当您授予设备许可与我们的应用程序或平台共享信息时
          </span>
          ;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>•当您通过我们的服务进行交易时</span>
          ;&nbsp;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>•当您向我们提供反馈或投诉时</span>
          ;&nbsp;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>•注册比赛时</span>;
          <span style={{ fontFamily: "等线" }}>或者</span>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>
            •当您出于任何原因向我们提交个人数据时
          </span>
          <span style={{ fontFamily: "等线" }}>&nbsp;&nbsp;</span>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>以上内容并不详尽</span>,
          <span style={{ fontFamily: "等线" }}>并列出了一些常见的实例</span>,
          <span style={{ fontFamily: "等线" }}>
            这些实例可能会收集有关您的个人数据。&nbsp;
          </span>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <strong>MeLife</strong>
          <strong>
            <span style={{ fontFamily: "等线" }}>将收集哪些个人数据</span>
          </strong>
          <strong>?</strong>
          <strong>&nbsp;&nbsp;</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          MeLife
          <span style={{ fontFamily: "等线" }}>可能收集的个人数据包括但不限于</span>
          :&nbsp;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>•名称</span>;&nbsp;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>•电子邮件地址</span>;&nbsp;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>•出生日期</span>;&nbsp;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>•帐单和</span>/
          <span style={{ fontFamily: "等线" }}>或收货地址</span>;&nbsp;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>•银行帐户和付款信息</span>;&nbsp;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>•电话号码</span>;&nbsp;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>•性别</span>;&nbsp;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>•位置</span>;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>
            •由用于进接我们的服务或平台的设备发送或关联的信息
          </span>
          ;&nbsp;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>
            •有关您的网络以及与之互动的人员和帐户的信息
          </span>
          ;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>•照片或音频或视频记录</span>;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>
            •政府签发的身份证明或我们尽职调查所需的其他信息
          </span>
          ,<span style={{ fontFamily: "等线" }}>了解您的客户</span>,
          <span style={{ fontFamily: "等线" }}>身份验证或预防欺诈的目的</span>;&nbsp;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>•营销和传播数据</span>,
          <span style={{ fontFamily: "等线" }}>
            例如您倾向于从我们和第三方向接受营销
          </span>
          ,<span style={{ fontFamily: "等线" }}>您的交流偏好以及与我们</span>,
          <span style={{ fontFamily: "等线" }}>
            我们的服务提供商和其他第三方的交流历史
          </span>
          ;&nbsp;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>•使用和交易数据</span>,
          <span style={{ fontFamily: "等线" }}>包括有关您的搜索</span>,
          <span style={{ fontFamily: "等线" }}>订单</span>,
          <span style={{ fontFamily: "等线" }}>
            您在平台上与之交互的广告和内容以及与您相关的其他产品和服务的详细信息
          </span>
          ;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>•位置数据</span>;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>
            •当用户注册使用我们的服务或平台以及用户使用服务或平台时
          </span>
          ,<span style={{ fontFamily: "等线" }}>有关用户的任何其他信息</span>,{" "}
          <span style={{ fontFamily: "等线" }}>
            以及与用户如何使用我们的服务或平台有关的信息
          </span>
          ;<span style={{ fontFamily: "等线" }}>和</span>
          <span style={{ fontFamily: "等线" }}>&nbsp;&nbsp;</span>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>•汇总用户参与的内容数据。&nbsp;</span>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>
            您同意不向我们提交任何不准确或误导性的信息
          </span>
          ,
          <span style={{ fontFamily: "等线" }}>
            并且您同意将此类信息的任何不准确或更改告知我们。我们保留自行决定要求进一步文档以验证您提供的信息的权利。&nbsp;
          </span>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>如果您不希望我们收集上述信息</span>/
          <span style={{ fontFamily: "等线" }}>个人数据</span>,{" "}
          <span style={{ fontFamily: "等线" }}>如果您的设备提供了这些选项</span>,
          <span style={{ fontFamily: "等线" }}>
            则只需通过更改应用程序或设备上的设置来撤销您的许可。当然
          </span>
          ,<span style={{ fontFamily: "等线" }}>如果这样做</span>,
          <span style={{ fontFamily: "等线" }}>某些服务可能会失去全部功能。例如</span>
          ,
          <span style={{ fontFamily: "等线" }}>
            选择退出位置信息的收集将导致其基于位置的功能被禁用。&nbsp;
          </span>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <strong>
            <span style={{ fontFamily: "等线" }}>来自第三方的信息。</span>
          </strong>
          <span style={{ fontFamily: "等线" }}>
            您可以选择从第三方与我们共享某些数据
          </span>
          ,<span style={{ fontFamily: "等线" }}>或者通过您使用平台</span>,
          <span style={{ fontFamily: "等线" }}>
            我们可能会自动收集此类第三方数据。我们使用从您那里收集的信息
          </span>
          ,
          <span style={{ fontFamily: "等线" }}>
            以及您与平台以及其他第三方网站和应用程序的互动
          </span>
          ,
          <span style={{ fontFamily: "等线" }}>
            来推断您可能有兴趣为您提供更相关的广告。此信息告诉我们您进接过的网站
          </span>
          ,{" "}
          <span style={{ fontFamily: "等线" }}>您下载的应用程序和购买的应用程序</span>
          ,
          <span style={{ fontFamily: "等线" }}>
            以便我们可以预测将来您可能感兴趣的其他内容
          </span>
          ,
          <span style={{ fontFamily: "等线" }}>
            并评估我们平台上的广告效果如何。我们通过使用平台上的
          </span>
          Cookie
          <span style={{ fontFamily: "等线" }}>
            和类似技术以及从在我们的平台上做广告的第三方以及您进接其网站
          </span>
          /
          <span style={{ fontFamily: "等线" }}>
            应用程序收到的类似信息来收集此信息。
          </span>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <strong>
            <span style={{ fontFamily: "等线" }}>我们收集的有关您的技术信息。</span>
          </strong>
          <span style={{ fontFamily: "等线" }}>
            我们收集有关您用于进接平台的设备的某些信息
          </span>
          ,<span style={{ fontFamily: "等线" }}>例如您的</span>IP
          <span style={{ fontFamily: "等线" }}>地址</span>,
          <span style={{ fontFamily: "等线" }}>用户代理</span>,
          <span style={{ fontFamily: "等线" }}>移动运营商</span>,
          <span style={{ fontFamily: "等线" }}>时区设置</span>,
          <span style={{ fontFamily: "等线" }}>用于广告目的的标识符</span>,{" "}
          <span style={{ fontFamily: "等线" }}>设备的型号</span>,
          <span style={{ fontFamily: "等线" }}>设备系统</span>,
          <span style={{ fontFamily: "等线" }}>网络类型</span>,
          <span style={{ fontFamily: "等线" }}>设备</span>ID,
          <span style={{ fontFamily: "等线" }}>屏幕分辨率和操作系统</span>,
          <span style={{ fontFamily: "等线" }}>应用程序和文件名和类型</span>,
          <span style={{ fontFamily: "等线" }}>击键模式或节奏</span>,
          <span style={{ fontFamily: "等线" }}>电池状态</span>,{" "}
          <span style={{ fontFamily: "等线" }}>
            音频设置和连接的音频设备。当您从多个设备登录时
          </span>
          ,
          <span style={{ fontFamily: "等线" }}>
            我们将能够使用您的个人资料信息来识别您在各个设备上的活动。我们也可能将您从用于登录平台的设备以外的设备收集的信息相关联。
          </span>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <strong>
            <span style={{ fontFamily: "等线" }}>位置。</span>
          </strong>
          <span style={{ fontFamily: "等线" }}>我们收集有关您的大概位置的信息</span>,
          <span style={{ fontFamily: "等线" }}>包括基于您的</span>SIM
          <span style={{ fontFamily: "等线" }}>卡和</span>/
          <span style={{ fontFamily: "等线" }}>或</span>IP
          <span style={{ fontFamily: "等线" }}>地址的位置信息。经您许可</span>,
          <span style={{ fontFamily: "等线" }}>我们还可以收集精确的位置数据</span>(
          <span style={{ fontFamily: "等线" }}>例如</span>GPS)
          <span style={{ fontFamily: "等线" }}>。</span>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <strong>
            <span style={{ fontFamily: "等线" }}>找到其他用户并邀请您的朋友。</span>
          </strong>
          <span style={{ fontFamily: "等线" }}>
            您可以使用我们的“查找朋友”功能选择是否找到平台的其他用户。此功能使您可以查看电话联系人列表中的哪个朋友或附近的人正在使用平台
          </span>
          ,
          <span style={{ fontFamily: "等线" }}>
            并可以选择跟随他们。您也可以使用此功能邀请您的联系人加入平台。我们将使用您拥有的该人的联系信息
          </span>
          ,
          <span style={{ fontFamily: "等线" }}>
            在电话的联系列表为您提供向他们发送短信的选项
          </span>
          , <span style={{ fontFamily: "等线" }}>电子邮件或第三方消息</span>(
          <span style={{ fontFamily: "等线" }}>例如</span>Whatsapp,Facebook (
          <span style={{ fontFamily: "等线" }}>包括</span>Facebook Messenger )
          <span style={{ fontFamily: "等线" }}>或</span>Twitter )
          <span style={{ fontFamily: "等线" }}>邀请他们查看您的</span>MeLife
          <span style={{ fontFamily: "等线" }}>个人资料。</span>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>COOKIES</p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>
            我们或我们的授权服务提供商和广告合作伙伴可能会不时使用“&nbsp;
          </span>
          cookies<span style={{ fontFamily: "等线" }}>”</span>"{" "}
          <span style={{ fontFamily: "等线" }}>或其他功能</span>,
          <span style={{ fontFamily: "等线" }}>
            以允许我们或第三方收集或共享与您使用我们的服务或平台有关的信息。这些功能可帮助我们改善平台和提供的服务
          </span>
          ,<span style={{ fontFamily: "等线" }}>帮助我们提供新的服务和功能</span>,
          <span style={{ fontFamily: "等线" }}>和</span>/
          <span style={{ fontFamily: "等线" }}>
            或使我们和我们的广告合作伙伴能够为您提供更相关的内容
          </span>
          , <span style={{ fontFamily: "等线" }}>包括通过再营销。“&nbsp;</span>Cookie{" "}
          <span style={{ fontFamily: "等线" }}>
            ”是存储在您的计算机或移动设备上的标识符
          </span>
          ,<span style={{ fontFamily: "等线" }}>用于记录有关计算机或移动设备</span>,
          <span style={{ fontFamily: "等线" }}>
            如何以及何时使用或进接服务或平台的数据
          </span>
          ,{" "}
          <span style={{ fontFamily: "等线" }}>
            通过我们平台中有多少人和其他活动。我们可能会将
          </span>
          Cookie<span style={{ fontFamily: "等线" }}>信息链接到个人数据。</span>
          Cookies
          <span style={{ fontFamily: "等线" }}>
            还链接到有关您选择购买哪些项目以及您浏览过的网页的信息。此信息用于跟踪您的购物车
          </span>
          ,<span style={{ fontFamily: "等线" }}>提供特定于您的兴趣的内容</span>,
          <span style={{ fontFamily: "等线" }}>
            以使我们的第三方广告合作伙伴能够在互联网上的网站上投放广告
          </span>
          ,{" "}
          <span style={{ fontFamily: "等线" }}>
            进行数据分析并监视服务的使用情况。&nbsp;
          </span>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>
            您可以通过在浏览器或设备上选择适当的设置来拒绝使用
          </span>
          cookie<span style={{ fontFamily: "等线" }}>。但是</span>,
          <span style={{ fontFamily: "等线" }}>请注意</span>,
          <span style={{ fontFamily: "等线" }}>如果这样做</span>,
          <span style={{ fontFamily: "等线" }}>
            则可能无法使用我们的平台或服务的全部功能。&nbsp;
          </span>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <strong>
            <span style={{ fontFamily: "等线" }}>我们如何使用您的个人数据</span>
          </strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>
            我们将通过以下方式使用收集到的有关您的信息
          </span>
          :
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>•通知您有关我们服务的更改</span>;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>•为您提供用户支持</span>;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>•个性化您收到的内容</span>,
          <span style={{ fontFamily: "等线" }}>
            并为您提供您感兴趣的量身定制的内容
          </span>
          ;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>
            •使您能够共享用户内容并与其他用户进行交互
          </span>
          ;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>•启用我们的短信服务</span>,
          <span style={{ fontFamily: "等线" }}>如果您选择使用此功能</span>;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>•使您能够参与虚拟物品项目</span>;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>•与您沟通</span>;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>•帮助我们在平台上发现和打击滥用</span>,
          <span style={{ fontFamily: "等线" }}>有害活动</span>,
          <span style={{ fontFamily: "等线" }}>欺诈</span>,
          <span style={{ fontFamily: "等线" }}>垃圾邮件和非法活动</span>;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>•确保您的安全</span>,
          <span style={{ fontFamily: "等线" }}>包括审查用户内容</span>,
          <span style={{ fontFamily: "等线" }}>
            消息和相关元数据以违反我们的社区准则和其他不适当的内容
          </span>
          ;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>
            •确保以最有效的方式为您和您的设备呈现内容
          </span>
          ;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>•改进</span>,
          <span style={{ fontFamily: "等线" }}>促进和发展平台</span>,
          <span style={{ fontFamily: "等线" }}>并在平台上推广流行的主题</span>,
          <span style={{ fontFamily: "等线" }}>主题标签和运动</span>;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>•进行数据分析并测试平台</span>,
          <span style={{ fontFamily: "等线" }}>以确保其稳定性和安全性</span>;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>•允许您参与平台的交互功能</span>;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>•例如</span>,
          <span style={{ fontFamily: "等线" }}>
            通过允许其他用户通过“查找其他朋友”功能或通过他们的电话联系人来识别您
          </span>
          ,<span style={{ fontFamily: "等线" }}>从而使您可以在平台上进行社交</span>;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>•确保您足够年龄</span>,
          <span style={{ fontFamily: "等线" }}>可以</span>(
          <span style={{ fontFamily: "等线" }}>按照法律的要求</span>)
          <span style={{ fontFamily: "等线" }}>使用我们的平台。</span>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>•为您提供个性化广告</span>;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>•提供可用的位置服务</span>(
          <span style={{ fontFamily: "等线" }}>在您所在的区域中</span>);
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>•告知我们的算法</span>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>•执行我们的条款</span>,
          <span style={{ fontFamily: "等线" }}>条件和政策</span>;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>•管理平台</span>,
          <span style={{ fontFamily: "等线" }}>包括故障排除</span>;
          <span style={{ fontFamily: "等线" }}>和</span>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>•促进商品和服务的销售</span>,
          <span style={{ fontFamily: "等线" }}>促销和购买</span>,
          <span style={{ fontFamily: "等线" }}>并提供用户支持</span>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <strong>&nbsp;</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <strong>
            <span style={{ fontFamily: "等线" }}>我们如何分享您的个人数据</span>
          </strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>
            我们与以下选定的第三方共享您的数据
          </span>
          :
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <u>
            <span style={{ fontFamily: "等线" }}>付款提供者</span>
          </u>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>
            如果您选择购买我们的服务或进行其他与付款相关的交易
          </span>
          ,
          <span style={{ fontFamily: "等线" }}>
            我们将与相关的付款提供商共享数据以促进此交易。对于虚拟礼品交易
          </span>
          ,<span style={{ fontFamily: "等线" }}>我们共享一个交易</span>ID,
          <span style={{ fontFamily: "等线" }}>以使我们能够识别您的身份</span>,
          <span style={{ fontFamily: "等线" }}>
            并在您付款后以正确的价值将您的帐户记入虚拟礼品。
          </span>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <u>
            <span style={{ fontFamily: "等线" }}>服务提供商&nbsp;</span>
          </u>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>
            我们向支持我们业务的服务提供商提供信息和内容
          </span>
          ,{" "}
          <span style={{ fontFamily: "等线" }}>
            例如云服务提供商和内容审核服务提供商
          </span>
          ,
          <span style={{ fontFamily: "等线" }}>
            以确保平台是一个安全且令人愉悦的地方
          </span>
          ,
          <span style={{ fontFamily: "等线" }}>
            并且服务提供商可以帮助我们营销平台。
          </span>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <u>
            <span style={{ fontFamily: "等线" }}>分析提供商&nbsp;</span>
          </u>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>
            我们使用分析提供商来帮助我们优化和改进平台。
            我们的第三方分析提供商还帮助我们提供有针对性的广告。
          </span>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <u>
            <span style={{ fontFamily: "等线" }}>广告商和广告网络</span>
          </u>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>
            我们与广告商和第三方测量公司共享信息
          </span>
          ,
          <span style={{ fontFamily: "等线" }}>
            以显示平台有多少用户以及哪些用户查看或点击了广告。我们与测量公司共享您的设备
          </span>
          ID,
          <span style={{ fontFamily: "等线" }}>
            以便我们可以将您在平台上的活动与其他网站上的活动联系起来
          </span>
          ; <span style={{ fontFamily: "等线" }}>然后</span>,
          <span style={{ fontFamily: "等线" }}>
            我们使用此信息向您显示您可能感兴趣的广告。
          </span>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <u>
            <span style={{ fontFamily: "等线" }}>我们的企业集团</span>
          </u>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>
            我们也可能与我们公司集团的其他成员
          </span>
          ,<span style={{ fontFamily: "等线" }}>子公司或分支机构共享您的信息</span>,
          <span style={{ fontFamily: "等线" }}>以提供平台</span>,
          <span style={{ fontFamily: "等线" }}>包括改进和优化平台</span>,
          <span style={{ fontFamily: "等线" }}>防止非法使用并支持用户。</span>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <u>
            <span style={{ fontFamily: "等线" }}>执法</span>
          </u>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>如果法律要求</span>,
          <span style={{ fontFamily: "等线" }}>或者如果合理有必要使用</span>,
          <span style={{ fontFamily: "等线" }}>我们将与执法机构</span>,
          <span style={{ fontFamily: "等线" }}>公共当局或其他组织共享您的信息</span>:
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>•遵守法律义务</span>,
          <span style={{ fontFamily: "等线" }}>程序或要求</span>;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>•执行我们的服务条款以及其他协议</span>,
          <span style={{ fontFamily: "等线" }}>政策和守则</span>,
          <span style={{ fontFamily: "等线" }}>包括对任何潜在违反行为的调查</span>;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>•检测</span>,
          <span style={{ fontFamily: "等线" }}>预防或以其他方式解决安全</span>,
          <span style={{ fontFamily: "等线" }}>欺诈或技术问题</span>;
          <span style={{ fontFamily: "等线" }}>或者</span>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>•以保护我们用户</span>,
          <span style={{ fontFamily: "等线" }}>第三方或公众的权利</span>,
          <span style={{ fontFamily: "等线" }}>财产或安全</span>,
          <span style={{ fontFamily: "等线" }}>根据法律要求或允许下</span>(
          <span style={{ fontFamily: "等线" }}>
            包括与其他公司和组织交换信息出于欺诈保护和降低信用风险的目的
          </span>
          )<span style={{ fontFamily: "等线" }}>。</span>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <u>
            <span style={{ fontFamily: "等线" }}>公众资料</span>
          </u>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>请注意</span>,
          <span style={{ fontFamily: "等线" }}>如果您的个人资料是公开的</span>,{" "}
          <span style={{ fontFamily: "等线" }}>您的内容将在平台上的任何人中可见</span>
          ,
          <span style={{ fontFamily: "等线" }}>
            并且您的朋友和关注者以及搜索引擎等第三方也可以进接或共享您的内容
          </span>
          ,{" "}
          <span style={{ fontFamily: "等线" }}>
            内容聚合器和新闻网站。您可以更改每次上传视频时谁可以观看视频。或者
          </span>
          ,
          <span style={{ fontFamily: "等线" }}>
            您可以通过将设置在“管理我的帐户”设置中的“私人帐户”来将个人资料更改为私人帐户。
          </span>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <u>
            <span style={{ fontFamily: "等线" }}>出售或合并</span>
          </u>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>我们还将向第三方披露您的信息</span>:
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>
            •如果我们出售或购买任何业务或资产
          </span>
          (,<span style={{ fontFamily: "等线" }}>无论是由于清算</span>,
          <span style={{ fontFamily: "等线" }}>破产还是其他原因</span>),{" "}
          <span style={{ fontFamily: "等线" }}>在这种情况下</span>,
          <span style={{ fontFamily: "等线" }}>
            我们会将您的数据透露给此类业务或资产的潜在卖方或买方
          </span>
          ;<span style={{ fontFamily: "等线" }}>或者</span>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>•如果我们出售</span>,
          <span style={{ fontFamily: "等线" }}>购买</span>,
          <span style={{ fontFamily: "等线" }}>合并</span>,
          <span style={{ fontFamily: "等线" }}>被其他公司或企业收购或与之合作</span>,
          <span style={{ fontFamily: "等线" }}>
            或出售部分或全部资产。在此类交易中
          </span>
          ,<span style={{ fontFamily: "等线" }}>用户信息可能属于转移的资产。</span>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <strong>
            <span style={{ fontFamily: "等线" }}>
              我们存储您的个人数据的地方&nbsp;
            </span>
          </strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>
            我们从您那里收集的个人数据可能会存储在您居住国家
          </span>
          /
          <span style={{ fontFamily: "等线" }}>
            地区之外的新加坡或任何国家的服务器上。我们主要的服务器维持在全球各地
          </span>
          ,<span style={{ fontFamily: "等线" }}>以为您提供全球</span>,
          <span style={{ fontFamily: "等线" }}>持续的服务。</span>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <strong>
            <span style={{ fontFamily: "等线" }}>您的权利和选择</span>
          </strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>您可以通过登录</span>MeLife
          <span style={{ fontFamily: "等线" }}>
            来进接和编辑大多数个人资料信息。您可以删除上传的用户内容。我们还在“设置”中提供了许多工具
          </span>
          ,
          <span style={{ fontFamily: "等线" }}>这些工具使您可以控制可以查看视频</span>
          ,
          <span style={{ fontFamily: "等线" }}>
            向您发送消息或向视频发布评论的人。您选甚至可以择在“设置”中删除整个帐户。根据适用法律
          </span>
          ,<span style={{ fontFamily: "等线" }}>您可能还会获得某些权利</span>,
          <span style={{ fontFamily: "等线" }}>其中可能包括进接</span>,
          <span style={{ fontFamily: "等线" }}>删除</span>,
          <span style={{ fontFamily: "等线" }}>更新或纠正数据的权利</span>,
          <span style={{ fontFamily: "等线" }}>以告知您数据的处理</span>,{" "}
          <span style={{ fontFamily: "等线" }}>向当局提出投诉</span>,
          <span style={{ fontFamily: "等线" }}>
            并可能提出其他权利。如果您对如何使用上述工具有任何疑问
          </span>
          ,
          <span style={{ fontFamily: "等线" }}>
            想知道您可能拥有或想要行使这些权利
          </span>
          ,<span style={{ fontFamily: "等线" }}>或者有任何要求</span>,
          <span style={{ fontFamily: "等线" }}>询问或投诉</span>,{" "}
          <span style={{ fontFamily: "等线" }}>请通过</span>hi@melife.id
          <span style={{ fontFamily: "等线" }}>与我们联系。</span>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <strong>
            <span style={{ fontFamily: "等线" }}>您的个人数据的安全性</span>
          </strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>
            我们采取措施确保您的信息得到安全处理
          </span>
          ,<span style={{ fontFamily: "等线" }}>并符合本政策。不幸的是</span>,
          <span style={{ fontFamily: "等线" }}>
            通过互联网传输信息并不完全安全。尽管我们将通过加密来保护您的个人数据
          </span>
          ,
          <span style={{ fontFamily: "等线" }}>
            但我们不能保证通过平台传输的信息的安全性
          </span>
          ;<span style={{ fontFamily: "等线" }}>任何传输均由您自担风险。</span>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>我们采取适当的技术和组织措施</span>,
          <span style={{ fontFamily: "等线" }}>以确保适当的安全级别</span>,
          <span style={{ fontFamily: "等线" }}>
            以适合您和其他用户的权利和自由可能性和严重性变化的风险。我们保留这些技术和组织措施
          </span>
          ,<span style={{ fontFamily: "等线" }}>并将不时对其进行修改</span>,
          <span style={{ fontFamily: "等线" }}>以提高我们系统的整体安全性。</span>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>
            我们将不时包括往返于我们的合作伙伴网络
          </span>
          ,
          <span style={{ fontFamily: "等线" }}>
            广告商和会员网站的链接。如果您点击这些网站的链接
          </span>
          ,<span style={{ fontFamily: "等线" }}>请注意</span>,
          <span style={{ fontFamily: "等线" }}>这些网站具有自己的隐私权政策</span>,
          <span style={{ fontFamily: "等线" }}>
            我们对这些政策不承担任何责任。在向这些网站提交任何信息之前
          </span>
          ,<span style={{ fontFamily: "等线" }}>请检查这些政策。</span>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <strong>
            <span style={{ fontFamily: "等线" }}>我们保留您的个人数据多长时间</span>
          </strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>只要有必要</span>,
          <span style={{ fontFamily: "等线" }}>例如</span>,
          <span style={{ fontFamily: "等线" }}>
            遵守我们的合同关系或为您提供我们的服务
          </span>
          ,
          <span style={{ fontFamily: "等线" }}>
            我们就会保留您的信息。在不需要您的信息即可为您提供服务的地方
          </span>
          ,<span style={{ fontFamily: "等线" }}>我们仅保留其时间</span>,
          <span style={{ fontFamily: "等线" }}>
            只要我们有合法的商业目的来保存此类数据
          </span>
          , <span style={{ fontFamily: "等线" }}>适用法律可能允许的。但是</span>,
          <span style={{ fontFamily: "等线" }}>在某些情况下</span>,
          <span style={{ fontFamily: "等线" }}>
            我们可能会根据我们的法律义务保留此数据更长的时间
          </span>
          ,<span style={{ fontFamily: "等线" }}>或者有必要建立</span>,
          <span style={{ fontFamily: "等线" }}>行使或抗辩法律主张。</span>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>终止使用我们的平台后</span>,
          <span style={{ fontFamily: "等线" }}>
            我们将以汇总和匿名格式存储您的信息。
          </span>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <strong>
            <span style={{ fontFamily: "等线" }}>有关儿童的信息</span>
          </strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          MeLife<span style={{ fontFamily: "等线" }}>不针对</span>13
          <span style={{ fontFamily: "等线" }}>岁以下的儿童。在某些情况下</span>,
          <span style={{ fontFamily: "等线" }}>由于当地法规的要求</span>,
          <span style={{ fontFamily: "等线" }}>该年龄可能会更高</span>,
          <span style={{ fontFamily: "等线" }}>
            请参阅您当地的隐私权政策以获取更多信息。如果您认为我们拥有有关年龄以下儿童的个人数据或从该年龄以下的儿童那里收集的个人数据
          </span>
          ,<span style={{ fontFamily: "等线" }}>请通过</span>hi@melife.id
          <span style={{ fontFamily: "等线" }}>与我们联系。</span>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <strong>
            <span style={{ fontFamily: "等线" }}>投诉</span>
          </strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>
            如果您希望对我们如何处理您的个人数据提出投诉
          </span>
          ,<span style={{ fontFamily: "等线" }}>请立即通过</span>hi@melife.id
          <span style={{ fontFamily: "等线" }}>
            与我们联系。我们将努力尽快处理您的要求。这不影响您向相关数据保护机构提出索赔的权利。
          </span>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <strong>
            <span style={{ fontFamily: "等线" }}>变化</span>
          </strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>
            我们可能会不时更新本隐私权政策。当我们更新隐私权政策时
          </span>
          ,{" "}
          <span style={{ fontFamily: "等线" }}>
            我们将通过更新本政策顶部的“生效”日期并发布新的隐私权政策并提供适用法律要求的任何其他通知来通知您。在更新政策日期之后
          </span>
          ,
          <span style={{ fontFamily: "等线" }}>
            您继续进接或使用平台即表示您接受更新政策。如果您不同意更新的政策
          </span>
          ,<span style={{ fontFamily: "等线" }}>则必须停止进接或使用平台。</span>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <strong>
            <span style={{ fontFamily: "等线" }}>联系</span>
          </strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>如果您对此政策有疑问</span>,
          <span style={{ fontFamily: "等线" }}>意见或要求</span>,
          <span style={{ fontFamily: "等线" }}>请通过</span>hi@melife.id
          <span style={{ fontFamily: "等线" }}>与我们联系。</span>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <strong>
            <span style={{ fontFamily: "等线" }}>翻译</span>
          </strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
          <span style={{ fontFamily: "等线" }}>这些政策是以英文</span>( US )
          <span style={{ fontFamily: "等线" }}>
            编写。如果这些政策的任何翻译版本与英文版本冲突
          </span>
          ,<span style={{ fontFamily: "等线" }}>则由英文版本生效。</span>
        </p>
      </div>
    </div>
  )
}

export default PrivacyZh
