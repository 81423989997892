const Loader: React.FC<any> = () => {
  const wrapper = { className: 'home-page-wrapper loader-wrapper' }

  return (
    <div {...wrapper}>
      <div className="loader" />
    </div>
  )
}

export default Loader