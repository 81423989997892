import { IHomeComponent } from "./types"

const Privacy : React.FC<IHomeComponent> = ({isMobile, ...rest }) => {
  return (
    <div {...rest} className="home-page-wrapper">
      <div id="policy" className="home-page" style={{
        paddingTop: 100,
      }}>
        <p>
          Kebijakan Privasi berikut ini menjelaskan bagaimana kami mengumpulkan, menggunakan, memindahkan, mengungkapkan dan melindungi
          informasi pribadi anda yang dapat diidentifikasi yang diperoleh melalui Aplikasi kami (sebagaimana didefinisikan di bawah).
          Mohon anda membaca Kebijakan Privasi ini dengan seksama untuk memastikan bahwa anda memahami bagaimana ketentuan Kebijakan
          Privasi ini kami berlakukan. Kebijakan Privasi ini disertakan sebagai bagian dari Ketentuan Penggunaan kami. Kebijakan
          Privasi ini mencakup hal-hal sebagai berikut:
        </p>
        <div className="content">
          <ol>
            <li>Definisi</li>
            <li>Informasi yang kami kumpulkan</li>
            <li>Penggunaan informasi yang kami kumpulkan</li>
            <li>Pemberian informasi yang kami kumpulkan</li>
            <li>Penahanan informasi yang kami kumpulkan</li>
            <li>Keamanan</li>
            <li>Perubahan atas Kebijakan Privasi ini</li>
            <li>Lain-lain</li>
            <li>Pengakuan dan persetujuan</li>
            <li>Berhenti menerima e-mail</li>
            <li>Cara untuk menghubungi kami</li>
          </ol>
        </div>
        <p><b>Penggunaan anda atas aplikasi dan layanan kami tunduk pada Ketentuan Penggunaan dan Kebijakan Privasi ini dan mengindikasikan persetujuan anda terhadap Ketentuan Penggunaan dan Kebijakan Privasi tersebut.</b></p>
        <div className="content">
          <ol>
            <li className="strong">
              <h4 className="normal-font">Definisi</h4>
              <ol className="normal">
                <li>“Kami” berarti PT MeLife Indonesia, suatu perusahaan yang didirikan berdasarkan hukum Negara Republik Indonesia.</li>
                <li>“Aplikasi” berarti suatu aplikasi piranti lunak yang telah kami kembangkan yang merupakan suatu sarana untuk menemukan
                  Layanan yang disediakan oleh pihak ketiga.</li>
                <li>“Penyedia Layanan” berarti suatu pihak ketiga yang menyediakan Layanan melalui
                  Aplikasi.</li>
                <li>“Layanan” berarti layanan yang ditawarkan oleh Penyedia Layanan melalui
                  Aplikasi yang dapat mencakup (i) layanan massage, (ii) cleaning, (iii) dan (iv) beauty services.</li>
                <li>“Informasi Pribadi” berarti informasi mengenai anda yang secara pribadi dapat diidentifikasi yang dikumpulkan melalui
                  Aplikasi, seperti nama, alamat, tanggal lahir, pekerjaan, nomor telepon, alamat surat elektronik (e-mail) anda dan/atau
                  sejenisnya, dan informasi lain yang mungkin dapat mengidentifikasi orang tertentu yang sedang menggunakan Aplikasi.</li>
                <li>“Situs web” berarti situs web kami di www.melife.id.</li>
              </ol>
            </li>
            <li className="strong">
              <h4 className="normal-font">Informasi yang Kami Kumpulkan</h4>
              <ol className="normal">
                <li>Kami mengumpulkan Informasi Pribadi tertentu dari anda agar Aplikasi dapat menemukan Layanan dari Penyedia Layanan.
                  Anda akan langsung memberikan Informasi Pribadi (sebagai contoh, saat anda mendaftar) dan beberapa informasi akan
                  secara otomatis dikumpulkan ketika anda menggunakan Aplikasi.</li>
                <li>Ketika anda mengunjungi Situs web kami, administrator situs web kami akan memproses data teknis seperti alamat IP
                  anda, halaman web yang pernah anda kunjungi, browser internet yang anda gunakan, halaman web yang sebelumnya /selanjutnya
                  anda kunjungi dan durasi setiap kunjungan/sesi yang memungkinkan kami untuk mengirimkan fungsi-fungsi Situs web.
                  Sebagai tambahan, dalam beberapa hal, browser dapat menyarankan anda agar geo-location anda memungkinkan kami untuk
                  memberikan anda suatu pengalaman yang lebih baik. Dengan data teknis ini administrator-administrator Situs web kami
                  dapat mengelola Situs web, misalnya dengan menyelesaikan kesulitan-kesulitan teknis atau memperbaiki kemampuan untuk
                  dapat diaksesnya bagian-baigan tertentu dari Situs web. Dengan cara ini, kami dapat memastikan bahwa Anda dapat (terus)
                  menemukan informasi pada Situs web dengan cara yang cepat dan sederhana.</li>
                <li><strong>Informasi yang Anda berikan secara langsung.</strong></li>
                <li>Pada saat mendaftar pada Aplikasi, anda akan memberikan kepada kami alamat surat elektronik / surel (email), nama,
                  nomor telepon, dan sandi akun anda.</li>
                <li>Ketika Anda mengisi ulang (TOP UP) Saldo MeLife anda, kami akan mengumpulkan informasi seperti nama bank dimana rekening
                  anda dibuka, nama pemegang rekening, dan jumlah yang anda transfer untuk pengisian ulang (TOP UP).</li>
                <li>Anda dapat memberikan kode rujukan (referral code) kepada teman anda melalui Aplikasi, dimana, kami hanya akan menyiapkan
                  suatu pesan untuk anda kirimkan atau anda terbitkan melalui penyedia media sosial atau surel anda. Anda dapat mengubah
                  pesan yang telah kami siapkan sebelum anda mengirimkannya. Kami tidak akan mengumpulkan data teman anda.</li>
                <li><strong>Informasi yang kami kumpulkan ketika anda menggunakan Aplikasi.</strong></li>
                <li>Ketika anda menggunakan Aplikasi melalui perangkat bergerak / mobile device anda, kami akan melacak dan mengumpulkan
                  informasi geo-location secara real-time. Kami menggunakan informasi ini untuk memungkinkan anda untuk melihat Penyedia
                  Layanan yang berada di daerah anda yang dekat dengan lokasi anda, mengatur lokasi penjemputan dan mengirimkan informasinya
                  kepada Penyedia Layanan yang diminta, dan untuk melihat Penyedia Layanan yang diminta mendekat di suatu peta secara
                  real-time. Kami juga dapat menggunakan informasi geo-location secara real-time ini untuk memberikan bantuan, menyelesaikan
                  kesulitan-kesulitan teknis atau usaha yang mungkin timbul pada saat anda menggunakan Aplikasi. Anda dapat mematikan
                  informasi pelacak geo-location pada tingkatan perangkat untuk sementara waktu. Perangkat bergerak/mobile anda akan
                  memberitahukan anda ketika geo-location anda dilacak dengan menampilkan simbol panah GPS.</li>
                <li>Kami juga melacak dan mengumpulkan informasi geo-location Penyedia Layanan. Ini berarti bahwa kami juga mengumpulkan
                  informasi ketika anda bepergian dengan Penyedia Layanan. Kami juga akan menggunakan informasi geo-location Penyedia
                  Layanan dalam bentuk tanpa nama dan keseluruhan untuk mendapatkan informasi statistic dan informasi pengelolaan dan
                  untuk menyediakan untuk anda fungsi Aplikasi yang ditingkatkan.</li>
              </ol>
            </li>
            <li className="strong">
              <h4 className="normal-font">Penggunaan Informasi yang Kami Kumpulkan</h4>
              <ol className="normal">
                <li>Kami menggunakan surel, nama, nomor telepon, dan sandi akun anda untuk memverifikasi kepemilikan anda atas suatu akun,
                  untuk berkomunikasi dengan anda sehubungan dengan pesanan anda dan untuk memberikan anda informasi mengenai Aplikasi.
                  Kami juga dapat menggunakan nama, surel, dan nomor telepon anda untuk mengirimkan pesan, pembaharuan yang bersifat
                  umum atas Aplikasi, penawaran-penawaran khusus atau promosi-promosi. Kami juga akan mengirimkan surel kepada anda
                  yang meminta anda untuk berlangganan Daftar Surat Menyurat (Mailing List) kami. Anda dapat setiap saat memilih untuk
                  tidak menerima informasi mengenai pembahrauan ini.</li>
                <li>Kami menggunakan geo-location dan tujuan anda untuk menemukan Penyedia Layanan yang berada di sekitar anda, untuk
                  membantu Penyedia Layanan untuk memperhitungkan biaya dan untuk menganalisa pola penggunaan Aplikasi untuk meningkatkan
                  kinerja Aplikasi.</li>
                <li>Kami menggunakan informasi seperti barang-barang yang anda kirimkan/diantarkan dan/atau beli dan biaya pembelanjaan
                  anda untuk menentukan apakah Aplikasi dapat menerima pesanan anda berdasarkan Ketentuan Penggunaan.</li>
                <li>Kami menggunakan informasi seperti nama bank dimana rekening anda dibuat, nama dimana rekening tersebut disimpan dan
                  jumlah yang ditransfer untuk isi ulang (TOP UP) untuk memastikan pembayaran yang anda lakukan untuk Saldo MeLifes.</li>
                <li>Kami menggunakan Informasi Pribadi dalam bentuk tanpa nama dan secara keseluruhan untuk memantau lebih dekat fitur-fitur
                  mana dari Layanan yang paling sering digunakan, untuk menganalisa pola penggunaan dan untuk menentukan apakah kami
                  akan menawarkan atau fokus pada Layanan kami. Anda dengan ini setuju bahwa data anda akan digunakan oleh pemrosesan
                  data internal kami untuk memberikan Layanan yang lebih baik kepada anda.</li>
              </ol>
            </li>
            <li className="strong">
              <h4 className="normal-font">Pemberian Informasi yang Kami Kumpulkan</h4>
              <ol className="normal">
                <li>Setelah menerima pesanan anda, kami akan memberikan informasi seperti nama, nomor telepon, lokasi, tujuan, geo-location,
                  barang yang akan dikirimkan/diantar atau dibeli dan/atau biaya pembelanjaan anda kepada Penyedia Layanan yang menerima
                  permintaan anda atas Layanan. Informasi ini dibutuhkan oleh Penyedia Layanan untuk menghubungi anda, dan/atau menemukan
                  anda dan/atau memenuhi pesanan anda.</li>
                <li>Kami juga akan memberikan nomor telepon dari pihak yang dapat dihubungi yang telah anda berikan kepada kami kepada
                  Penyedia Layanan ketika anda menggunakan Aplikasi untuk menemukan layanan.</li>
                <li>Anda dengan ini setuju dan memberikan wewenang pada kami untuk memberikan Informasi Pribadi anda kepada Penyedia Layanan
                  sebagai suatu bagian dari ketentuan Layanan. Walaupun informasi pribadi anda secara otomatis akan dihapus dari perangkat
                  bergerak milik Penyedia Layanan setelah anda menggunakan Layanan, terdapat kemungkinan dimana Penyedia Layanan dapat
                  menyimpan data anda di perangkat mereka dengan cara apapun. Kami tidak bertanggung jawab atas penyimpanan data dengan
                  cara tersebut dan anda setuju untuk membela, memberikan ganti rugi dan membebaskan kami dan kami tidak akan bertanggung
                  jawab atas segala penyalahgunaan Informasi Pribadi anda oleh Penyedia Layanan setelah berakhirnya Layanan yang diberikan.</li>
                <li>Kami dapat mempekerjakan perusahaan-perusahaan dan orang perorangan pihak ketiga untuk memfasilitasi atau memberikan
                  Aplikasi dan layanan-layanan tertentu atas nama kami, untuk memberikan bantuan konsumen, memberikan informasi geo-location
                  kepada Penyedia Layanan kami, untuk melaksanakan layanan-layanan terkait dengan Situs web (misalnya tanpa pembatasan,
                  layanan pemeliharaan, pengelolaan database, analisis web dan penyempurnaan fitur-fitur Situs web) atau untuk membantu
                  kami dalam menganalisa bagaimana Layanan kami digunakan atau untuk penasihat profesional dan auditor eksternal kami,
                  termasuk penasihat hukum, penasihat keuangan, dan konsultan-konsultan. Para pihak ketiga ini hanya memiliki akses
                  atas informasi pribadi anda untuk melakukan tugas-tugas tersebut atas nama kami dan secara kontraktual terikat untuk
                  tidak mengungkapkan atau menggunakan informasi pribadi tersebut untuk tujuan lain apapun.</li>
                <li>Kami tidak membagikan Informasi Pribadi anda kepada pihak manapun selain kepada Penyedia Layanan terkait dan perusahaan
                  dan individu pihak ketiga yang disebutkan di bagian 4.4 di atas, tanpa persetujuan dari anda. Namun demikian, kami
                  akan mengungkapkan Informasi Pribadi anda sepanjang dimintakan secara hukum, atau diperlukan untuk tunduk pada ketentuan
                  perundang-undangan, peraturan-peraturan dan pemerintah, atau dalam hal terjadi sengketa, atau segala bentuk proses
                  hukum antara anda dan kami, atau antara anda dan pengguna lain sehubungan dengan, atau terkait dengan Layanan, atau
                  dalam keadaan darurat yang berkaitan dengan kesehatan dan/atau keselamatan anda.</li>
                <li>Informasi Pribadi anda dapat dialihkan, disimpan, digunakan, dan diproses di suatu yurisdiksi selain Indonesia dimana
                  server-server kami berada. Anda memahami dan setuju atas pengalihan Informasi Pribadi anda ke luar Indonesia.</li>
                <li>Kami tidak menjual atau menyewakan Informasi Pribadi anda kepada pihak ketiga.</li>
              </ol>
            </li>
            <li className="strong">
              <h4 className="normal-font">Penahanan Informasi yang Kami Kumpulkan</h4>
              <p className="normal">
                Kami akan menahan informasi anda hingga anda menghapus akun anda pada Aplikasi.
              </p>
            </li>
            <li className="strong">
              <h4 className="normal-font">Keamanan</h4>
              <p className="normal">
                Kami tidak menjamin keamanan database kami dan kami juga tidak menjamin bahwa data yang anda berikan tidak akan ditahan/terganggu
                ketika sedang dikirimkan kepada kami. Setiap pengiriman informasi oleh anda kepada kami merupakan risiko anda sendiri.
                Anda tidak boleh mengungkapkan sandi anda kepada siapapun. Bagaimanapun efektifnya suatu teknologi, tidak ada sistem
                keamanan yang tidak dapat ditembus.
              </p>
            </li>
            <li className="strong">
              <h4 className="normal-font">Perubahan atas Kebijakan Privasi ini</h4>
              <p className="normal">
                Kami dapat mengubah Kebijakan Privasi ini untuk mencerminkan perubahan dalam kegiatan kami. Jika kami mengubah Kebijakan
                Privasi ini, kami akan memberitahu anda melalui email atau dengan cara pemberitahuan di Situs web 1 hari sebelum perubahan
                berlaku. Kami menghimbau anda untuk meninjau halaman ini secara berkala untuk mengetahui informasi terbaru tentang
                bagaimana ketentuan Kebijakan Privasi ini kami berlakukan.
              </p>
            </li>
            <li className="strong">
              <h4 className="normal-font">Lain-lain</h4>
              <ol className="normal">
                <li>Bahasa. Kebijakan Privasi ini dibuat dalam bahasa Inggris dan bahasa Indonesia, versi keduanya akan mengikat anda
                  dan kami. Jika terdapat ketidaksesuaian antara versi bahasa Indonesia dan versi bahasa Inggris, maka versi bahasa
                  Indonesia yang akan berlaku.</li>
                <li>Hukum yang mengatur dan yurisdiksi. Kebijakan Privasi ini diatur oleh dan untuk ditafsirkan dalam hukum Negara Republik
                  Indonesia. Setiap dan seluruh sengketa yang timbul dari kebijakan privasi ini akan diatur oleh yurisdiksi eksklusif
                  dari Pengadilan Negeri Jakarta Selatan.</li>
              </ol>
            </li>
            <li className="strong">
              <h4 className="normal-font">Pengakuan dan Persetujuan</h4>
              <ol className="normal">
                <li>Dengan menggunakan Aplikasi, anda mengakui bahwa anda telah membaca dan memahami Kebijakan Privasi ini dan Ketentuan
                  Penggunaan dan setuju dan sepakat terhadap penggunaan, praktek, pemrosesan dan pengalihan informasi pribadi anda
                  oleh kami sebagaimana dinyatakan di dalam Kebijakan Privasi ini.</li>
                <li>Anda juga menyatakan bahwa anda memiliki hak untuk membagikan seluruh informasi yang telah anda berikan kepada kami
                  dan untuk memberikan hak kepada kami untuk menggunakan dan membagikan informasi tersebut kepada Penyedia Layanan.</li>
              </ol>
            </li>
            <li className="strong">Berhenti menerima e-mail
              <p className="normal">
                Kami memiliki kebijakan untuk memilih masuk/keluar dari database. Jika Anda ingin berhenti menerima email dari kami, silahkan
                klik link unsubscribe yang disertakan pada masing-masing e-mail.
              </p>
            </li>
            <li className="strong">Cara untuk Menghubungi Kami
              <p className="normal">
                Jika Anda memiliki pertanyaan lebih lanjut tentang privasi dan keamanan informasi Anda dan ingin memperbarui atau menghapus
                data Anda maka silakan hubungi kami di: hi@melife.id
              </p>
            </li>
          </ol>
        </div>
      </div>
    </div>
  )
}

export default Privacy
