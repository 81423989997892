export interface Summary {
  data: any
  draw: number
  recordsFiltered: number
  recordsTotal: number
}

export enum SummaryActionTypes {
  FETCH_REQUEST = '@@summaries/FETCH_REQUEST',
  FETCH_SUCCESS = '@@summaries/FETCH_SUCCESS',
  FETCH_ERROR = '@@summaries/FETCH_ERROR',
}

export interface SummaryState {
  readonly loading: boolean
  readonly data: Summary | null
  readonly errors?: string
}
