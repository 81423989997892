import { Reducer } from "redux"
import { SummaryActionTypes, SummaryState } from "./types"

export const initialState: SummaryState = {
  data: null,
  errors: undefined,
  loading: false
}

const reducer: Reducer<SummaryState> = (state = initialState, action) => {
  switch (action.type) {
    case SummaryActionTypes.FETCH_REQUEST:
    case SummaryActionTypes.FETCH_SUCCESS: {
      return { ...state, loading: false, data: action.payload }
    }
    case SummaryActionTypes.FETCH_ERROR: {
      return { ...state, loading: false, errors: action.payload }
    }
    default: {
      return state
    }
  }
}

export { reducer as summaryReducer }
