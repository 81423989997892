import React, { useState } from 'react'
import TweenOne from 'rc-tween-one'
import { Menu } from 'antd'
import { IHomeComponent } from './types'
import { Link } from 'react-router-dom'
// import { Helmet } from 'react-helmet'

const Header: React.FC<IHomeComponent> = ({dataSource, isMobile, noMenu, ...rest }) => {
  const [phoneOpen, openPhone] = useState<any>()

  const navData = dataSource.Menu.children
  const moment = phoneOpen === undefined ? 300 : null

  const phoneClick = () => {
    openPhone(!phoneOpen)
  }

  return (
    <TweenOne
      component="header"
      animation={{ opacity: 0, type: 'from' }}
      {...dataSource.wrapper}
      {...rest}
    >
      {/* {!noMenu && (
        <Helmet>
          <script type="text/javascript" src="https://www.paypal.com/sdk/js?client-id=AetWgPmW4iDyEH_ij5Ux-ob0MEpNvx06HWS2PKEx4Ltp-eyQ998C1oAxhOj2FaPkzlIYXrC2EcFq_OZQ&currency=SGD" crossOrigin="anonymous" async></script>
          <title>Checkout</title>
        </Helmet>
      )} */}
      <div
        {...dataSource.page}
        className={`${dataSource.page.className}${phoneOpen ? ' open' : ''}`}
      >
        <TweenOne
          animation={{ x: -30, type: 'from', ease: 'easeOutQuad' }}
          {...dataSource.logo}
        >
          <Link to='/'>
            <img width="100%" src={dataSource.logo.children} alt="MeLife" />
          </Link>
        </TweenOne>
        {!noMenu && (
          <>
            {isMobile && (
              <div
                {...dataSource.mobileMenu}
                onClick={() => {
                  phoneClick()
                }}
              >
                <em />
                <em />
                <em />
              </div>
            )}
            <TweenOne
              {...dataSource.Menu}
              animation={
                isMobile
                  ? {
                      x: 0,
                      height: 0,
                      duration: 300,
                      onComplete: (e) => {
                        if (phoneOpen) {
                          e.target.style.height = 'auto'
                        }
                      },
                      ease: 'easeInOutQuad',
                    }
                  : null
              }
              moment={moment}
              reverse={!!phoneOpen}
            >
              <Menu
                mode={isMobile ? 'inline' : 'horizontal'}
                theme="light"
                defaultSelectedKeys={[]}
                items={navData}
              />
            </TweenOne>
          </>
        )}
      </div>
    </TweenOne>
  )
}

export default Header
