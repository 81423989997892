import React from "react"
import ReactDOM from "react-dom"
import { IHomeComponent } from "./types"

const paypal = (window as any).paypal;
const PayPalButton = paypal.Buttons.driver("react", { React, ReactDOM });

const Checkout : React.FC<IHomeComponent> = ({isMobile, ...rest }) => {
  const _createOrder = (data: any, actions: any) => {
    console.log(data)
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: "1",
          },
        },
      ],
    });
  }

  const _onApprove = async (data: any, actions: any) => {
    let order = await actions.order.capture();
    console.log(order);
    return order;
  }
  
  const _onError = (err: any) => {
    console.log(err);
  }

  return (
    <div {...rest} className="home-page-wrapper">
      <div className="home-page" style={{
        paddingTop: 100,
      }}>
        <PayPalButton
          createOrder={({data, actions}: any) => _createOrder(data, actions)}
          onApprove={({data, actions}: any) => _onApprove(data, actions)}
          onCancel={() => _onError("Canceled")}
          onError={(err: any) => _onError(err)}
        />
      </div>
    </div>
  )
}

export default Checkout
