/* eslint-disable jsx-a11y/anchor-is-valid */
import { IHomeComponent } from "./types"

const TermsOfService : React.FC<IHomeComponent> = ({isMobile, ...rest }) => {
  return (
    <div {...rest} className="home-page-wrapper">
      <div id="policy" className="home-page" style={{
        paddingTop: 100,
      }}>
        <div className="pure-u-1 pure-u-sm-16-24">
			<h4>Ketentuan Penggunaan</h4>
			<p>
				Dengan mengunduh, memasang, dan/atau menggunakan Aplikasi MeLife ("<b>Aplikasi</b>"), Anda setuju bahwa Anda telah membaca,
				memahami dan menerima dan menyetujui Ketentuan Penggunaan ini ("<b>Ketentuan Penggunaan</b>"). Ketentuan Penggunaan ini
				merupakan suatu perjanjian sah antara Anda dan PT MeLife Indonesia dan Layanan dan Aplikasi (sebagaimana didefinisikan
				di bawah ini) berlaku pada kunjungan dan penggunaan Anda pada situs web kami di www.melife.id ("<b>Situs web</b>").
			</p>
			<p>
				Silahkan membatalkan akun Anda (jika Anda telah mendaftar untuk Aplikasi) dan secara permanen menghapus aplikasi dari perangkat
				Anda jika Anda tidak setuju atau tidak ingin masuk ke dalam Ketentuan Penggunaan.
			</p>
			<p>
				MOHON UNTUK MEMERIKSA KETENTUAN PENGGUNAAN DAN KEBIJAKAN PRIVASI KAMI DENGAN SEKSAMA SEBELUM MENGUNDUH APLIKASI ATAU MENGGUNAKAN
				LAYANAN KAMI UNTUK PERTAMA KALI.
			</p>
			<div className="content">
				<ol>
					<li className="strong">
						<h4 className="normal-font">Ketentuan untuk Menggunakan Aplikasi</h4>
						<ol className="normal">
							<li>Anda menyatakan dan menjamin bahwa Anda adalah individu yang secara hukum berhak untuk mengadakan perjanjian yang
								mengikat berdasarkan hukum Negara Republik Indonesia, khususnya Ketentuan Penggunaan, untuk menggunakan Aplikasi
								dan bahwa Anda telah berusia minimal 21 tahun atau sudah menikah dan tidak berada di bawah perwalian. Jika tidak,
								kami atau Penyedia Layanan terkait, berhak berdasarkan hukum untuk membatalkan perjanjian yang dibuat dengan Anda.
								Anda selanjutnya menyatakan dan menjamin bahwa Anda memiliki hak, wewenang dan kapasitas untuk menggunakan Layanan
								dan mematuhi Ketentuan Penggunaan. Jika Anda mendaftarkan atas nama suatu badan hukum, Anda juga menyatakan dan menjamin
								bahwa Anda berwenang untuk mengadakan, dan mengikatkan diri entitas tersebut pada Ketentuan Penggunaan ini dan mendaftarkan
								untuk Layanan dan Aplikasi.</li>
							<li>Kami mengumpulkan dan memproses informasi pribadi Anda, seperti nama, alamat surat elektronik (surel / e-mail), dan
								nomor telepon seluler Anda ketika Anda mendaftar. Anda harus memberikan informasi yang akurat dan lengkap, memperbaharui
								informasi dan setuju untuk memberikan kepada kami bukti identitas apapun yang secara wajar dapat kami mintakan. Jika
								informasi pribadi yang Anda berikan kepada kami ada yang berubah, misalnya, jika Anda mengubah alamat surel, nomor
								telepon, atau jika Anda ingin membatalkan akun Anda, mohon perbaharui rincian informasi Anda dengan mengirimkan permintaan
								Anda kepada kami. Kami akan, sepanjang yang dapat kami lakukan, memberlakukan perubahan yang diminta tersebut dalam
								waktu lima belas (15) hari kerja sejak diterimanya pemberitahuan perubahan.</li>
							<li>Anda hanya dapat menggunakan Aplikasi ketika Anda telah mendaftar pada Aplikasi tersebut. Setelah Anda berhasil mendaftarkan
								diri, Aplikasi akan memberikan Anda suatu akun pribadi yang dapat diakses dengan kata sandi yang Anda pilih.</li>
							<li>Hanya Anda yang dapat menggunakan akun Anda sendiri dan Anda berjanji untuk tidak memberikan wewenang kepada orang
								lain untuk menggunakan identitas Anda atau menggunakan akun Anda. Anda tidak dapat menyerahkan atau mengalihkan akun
								Anda kepada pihak lain. Anda harus menjaga keamanan dan kerahasiaan kata sandi akun Anda dan setiap identifikasi
								yang kami berikan kepada Anda. Dalam hal terjadi pengungkapan atas kata sandi Anda, dengan cara apapun, yang mengakibatkan
								setiap penggunaan yang tidak sah atau tanpa kewenangan atas akun atau identitas Anda, pesanan yang diterima dari
								penggunaan yang tidak sah atau tanpa kewenangan tersebut masih akan dianggap sebagai pesanan yang sah, kecuali Anda
								memberitahu kami tentang mengenai hal tersebut sebelum Penyedia Layanan memberikan Layanan yang diminta.</li>
							<li>Anda hanya dapat memiliki satu akun <span className="inline">MeLife</span>.</li>
							<li>Informasi yang diberikan oleh Aplikasi tidak dapat diartikan sebagai suatu saran atau penawaran, keputusan untuk menggunakan
								Penyedia Layanan sepenuhnya berada di tangan Anda. Anda bebas untuk memilih untuk menggunakan penyedia layanan lainnya.</li>
							<li>Anda berjanji bahwa Anda akan menggunakan Aplikasi hanya untuk tujuan yang dimaksud untuk mendapatkan Layanan. Anda
								tidak diperbolehkan untuk menyalahgunakan atau menggunakan Aplikasi untuk tujuan penipuan atau menyebabkan ketidaknyamanan
								kepada orang lain atau melakukan pemesanan palsu.</li>
							<li>Jika Anda juga adalah seorang Penyedia Layanan, Anda tidak dapat menggunakan akun konsumen Anda sendiri (atau akun
								milik konsumen orang lain) untuk melakukan pemesanan yang akan Anda terima sendiri sebagai seorang penyedia layanan.</li>
							<li>Anda tidak diperkenankan untuk membahayakan, mengubah atau memodifikasi Aplikasi dan/atau Situs web atau mencoba untuk
								membahayakan, mengubah atau memodifikasi Aplikasi dan/atau Situs web dengan cara apapun. Kami tidak bertanggung jawab
								jika Anda tidak memiliki perangkat yang sesuai atau jika Anda telah mengunduh versi Aplikasi yang salah untuk perangkat
								Anda. Kami berhak untuk melarang Anda untuk menggunakan Aplikasi lebih lanjut jika Anda menggunakan Aplikasi dengan
								perangkat yang tidak kompatibel/cocok atau tidak sah atau untuk tujuan lain selain daripada tujuan yang dimaksud
								untuk penggunaan Aplikasi ini. Anda berjanji bahwa Anda hanya akan menggunakan suatu jalur akses yang diperbolehkan
								untuk Anda gunakan.</li>
							<li>Anda akan menjaga kerahasiaan dan tidak akan menyalahgunakan informasi yang Anda terima dari penggunaan Aplikasi tersebut.
								Anda akan memperlakukan Penyedia Layanan dengan hormat dan tidak akan terlibat dalam perilaku atau tindakan yang
								tidak sah, mengancam atau melecehkan ketika menggunakan layanan mereka.</li>
							<li>Anda memahami dan setuju bahwa penggunaan Aplikasi oleh Anda akan tunduk pula pada Kebijakan Privasi kami sebagaimana
								dapat diubah dari waktu ke waktu. Dengan menggunakan Aplikasi, Anda juga memberikan persetujuan sebagaimana dipersyaratkan
								berdasarkan Kebijakan Privasi kami.</li>
							<li>Dengan memberikan informasi kepada kami, Anda menyatakan bahwa Anda berhak untuk memberikan kepada kami informasi
								yang akan kami gunakan dan berikan kepada Penyedia Layanan.</li>
							<li>Mohon menginformasikan kepada kami jika Anda tidak lagi memiliki kontrol atas akun Anda, sebagai contoh akun Anda
								dengan cara bagaimanapun diretas (hack) atau telepon Anda dicuri, sehingga kami dapat membatalkan akun Anda dengan
								sebagaimana mestinya. Mohon diperhatikan bahwa Anda bertanggung jawab atas penggunaan akun Anda dan Anda mungkin
								dapat dimintakan tanggung jawabnya meskipun jika akun Anda tersebut disalahgunakan oleh orang lain.</li>
						</ol>
					</li>
					<li className="strong">
						<h4 className="normal-font">Pembayaran</h4>
						<ol className="normal">
							<li>Pengunduhan dan/atau penggunaan Aplikasi ini adalah bebas biaya. Kami dapat memperkenalkan pengenaan biaya untuk pengunduhan
								dan/atau penggunaan Aplikasi di setiap saat. Kami akan memberitahu anda tentang hal ini sebagaimana mestinya agar
								anda dapat memutuskan apakah anda ingin terus menggunakan Aplikasi tersebut atau tidak. Namun demikian, koneksi internet
								yang dibutuhkan untuk menggunakan Layanan, dan setiap biaya terkait (misalnya biaya data ponsel) yang ditimbulkan
								oleh penggunaan Layanan tersebut adalah tanggung jawab eksklusif anda dan semata-mata dibebankan kepada anda.</li>
							<li>Tarif yang berlaku untuk Layanan oleh Penyedia Layanan dapat ditemukan pada Situs dan melalui Aplikasi. Kami dapat
								mengubah atau memperbaharui tarif dari waktu ke waktu. Kami akan membantu Penyedia Layanan untuk menghitung biaya
								berdasarkan pesanan anda dan memberitahu anda tentang biaya atas nama Penyedia Layanan.</li>
							<li>Anda setuju bahwa anda akan membayar Layanan yang diberikan kepada anda oleh Penyedia Layanan secara penuh.</li>
							<li>Pembayaran dapat dilakukan secara tunai atau dengan menggunakan Saldo <span className="inline">MeLife</span> anda. Semua
								pembayaran tunai harus dilakukan dalam Rupiah. Saldo <span className="inline">MeLife</span> dapat diperoleh dengan mentransfer
								jumlah yang anda inginkan ke rekening BCA kami di nomor 375 145 7578.</li>
							<li>Saldo <span className="inline">MeLife</span> bukan merupakan tabungan dan karena itu tidak termasuk dalam pengaturan
								oleh Lembaga Penjamin Simpanan Indonesia (LPS). Karena Saldo <span className="inline">MeLife</span> dapat dikategorikan
								sebagai e-money berdasarkan peraturan perundang-undangan yang berlaku, penggunaan Saldo <span className="inline">MeLife</span> harus tunduk pada peraturan perundang-undangan yang berlaku.</li>
							<li>Jumlah maksimum Saldo <span className="inline">MeLife</span> anda adalah Rp5.000.000 (lima juta Rupiah) dan maksimum
								total nilai transaksi anda dalam sebulan adalah Rp20.000.000 (dua puluh juta Rupiah).</li>
							<li>Saldo <span className="inline">MeLife</span> adalah dana Anda yang hanya dapat digunakan untuk pembayaran:
								<ol>
									<li>Layanan Massage dan Cleaning yang disediakan oleh Penyedia Layanan; dan</li>
								</ol>
							</li>
							<li>Biaya untuk Layanan mencakup pajak yang berlaku.</li>
						</ol>
					</li>
					<li className="strong">
						<h4 className="normal-font">Jaminan</h4>
						<p className="normal">
							Kami tidak memberikan pernyataan, jaminan atau garansi untuk dapat diandalkannya, ketepatan waktu, kualitas, kesesuaian,
							ketersediaan, akurasi atau kelengkapan dari Layanan, Situs web dan/atau perangkat lunak Aplikasi, termasuk namun tidak
							terbatas pada Layanan yang diperoleh atau berasal dari Penyedia Layanan melalui penggunaan Aplikasi tersebut. Kami
							tidak menyatakan atau menjamin bahwa (a) penggunaan Layanan dan/atau Aplikasi akan aman, tepat waktu, tanpa gangguan
							atau terbebas dari kesalahan atau beroperasi dengan kombinasi dengan perangkat keras lain, perangkat lunak, sistem
							atau data, (b) Layanan akan memenuhi kebutuhan atau harapan anda, (c) setiap data yang tersimpan akan akurat atau
							dapat diandalkan, (d) kualitas produk, layanan, informasi, atau bahan-bahan lain yang dibeli atau diperoleh oleh anda
							melalui Aplikasi akan memenuhi kebutuhan atau harapan anda, (e) kesalahan atau kecacatan dalam Aplikasi akan diperbaiki,
							atau (f) aplikasi atau server(-server)) yang menyediakan Aplikasi terbebas dari virus atau komponen berbahaya lainnya,
							atau (g) Aplikasi melacak anda atau kendaraan yang digunakan oleh Penyedia Layanan. Layanan disediakan untuk anda
							terbatas hanya pada dasar "sebagaimana adanya". Semua kondisi, pernyataan dan jaminan, baik tersurat, tersirat, yang
							diwajibkan oleh undang-undang atau sebaliknya, termasuk, namun tidak terbatas pada, jaminan yang tersirat mengenai
							jual beli, kesesuaian untuk tujuan tertentu, atau tidak adanya pelanggaran hak pihak ketiga, dengan ini dikecualikan
							dan dikesampingkan dengan batas tertinggi dan maksimum. Anda mengakui dan menyetujui bahwa seluruh risiko yang timbul
							dari penggunaan Layanan oleh anda tetap semata-mata dan sepenuhnya ada pada anda dan anda tidak akan memiliki hak
							untuk meminta ganti rugi apapun dari perusahaan.
						</p>
					</li>
					<li className="strong">
						<h4 className="normal-font">Perijinan</h4>
						<ol className="normal">
							<li>Dengan tergantung pada kepatuhan anda pada Ketentuan Penggunaan, kami memberikan anda ijin yang terbatas, non-eksklusif,
								tidak dapat dipindahtangankan, tidak dapat dialihkan, tidak dapat disublisensikan, ijin yang dapat ditarik untuk
								mengunduh dan memasang salinan Aplikasi pada suatu perangkat bergerak tunggal yang anda miliki atau kontrol dan menjalankan
								salinan Aplikasi semata-mata untuk kebutuhan pribadi, non-komersial anda sendiri.</li>
							<li>Anda tidak diperkenankan (i) menyalin, memodifikasi, mengadaptasi, menerjemahkan, membuat karya turunan dari, mendistribusikan,
								memberikan lisensi, menjual, mengalihkan, menampilkan di muka umum, membuat ulang, mentransmisikan, memindahkan,
								menyiarkan, menguraikan, atau membongkar bagian manapun dari atau dengan cara lain yang mungkin mengeksploitasi Aplikasi,
								kecuali sebagaimana diperbolehkan dalam Ketentuan Penggunaan ini, (ii) memberikan lisensi, mensublisensikan, menjual,
								menjual kembali, memindahkan, mengalihkan, mendistribusikan atau mengeksploitasi secara komersial atau membuat tersedia
								kepada pihak ketiga Aplikasi dan / atau perangkat lunak dengan cara; (iii) menciptakan "link" internet ke Aplikasi
								atau "frame" atau "mirror" setiap perangkat lunak pada server lain atau perangkat nirkabel atau yang berbasis internet;
								(iv) merekayasa ulang atau mengakses perangkat lunak kami untuk (a) membangun produk atau layanan tandingan, (b)
								membangun produk dengan menggunakan ide, fitur, fungsi atau grafis sejenis Aplikasi, atau (c) menyalin ide, fitur
								, fungsi atau grafis Aplikasi, (v) meluncurkan program otomatis atau script, termasuk, namun tidak terbatas pada,
								web spiders, web crawlers, web robots, web ants, web indexers, bots, virus atau worm, atau segala program apapun
								yang mungkin membuat beberapa permintaan server per detik, atau menciptakan beban berat atau menghambat operasi dan/atau
								kinerja Aplikasi, (vi) menggunakan robot, spider, pencarian situs/aplikasi pengambilan kembali, atau perangkat manual
								atau otomatis lainnya atau proses untuk mengambil, indeks, "tambang data" (data mine), atau dengan cara apapun memperbanyak
								atau menghindari struktur navigasi atau presentasi dari aplikasi atau isinya; (vii) menerbitkan, mendistribusikan
								atau memperbanyak dengan cara apapun materi yang dilindungi hak cipta, merek dagang, atau informasi yang dimiliki
								lainnya tanpa memperoleh persetujuan terlebih dahulu dari pemilik hak milik, (viii) menghapus setiap hak cipta, merek
								dagang atau pemberitahuan hak milik lainnya yang terkandung dalam aplikasi. Tidak ada lisensi atau hak yang diberikan
								kepada anda dengan implikasi berdasarkan hak kekayaan intelektual dimiliki atau dikendalikan oleh kami atau pemberi
								lisensi kami, kecuali untuk lisensi dan hak tersebut secara tegas diberikan dalam Ketentuan Penggunaan ini.</li>
							<li>Anda (i) tidak akan mengirim spam atau pesan yang bersifat duplikasi atau tidak diminta yang melanggar hukum berlaku;
								(ii) tidak akan mengirim atau menyimpan materi yang melanggar, cabul, mengancam, memfitnah, atau melanggar hukum
								atau bersifat sadis, termasuk namun tidak terbatas pada bahan berbahaya bagi anak-anak atau yang melanggar hak privasi
								pihak ketiga; (iii) tidak akan mengirim bahan yang mengandung virus piranti lunak, worm, trojan horses atau kode
								komputer berbahaya lainnya, dokumen/file, script, agen atau program; (iv) tidak mengganggu atau mengacaukan integritas
								atau kinerja Aplikasi atau data di dalamnya; (v) tidak mencoba untuk mendapatkan akses yang tidak sah ke Aplikasi
								atau sistem atau jaringan terkait; (vi) tidak berkedok sebagai orang atau badan lain atau menggambarkan diri anda
								sebagai afiliasi seseorang atau suatu badan; dan (vii) menahan diri untuk terlibat dalam tindakan yang mungkin bisa
								merusak reputasi kami atau dianggap dapat merusak reputasi kami.</li>
							<li>Kami akan memiliki hak untuk menyelidiki dan menuntut setiap pelanggaran di atas sepanjang yang dimungkinkan oleh
								hukum. Kami dapat melibatkan dan bekerja sama dengan pihak penegak hukum dalam menuntut pengguna yang melanggar Ketentuan
								Penggunaan ini. Anda mengakui bahwa kami tidak memiliki kewajiban untuk memonitor akses anda ke atau penggunaan Situs
								web atau Aplikasi, tapi kami memiliki hak untuk melakukannya untuk tujuan pengoperasian Situs web dan Aplikasi, untuk
								memastikan kepatuhan dengan Ketentuan Penggunaan ini, atau untuk mematuhi peraturan yang berlaku atau perintah atau
								persyaratan dari pengadilan, lembaga administratif atau badan pemerintahan lainnya.</li>
						</ol>
					</li>
					<li className="strong">
						<h4 className="normal-font">Hal-hal Terkait Kekayaan Intelektual</h4>
						<ol className="normal">
							<li><span className="inline">MeLife</span>, termasuk nama dan logo, Aplikasi dan Layanan, dilindungi oleh hak cipta, merek
								dagang dan hak-hak lain yang disediakan berdasarkan hukum Negara Republik Indonesia. Kami (dan pemberi lisensi kami,
								sebagaimana berlaku) secara eksklusif memiliki semua hak, kepemilikan dan kepentingan dalam dan terhadap Aplikasi,
								termasuk semua hak kekayaan intelektual terkait.</li>
							<li>Tanpa menyimpang dari hak kami berdasarkan hukum yang berlaku atau Ketentuan Penggunaan, anda diberitahukan bahwa
								setiap berusaha atau pelanggaran nyata atas ketentuan ini akan mengakibatkan penghentian semua hak anda di bawah
								Ketentuan Penggunaan. Jika anda menghindari salah satu cara yang kami ambil untuk melindungi layanan dari penggunaan
								secara tidak sah, anda harus segera menghentikan setiap dan semua penggunaan Layanan, dan anda setuju untuk melakukannya.</li>
						</ol>
					</li>
					<li className="strong">
						<h4 className="normal-font">Pengakhiran</h4>
						<ol className="normal">
							<li>Anda tidak berkewajiban untuk menggunakan Aplikasi dan dapat memilih untuk berhenti menggunakannya setiap saat dengan
								membatalkan akun anda dan secara permanen menghapus aplikasi dari perangkat anda, sehingga menonaktifkan penggunaan
								Aplikasi tersebut. Ketentuan Penggunaan secara otomatis berakhir ketika anda secara permanen menghapus aplikasi dari
								perangkat.
							</li>
							<li>Anda dapat mengakhiri akun anda dengan memberitahu kami maksud anda untuk membatalkan akun anda. Kami akan mencoba
								untuk melakukan yang terbaik untuk menyelesaikan penghentian, termasuk mengembalikan Saldo <span className="inline">MeLife</span>	anda (tidak termasuk jumlah yang diperoleh dari rujukan (referral code) atau promosi lainnya) dalam waktu
								15 (lima belas) hari kerja.</li>
							<li>Kami berhak untuk segera menangguhkan, membatasi atau menghentikan Ketentuan Penggunaan dan penggunaan Aplikasi jika
								kami memiliki alasan untuk mencurigai bahwa anda telah melanggar ketentuan dari Ketentuan Penggunaan ini atau peraturan
								perundang-undangan yang berlaku.</li>
							<li>Jika anda secara permanen menghapus aplikasi atau berhenti menggunakan Aplikasi secara permanen, melalui pemberitahuan
								kepada kami dalam 14 (empat belas) hari kerja sebelum penghapusan atau penghentian, Saldo <span className="inline">MeLife</span> anda dapat ditarik, namun terbatas pada nilai uang elektronik anda dan tidak termasuk jumlah nilai yang setara
								dengan nilai uang. Dalam hal kematian, ahli waris anda yang sah dengan pemberitahuan kepada kami dalam 14 (empat
								belas) hari kerja setelah kematian, dengan pernyataan yang sah yang dibuat sesuai dengan peraturan perundang-undangan
								yang berlaku atau keputusan pengadilan yang final dan mengikat, dapat mengklaim saldo <span className="inline">MeLife</span> Anda, namun terbatas pada nilai uang elektronik anda dan tidak termasuk jumlah nilai yang setara dengan nilai
								uang.
							</li>
						</ol>
					</li>
					<li className="strong">
						<h4 className="normal-font">Lain-lain</h4>
						<ol className="normal">
							<li>Pengesampingan atau toleransi atau kegagalan kami untuk mengklaim suatu pelanggaran ketentuan dari Ketentuan Penggunaan
								ini atau untuk melaksanakan hak yang dinyatakan oleh Ketentuan Penggunaan ini atau hukum yang berlaku, tidak akan
								dianggap sebagai pengesampingan sehubungan dengan pelanggaran berikutnya dari setiap ketentuan dari Ketentuan Penggunaan
								ini.
							</li>
							<li>Ketentuan Penggunaan ini disusun dalam bahasa Inggris dan bahasa Indonesia, kedua versi akan mengikat anda dan kami.
								Dalam hal terdapat ketidaksesuaian antara versi bahasa Indonesia dan versi bahasa Inggris, versi bahasa Indonesia
								yang akan berlaku.</li>
							<li>Anda tidak dapat memindahkan atau mengalihkan hak anda berdasarkan Ketentuan Penggunaan, tanpa persetujuan tertulis
								sebelumnya dari kami. Kami dapat mengalihkan hak kami berdasarkan Ketentuan Penggunaan kepada pihak semata-mata dan
								mutlak menurut kebijakan kami.</li>
							<li>Jika ada istilah berdasarkan Ketentuan Penggunaan yang dianggap tidak sah, tidak berlaku atau tidak dapat dilaksanakan,
								baik secara keseluruhan atau sebagian, berdasarkan pengundangan atau ketentuan perundang-undangan, istilah atau bagian
								dari istilah tersebut akan, sepanjang, dianggap bukan bagian dari Ketentuan Penggunaan ini, namun legalitas, keabsahan
								atau keberlakuan dari Ketentuan Penggunaan selebihnya tidak akan terpengaruhi.</li>
							<li>Ketentuan Penggunaan ini diatur oleh dan ditafsirkan berdasarkan hukum Negara Republik Indonesia. Setiap dan semua
								sengketa yang timbul dari penggunaan layanan kami akan diatur oleh yurisdiksi eksklusif dari Pengadilan Negeri Jakarta
								Selatan.
							</li>
							<li>Ketentuan Penggunaan ini dapat dimodifikasi dan diubah dari waktu ke waktu. Kami akan memberitahu anda melalui Aplikasi
								dan/atau email atas modifikasi, dan/atau perubahan atas Ketentuan Penggunaan. Penggunaan Aplikasi secara terus menerus
								setelah diterimanya pemberitahuan ini merupakan persetujuan dan penerimaan anda atas modifikasi, dan/atau perubahan.</li>
						</ol>
					</li>
				</ol>
			</div>
		</div>
      </div>
    </div>
  )
}

export default TermsOfService
