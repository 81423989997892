import { useEffect, useState } from 'react'
import { enquireScreen } from 'enquire-js'
import { BannerDataSource, FooterDataSource, HeaderNavDataSource, MissionDataSource } from '../components/Home/data.source'
import Header from '../components/Home/Header'

import '../components/Home/less/antMotionStyle.less'
import Banner from '../components/Home/Banner'
import Footer from '../components/Home/Footer'
import Loader from '../components/Home/Loader'
import PreFooter from '../components/Home/PreFooter'

import BgMovie from '../assets/videos/bg-movie.mp4'
import About from '../components/Home/About'

let isThisMobile : boolean
enquireScreen((b : boolean) => {
  isThisMobile = b
})

const AboutMission = () => {
  const [isMobile, setIsMobile] = useState(isThisMobile)
  const [show, isShown] = useState(false)
  const [headerClassName, setHeaderClassName] = useState('')

  useEffect(() => {
    const handleScroll = (headerClassName: string) => {
      if (headerClassName !== 'menu-with-bg' && window.pageYOffset >= 150) {
        setHeaderClassName('menu-with-bg');
      } else if (headerClassName === 'menu-with-bg' && window.pageYOffset < 150) {
        setHeaderClassName('');
      }
    }

    window.onscroll = () => handleScroll(headerClassName);

    enquireScreen((b : boolean) => {
      setIsMobile(!!b)
    })
    
    if (window.location.port) {
      setTimeout(() => {
        isShown(true)
      }, 100)
    } else {
      isShown(true)
    }
  }, [headerClassName, isMobile, show])

  const children = [
    <Header
      dataSource={HeaderNavDataSource(isMobile, headerClassName)}
      isMobile={isMobile}
      key="Header"
    />,
    <Banner
      dataSource={BannerDataSource(true)}
      isMobile={isMobile}
      video={BgMovie}
      key="Banner"
    />,
    <About
      dataSource={MissionDataSource}
      isMobile={isMobile}
      id="About"
      key="About"
    />,
    <PreFooter
      isMobile={isMobile}
      id="PreFooter"
      key="Pre-Footer"
    />,
    <Footer
      dataSource={FooterDataSource}
      isMobile={isMobile}
      key="Footer"
    />,
  ]

  return (
    <div
      className="templates-wrapper"
    >
      {!show ? <Loader /> : children}
    </div>
  )
}

export default AboutMission
