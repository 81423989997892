import axios from "axios"

const url = "widget/tabular?draw=1&columns%5B0%5D%5Bdata%5D=0&columns%5B0%5D%5Bname%5D=&columns%5B0%5D%5Bsearchable%5D=true&columns%5B0%5D%5Borderable%5D=true&columns%5B0%5D%5Bsearch%5D%5Bvalue%5D=&columns%5B0%5D%5Bsearch%5D%5Bregex%5D=false&columns%5B1%5D%5Bdata%5D=1&columns%5B1%5D%5Bname%5D=&columns%5B1%5D%5Bsearchable%5D=true&columns%5B1%5D%5Borderable%5D=true&columns%5B1%5D%5Bsearch%5D%5Bvalue%5D=&columns%5B1%5D%5Bsearch%5D%5Bregex%5D=false&order%5B0%5D%5Bcolumn%5D=0&order%5B0%5D%5Bdir%5D=asc&start=0&length=10&search%5Bvalue%5D=&search%5Bregex%5D=false&username=admin&viewName=vwKPR_OrderTest&displayName=Order+Test&_=1631676363380"

export const getWidget = async () => {
  const JSESSIONID = localStorage.getItem('@jsessionid')
  document.cookie = `JSESSIONID=${JSESSIONID}`

  const response = await axios.get(url, {
    headers: {
      "Content-Type": 'application/json',
      "Accept": "application/json",
      "Cache-Control": "no-cache",
      "Cookie": document.cookie,
    },
    withCredentials: true,
  })

  if (response.status === 200) {
    if (response.data) {
      return response.data
    } else {
      return null
    }
  }

  return null
}
