import { IHomeComponent } from "./types"

const Community : React.FC<IHomeComponent> = ({isMobile, ...rest }) => {
  return (
    <div {...rest} className="home-page-wrapper">
      <div className="home-page" style={{
        paddingTop: 100,
      }}>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '12pt'}}>
          <span style={{fontFamily: '"Montserrat", Sans-serif'}}>Effective: 1 October 2023 </span>
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '18pt'}}>
          <strong><span style={{fontFamily: '"Montserrat", Sans-serif'}}>Community Standards</span></strong>
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '18pt'}}>
          <strong><span style={{fontFamily: '"Montserrat", Sans-serif'}}>Introduction</span></strong>
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '12pt'}}>
          <span style={{fontFamily: '"Montserrat", Sans-serif'}}>MeLife's mission is to provide a platform for creative individuals to express themselves. We recognise how important it is for MeLife to be a place where people feel empowered to communicate, and we take our role seriously in keeping abuse off the service. That's why we developed standards for what is and isn't allowed on MeLife. We are committed to maintaining a supportive environment for our growing community. Our Community Standards establish a set of norms and common code of conduct that provide for a safe and welcoming space for everyone.</span>
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '12pt'}}>
          <span style={{fontFamily: '"Montserrat", Sans-serif'}}>Our Community Standards apply to everyone and everything on MeLife. We proactively enforce them using a mix of technology and human moderation and aim to do so before people report potentially violative content to us. We also encourage our community members to use the tools we provide on MeLife</span><span style={{fontFamily: '"Montserrat", Sans-serif'}}>&nbsp;</span><span style={{fontFamily: '"Montserrat", Sans-serif'}}>to report any content or account they believe violates our Community Standards.</span>
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '12pt'}}>
          <span style={{fontFamily: '"Montserrat", Sans-serif'}}>We will remove any content – including video, audio, livestream, images, comments, links, or other text – that violates our Community Standards. Individuals are notified of our decisions and can appeal them if they believe no violation has occurred. We will temporarily or permanently ban accounts and/or users that are involved in severe or repeated on-platform violations; we may also consider actions on other platforms and offline behaviour in these decisions. Circumstances that involve any instance of a threat of real-world harm to human life that is specific, credible, and imminent may be reported to law enforcement authorities. In certain cases, we won’t take action against content when it is newsworthy, factual, and relates to a matter of political, social, or other general concern to our community.</span>
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '12pt'}}>
          <span style={{fontFamily: '"Montserrat", Sans-serif'}}>In consultation with relevant stakeholders, we update our Community Standards from time to time to evolve alongside new behaviours and risks, as part of our commitment to keeping MeLife a safe place for creativity and joy. We reserve the right to decide, in our sole discretion, what content violates that spirit and will not be permitted on the platform.</span>
        </p>
        <h2 style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify'}}>
          Sexually Explicit Content
        </h2>
        <ul style={{margin: '0pt', paddingLeft: '0pt'}}>
          <li style={{marginTop: '6pt', marginLeft: '27.6pt', marginBottom: '6pt', textAlign: 'justify', paddingLeft: '8.4pt', fontFamily: 'serif', fontSize: '10pt'}}>
            <span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}>We prohibit accounts that promote or distribute pornographic content.</span>
          </li>
          <li style={{marginTop: '6pt', marginLeft: '27.6pt', marginBottom: '6pt', textAlign: 'justify', paddingLeft: '8.4pt', fontFamily: 'serif', fontSize: '10pt'}}>
            <span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}>We report all instances of child sexual exploitation to authorities. Never post, save, or send nude or sexually explicit content involving anyone under the age of 18 — even of yourself. Never ask a minor to send sexually explicit content. </span>
          </li>
          <li style={{marginTop: '6pt', marginLeft: '27.6pt', marginBottom: '6pt', textAlign: 'justify', paddingLeft: '8.4pt', fontFamily: 'serif', fontSize: '10pt'}}>
            <span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}>Breastfeeding and other depictions of nudity in certain non-sexual contexts may be permitted.</span>
          </li>
        </ul>
        <h2 style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify'}}>
          Harassment &amp; Bullying
        </h2>
        <ul style={{margin: '0pt', paddingLeft: '0pt'}}>
          <li style={{marginTop: '6pt', marginLeft: '27.6pt', marginBottom: '6pt', textAlign: 'justify', paddingLeft: '8.4pt', fontFamily: 'serif', fontSize: '10pt'}}>
            <span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}>We prohibit bullying or harassment of any kind. If someone blocks you, you may not contact them from another account.</span>
          </li>
          <li style={{marginTop: '6pt', marginLeft: '27.6pt', marginBottom: '6pt', textAlign: 'justify', paddingLeft: '8.4pt', fontFamily: 'serif', fontSize: '10pt'}}>
            <span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}>Sharing another person’s private information and images of people in private spaces — like a bathroom, bedroom, locker room or a medical facility — without their knowledge and consent is not allowed.</span>
          </li>
          <li style={{marginTop: '6pt', marginLeft: '27.6pt', marginBottom: '6pt', textAlign: 'justify', paddingLeft: '8.4pt', fontFamily: 'serif', fontSize: '10pt'}}>
            <span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}>If someone is depicted in your posts and asks you to remove it, please do! Respect the privacy rights of others.</span>
          </li>
        </ul>
        <h2 style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify'}}>
          Threats, Violence &amp; Harm
        </h2>
        <ul style={{margin: '0pt', paddingLeft: '0pt'}}>
          <li style={{marginTop: '6pt', marginLeft: '27.6pt', marginBottom: '6pt', textAlign: 'justify', paddingLeft: '8.4pt', fontFamily: 'serif', fontSize: '10pt'}}>
            <span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}>Encouraging violence or dangerous behaviour is prohibited — never intimidate or threaten to harm a person, a group of people, or someone’s property.</span>
          </li>
          <li style={{marginTop: '6pt', marginLeft: '27.6pt', marginBottom: '6pt', textAlign: 'justify', paddingLeft: '8.4pt', fontFamily: 'serif', fontSize: '10pt'}}>
            <span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}>Images of gratuitous or graphic violence, including animal abuse are not allowed.</span>
          </li>
          <li style={{marginTop: '6pt', marginLeft: '27.6pt', marginBottom: '6pt', textAlign: 'justify', paddingLeft: '8.4pt', fontFamily: 'serif', fontSize: '10pt'}}>
            <span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}>We don’t allow the glorification of self-harm, including the promotion of self-injury, suicide or eating disorders</span>
          </li>
        </ul>
        <h2 style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify'}}>
          Impersonation, Deceptive Practices &amp; False Information
        </h2>
        <ul style={{margin: '0pt', paddingLeft: '0pt'}}>
          <li style={{marginTop: '6pt', marginLeft: '27.6pt', marginBottom: '6pt', textAlign: 'justify', paddingLeft: '8.4pt', fontFamily: 'serif', fontSize: '10pt'}}>
            <span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}>We prohibit pretending to be someone (or something) that you’re not, or attempting to deceive people about who you are. This includes impersonating your friends, celebrities, brands, or other organizations.</span>
          </li>
          <li style={{marginTop: '6pt', marginLeft: '27.6pt', marginBottom: '6pt', textAlign: 'justify', paddingLeft: '8.4pt', fontFamily: 'serif', fontSize: '10pt'}}>
            <span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}>We prohibit spreading false information that causes harm or is malicious, such as denying the existence of tragic events, unsubstantiated medical claims, undermining the integrity of civic processes, or manipulating content for false or misleading purposes.</span>
          </li>
          <li style={{marginTop: '6pt', marginLeft: '27.6pt', marginBottom: '6pt', textAlign: 'justify', paddingLeft: '8.4pt', fontFamily: 'serif', fontSize: '10pt'}}>
            <span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}>We disallow spam and deceptive practices, including imitating MeLife.</span>
          </li>
        </ul>
        <h2 style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify'}}>
          Illegal Activity
        </h2>
        <ul style={{margin: '0pt', paddingLeft: '0pt'}}>
          <li style={{marginTop: '6pt', marginLeft: '27.6pt', marginBottom: '6pt', textAlign: 'justify', paddingLeft: '8.4pt', fontFamily: 'serif', fontSize: '10pt'}}>
            <span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}>Don’t use MeLife for any illegal activity — including to promote criminal activity; facilitate or participate in cybercrime; or to buy, sell or facilitate sales of illegal or regulated drugs, contraband, weapons, and counterfeit goods or documents.</span>
          </li>
          <li style={{marginTop: '6pt', marginLeft: '27.6pt', marginBottom: '6pt', textAlign: 'justify', paddingLeft: '8.4pt', fontFamily: 'serif', fontSize: '10pt'}}>
            <span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}>We prohibit the promotion of regulated goods or industries, including illegal gambling, tobacco products, and alcohol.</span>
          </li>
        </ul>
        <h2 style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify'}}>
          Terrorism, Hate Groups, and Hate Speech
        </h2>
        <ul style={{margin: '0pt', paddingLeft: '0pt'}}>
          <li style={{marginTop: '6pt', marginLeft: '27.6pt', marginBottom: '6pt', textAlign: 'justify', paddingLeft: '8.4pt', fontFamily: 'serif', fontSize: '10pt'}}>
            <span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}>Terrorist, extremist and hate groups are prohibited from using our platform. We have no tolerance for content that advocates or advances violent extremism or terrorism.</span>
          </li>
          <li style={{marginTop: '6pt', marginLeft: '27.6pt', marginBottom: '6pt', textAlign: 'justify', paddingLeft: '8.4pt', fontFamily: 'serif', fontSize: '10pt'}}>
            <span style={{fontFamily: '"Montserrat", Sans-serif', fontSize: '12pt'}}>Hate speech or content that demeans, defames, or promotes discrimination or violence on the basis of race, colour, caste, ethnicity, national origin, religion, sexual orientation, gender identity, disability, or veteran status, immigration status, socio-economic status, age, weight or pregnancy status is prohibited.</span>
          </li>
        </ul>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '12pt'}}>
          Please remember that you can always file a report with our team using our in-app reporting feature. We review these reports to determine violations of these Standards. 
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '12pt'}}>
          If you violate these Community Standards, we may remove the offending content, terminate or limit the visibility of your account, and/or notify law enforcement. We engage with global law enforcement when activity poses a threat to human life.&nbsp; If your account is terminated for violating our Terms of Service or these Standards, you are not allowed to use MeLife again.&nbsp;&nbsp;&nbsp;
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '12pt'}}>
          MeLife reserves the right to remove users whom we have reason to believe, in our sole discretion, pose a clear and present danger to others, on or off of MeLife. These include leaders of hate groups and terrorist organizations, and individuals with a reputation for inciting violence or behaviour that we believe poses a threat to human life.
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '12pt'}}>
          Please take these Standards seriously and honour them in the spirit in which they are intended.
        </p>
        <p style={{marginTop: '6pt', marginBottom: '6pt', textAlign: 'justify', lineHeight: 'normal', fontSize: '12pt'}}>
          <span style={{fontFamily: '"Montserrat", Sans-serif'}}>Thank you for being a part of our vibrant global community and working with us to maintain a welcoming space for all users. If you come across content or accounts that you believe may violate our Community Standard, please let us know</span><span style={{fontFamily: '"Montserrat", Sans-serif'}}>&nbsp;</span><span style={{fontFamily: '"Montserrat", Sans-serif'}}>so we can review and take appropriate action.</span>
        </p>
      </div>
    </div>
  )
}

export default Community
