import { enquireScreen } from 'enquire-js'
import { useEffect, useState } from "react"
import { FooterDataSource, HeaderNavDataSource } from "../components/Home/data.source"
import Loader from "../components/Home/Loader"
import Footer from "../components/Home/Footer"
import Header from '../components/Home/Header'
import CheckoutCompleted from '../components/Home/CheckoutCompleted'

let isThisMobile : boolean
enquireScreen((b : boolean) => {
  isThisMobile = b
})

const ReturnURL = () => {
  const [isMobile, setIsMobile] = useState(isThisMobile)
  const [show, isShown] = useState(false)
  const [headerClassName, setHeaderClassName] = useState('no-banner')

  useEffect(() => {
    const handleScroll = (headerClassName: string) => {
      if (headerClassName !== 'menu-with-bg' && window.pageYOffset >= 150) {
          setHeaderClassName('no-banner menu-with-bg');
      } else if (headerClassName === 'menu-with-bg' && window.pageYOffset < 150) {
          setHeaderClassName('no-banner');
      }
    }

    window.onscroll = () => handleScroll(headerClassName);

    enquireScreen((b : boolean) => {
      setIsMobile(!!b)
    })
    
    if (window.location.port) {
      setTimeout(() => {
        isShown(true)
      }, 100)
    } else {
      isShown(true)
    }
  }, [headerClassName, isMobile, show])

  const children = [
    <Header
      dataSource={HeaderNavDataSource(isMobile, headerClassName)}
      isMobile={isMobile}
      noMenu
      key="Header"
    />,
    <CheckoutCompleted
      isMobile={isMobile}
      isCancelled={false}
      key="CheckoutCompleted"
    />,
    <Footer
      dataSource={FooterDataSource}
      isMobile={isMobile}
      key="Footer"
    />,
  ]

  return (
    <div
      className="templates-wrapper"
    >
      {!show ? <Loader /> : children}
    </div>
  )
}

export default ReturnURL
